// Do not modify this file by hand!
// Re-generate this file by running lit-localize.

/**
 * The locale code that templates in this source code are written in.
 */
export const sourceLocale = `en-US`;

/**
 * The other locale codes that this application is localized into. Sorted
 * lexicographically.
 */
export const targetLocales = [
  `da-DK`,
  `de-DE`,
  `es-ES`,
  `fr-FR`,
  `nb-NO`,
  `nl-NL`,
  `pt-PT`,
  `sv-SE`,
  `tr-TR`,
];

/**
 * All valid project locale codes. Sorted lexicographically.
 */
export const allLocales = [
  `da-DK`,
  `de-DE`,
  `en-US`,
  `es-ES`,
  `fr-FR`,
  `nb-NO`,
  `nl-NL`,
  `pt-PT`,
  `sv-SE`,
  `tr-TR`,
];
