import { LitElement, html, css } from "lit";
import { createRef, ref } from "lit/directives/ref.js";

customElements.define(
  "social-rating",
  class RangeRating extends LitElement {
    static formAssociated = true;

    #rangeRef = createRef();
    #outputRef = createRef();

    static get properties() {
      return {
        name: { type: String, attribute: true },
        value: { type: String },
      };
    }

    static get styles() {
      return css`
        :host {
          display: inline-block;
          margin: auto;
          position: relative;
        }

        input,
        output {
          letter-spacing: 1rem;
          font-size: 28px;
          padding: 0.5rem;
        }

        input[type="range"] {
          appearance: none;
          display: block;
          width: calc(40px * 5 + 10px);
          background: transparent; // var(--color-primary-100);
          border-radius: 4px;

          height: 32px;
          cursor: pointer;
          margin: 0;

          &:focus {
            outline: 1px dotted #ccc;
          }
        }

        input[type="range"]::-webkit-slider-thumb {
          height: 28px;
          margin-top: 10px;
          opacity: 0;
        }

        output {
          position: absolute;
          top: -8px;
          font-size: 28px;
          color: transparent;

          &::after {
            pointer-events: none;
            display: block;
            position: absolute;
            content: attr(data-stars);
            color: #feab07;
            left: 10px;
          }
          &::before {
            pointer-events: none;
            display: block;
            position: absolute;
            content: "★★★★★";
            color: rgba(0, 0, 0, 0.2);
            left: 10px;
          }
        }
      `;
    }

    constructor() {
      super();
      this.internals = this.attachInternals();
    }

    render() {
      return html`
        <input
          ${ref(this.#rangeRef)}
          id="stars"
          type="range"
          min="1"
          max="5"
          step="1"
          name="stars"
          .value=${this.value}
          @input=${this.#onInput}
        />
        <output ${ref(this.#outputRef)} for="stars"></output>
      `;
    }

    firstUpdated() {
      this.#updateValue(false);
    }

    updated(changeProps) {
      if (changeProps.has("value")) {
        this.#updateValue();
      }
    }

    #updateValue(change) {
      change = change ?? true;
      this.#rangeRef.value.setAttribute("value", this.value);

      const stars = "★".repeat(parseInt(this.value));
      this.#outputRef.value.setAttribute("data-stars", stars);
      this.internals.setFormValue(this.value);

      if (change && this._isChange) {
        this._isChange = false;
        this.dispatchEvent(
          new CustomEvent("change", {
            bubbles: true,
            composed: true,
          })
        );
      }
    }

    #onInput(e) {
      this.value = e.target.value;
      this._isChange = true
    }
  }
);
