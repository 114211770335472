import { LitElement } from "lit";
import { msg, str } from "@lit/localize";
/**
 * Calls the given callback when the ESC key is pressed, and when
 * the user clicks outside the given element.
 * @param {HTMLElement} element
 * @param {Function} callback
 */
export function autoEscape(element, callback) {
  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") callback();
  });
  document.addEventListener("mousedown", (e) => {
    if (!element.contains(e.target)) callback();
  });
}

export function detectPersonNames(text) {
  if (text.length < 5 || text.trim().indexOf(" ") === -1) return false;

  const regex = /\b\w{2,}\s+\w{2,}\b/gi;

  let match;
  const names = [];

  // Find matches
  while ((match = regex.exec(text)) !== null) {
    names.push(match[0]);
  }

  return names;
}

export const getNeurotransmitterColor = (name) => {
  const neurotransmitterColors = {
    acetylcholine: "var(--color-accent-100)",
    dopamine: "var(--color-accent-200)",
    serotonin: "var(--color-accent-300)",
    gaba: "var(--color-accent-400)",
  };

  return neurotransmitterColors[name] ?? "var(--color-primary-300)";
}

export function isIterable(obj) {
  return typeof obj[Symbol.iterator] === 'function';
}

export const nativeWebShare = async ({title, text}) => {
  const shareData = {
    ...(title && {title}),
    text
  };

  if (!navigator.canShare) {
    try {
      await navigator.clipboard.writeText(text);
      app.addToastMessage("Successfully copied to clipboard");
      console.info("Successfully copied to clipboard");
    } catch (err) {
      console.error("Failed to copy content: ", err);
    }
  } else if (navigator.canShare(shareData)) {
    try {
      await navigator.share(shareData);
      console.info("Shared successfully");
    } catch (err) {
      console.error("Error sharing", err);
    }
  } else {
    console.warn("Specified data cannot be shared.", shareData);
  }
}


export function openCenteredWindow(url, width, height) {
  const left = (window.screen.width / 2) - (width / 2);
  const top = (window.screen.height / 2) - (height / 2);
  return window.open(url, '', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=${width}, height=${height}, top=${top}, left=${left}`);
}

/**
 * Truncate string at given length-3 and add '...' when truncated
 * @param {String} str 
 * @param {Number} maxLength 
 * @returns {String}
 */
export function truncateString(str, maxLength) {
  return str.length > maxLength ? `${str.slice(0, maxLength - 3)}...` : str;
}


export function playSound(src) {
  let audioCtx;
  try {
    audioCtx = new AudioContext();
  } catch (e) {
    console.error("Web Audio API is not supported in this browser");
  }

  // Load the sound file
  fetch(src)
    .then((response) => response.arrayBuffer())
    .then((arrayBuffer) => audioCtx.decodeAudioData(arrayBuffer))
    .then((audioBuffer) => {
      playAudio(audioCtx, audioBuffer);
    });

  // Function to play the sound
  function playAudio(audioContext, buffer) {
    const source = audioContext.createBufferSource();
    source.buffer = buffer;
    source.connect(audioContext.destination);
    source.start(0); // Play the sound immediately
  }
}


/**
 * Load a script.
 * @param {string} url - URL or path to the script. Relative paths are
 * relative to the HTML file that initiated script loading.
 * @param {function} successCallback - Optional parameterless function that
 * will be called when the script has loaded.
 * @param {function} errorCallback - Optional function that will be called
 * if loading the script fails, takes an error object as parameter.
 * @public
 */
export function loadScript(url, successCallback, errorCallback) {
  let mLoadedScripts = {},
    mScriptLoadingCounter = 0,
    mScriptsLoadedCallbacks = [];

  // If script is already loaded call callback directly and return.
  if (mLoadedScripts[url] == "loadingcomplete") {
    successCallback && successCallback();
    return;
  }

  // Add script to dictionary of loaded scripts.
  mLoadedScripts[url] = "loadingstarted";
  ++mScriptLoadingCounter;

  // Create script tag.
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = url;

  // Bind the onload event.
  script.onload = function () {
    // Mark as loaded.
    mLoadedScripts[url] = "loadingcomplete";
    --mScriptLoadingCounter;

    // Call success callback if given.
    successCallback && successCallback();

    // Call scripts loaded callbacks if this was the last script loaded.
    if (0 == mScriptLoadingCounter) {
      for (var i = 0; i < mScriptsLoadedCallbacks.length; ++i) {
        var loadedCallback = mScriptsLoadedCallbacks[i];
        loadedCallback && loadedCallback();
      }

      // Clear callbacks - should we do this???
      mScriptsLoadedCallbacks = [];
    }
  };

  // onerror fires for things like malformed URLs and 404's.
  // If this function is called, the matching onload will not be called and
  // scriptsLoaded will not fire.
  script.onerror = function (error) {
    errorCallback && errorCallback(error);
  };

  // Attaching the script tag to the document starts loading the script.
  document.head.appendChild(script);
}


/**
 * Calculates the age in years based on input date (Date or parseable date string)
 * @param {Date|String} dateValue
 * @returns {Number} age
 */
export function calculateAge(dateValue) {
  const date = new Date(dateValue);
  const today = new Date();
  let age = today.getFullYear() - date.getFullYear();
  const monthDifference = today.getMonth() - date.getMonth();
  // Adjust age if the current month and day are before the birth month and day
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < date.getDate())
  ) {
    age--;
  }
  return age;
}


export function padNumber(num, length) {
  let numStr = num.toString();
  numStr = numStr.replace(".", "");
  let diff = length - numStr.length;
  if (diff > 0) return "0".repeat(diff) + numStr;

  return numStr;
}

/**
 * Gets an object with padded string parts for a date.
 * @param {Date} date
 * @returns { Object } {year: yyyy, month: mm, day: dd}
 */
export function getDateStringParts(date) {
  if (!(date instanceof Date)) date = new Date(Date.parse(date));

  if (isNaN(date)) throw new TypeError("Invalid date");

  return {
    year: date.getFullYear(),
    month: padNumber(date.getMonth() + 1, 2),
    day: padNumber(date.getDate(), 2),
  };
}

export class LightElement extends LitElement {
  createRenderRoot() {
    return this;
  }
}

export function normalizeSpacing(str) {
  return str
    .split('\n') // Split the string into lines
    .map(line => line.trim()) // Trim each line
    .join(' ') // Join lines with a single space
    .replace(/\s+/g, ' '); // Replace multiple spaces with a single space
}

