import {html, LitElement} from "lit";

/**
 * Notification component
 */
customElements.define(
  "score-bar",
  class ScoreBar extends LitElement {
    createRenderRoot() {
      return this;
    }

    static get properties() {
      return {
        score: {
          type: Number,
          attribute: true,
        },
      };
    }

    get totalScorePercentage() {
      return this.score / 10 * 100;
    }

    get scoreStatus() {
      if (this.score <= 4) {
        return 'red';
      }

      if (this.score <= 7) {
        return 'orange';
      }

      return 'green';
    }

    render() {
      return html`
        <div class="bar">
          <span style="width: ${this.totalScorePercentage}%" class="progress ${this.scoreStatus}"></span>
        </div>

        <legend>
          <span>0</span>
          <span>2</span>
          <span>4</span>
          <span>6</span>
          <span>8</span>
          <span>10</span>
        </legend>
      `
    }

    updated() {
      setTimeout(() => {
        this.querySelector(".progress").style = `width: ${this.totalScorePercentage}%;`
      }, 100);
    }
  }
);
