export async function highlightAndShowTooltip(selector, content, timeout = 5000) {
  const element = document.querySelector(selector);
  if (!element) return;

  // Remove existing highlights
  document.querySelectorAll('.highlighted')
    .forEach(el => el.classList.remove('tour-highlighted'));

  // Highlight the new element
  element.classList.add('tour-highlighted');

  // Create and show the popover
  const popover = document.createElement('tour-popover');
  popover.textContent = content;
  document.body.appendChild(popover);

  // Position the popover near the highlighted element
  const rect = element.getBoundingClientRect();
  const offset = [5, 5];
  popover.style.left = `${rect.left + window.scrollX + offset[0]}px`;
  popover.style.top = `${rect.bottom + window.scrollY + offset[1]}px`;

  // Wait for a short time before removing the popover
  return new Promise((resolve) => {
    setTimeout(() => {
      document.body.removeChild(popover);
      element.classList.remove('tour-highlighted');
      return resolve();
    }, timeout); // Adjust timeout as needed
  });
}
