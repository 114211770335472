import { msg } from "@lit/localize";

export const QOGNI = {
  meta: {
    languages: {
      "da-DK": "Dansk",
      "de-DE": "Deutsch",
      "en-US": "English",
      "es-ES": "Español",
      "fr-FR": "Français",
      "nl-NL": "Nederlands",
      "nb-NO": "Norsk",
      "pt-PT": "Português",
      "sv-SE": "Svenska",
      "tr-TR": "Türkçe",
    },

    foodChoiceMappings: () => [
      { name: msg("Omnivore"), alias: "Omnivore", column: "omnivore", isDefault: true },
      { name: msg("Flexitarian"), alias: "Flexitarian", column: "flexitarian" },
      { name: msg("Pescatarian"), alias: "Pescatarian", column: "pescatarian" },
      { name: msg("Vegetarian"), alias: "Vegetarian", column: "vegetarian" },
      { name: msg("Lacto-Vegetarian"), alias: "Lacto-Vegetarian", column: "lacto_vegetarian" },
      { name: msg("Ovo-Vegetarian"), alias: "Ovo-Vegetarian", column: "ovo_vegetarian" },
      { name: msg("Lacto-Ovo Vegetarian"), alias: "Lacto-Ovo Vegetarian", column: "lacto_ovo_vegetarian" },
      { name: msg("Vegan"), alias: "Vegan", column: "vegan" },
      { name: msg("Raw Foodist"), alias: "Raw Foodist", column: "raw_foodist" },
      { name: msg("Paleo"), alias: "Paleo", column: "paleo" },
    ],

    allergiesAndIntoleranceMappings: () => [
      {
        column: "gluten_intolerance",
        name: msg("Gluten Intolerance", {desc: 'Allergen, the user will have a chance to provide their allergens in an onboarding screen.'}),
        alias: "Gluten",
        category: "Allergies",
      },
      { column: "celiac_disease", name: "Celiac Disease", category: "Allergies" },
      {
        column: "nut_allergy",
        alias: "Nuts",
        name: msg("Nut Allergy", {desc: 'Allergen, the user will have a chance to provide their allergens in an onboarding screen.'}),
        category: "Allergies",
      },
      {
        column: "shellfish_allergy",
        name: msg("Shellfish Allergy", {desc: 'Allergen, the user will have a chance to provide their allergens in an onboarding screen.'}),
        alias: "Shellfish",
        category: "Allergies",
      },
      {
        column: "fish_allergy",
        alias: "Fish",
        name: msg("Fish Allergy", {desc: 'Allergen, the user will have a chance to provide their allergens in an onboarding screen.'}),
        category: "Allergies",
      },
      {
        column: "soy_intolerance",
        alias: "Soy",
        name: msg("Soy Intolerance", {desc: 'Allergen, the user will have a chance to provide their allergens in an onboarding screen.'}),
        category: "Allergies",
      },
      {
        column: "egg_allergy",
        alias: "Egg",
        name: msg("Egg Allergy", {desc: 'Allergen, the user will have a chance to provide their allergens in an onboarding screen.'}),
        category: "Allergies",
      },
      {
        column: "lactose",
        alias: "Lactose",
        name: msg("Lactose Intolerance", {desc: 'Allergen, the user will have a chance to provide their allergens in an onboarding screen.'}),
        category: "Allergies",
      },
    ]
  }
}
