import { ApiRequest } from "../shared/APIRequest";
import { AppDomainHandler } from "../shared/app-domain-handler";
import { removeFalsyValues } from "@qogni-technologies/design-system/src/shared/common";

export const AllergensValues = [
  "gluten_intolerance",
  "celiac_disease",
  "nut_allergy",
  "shellfish_allergy",
  "fish_allergy",
  "soy_intolerance",
  "egg_allergy",
  "lactose",
];

export class RecipesDomain extends AppDomainHandler {
  #api;

  constructor() {
    super();
    this.#api = ApiRequest.factory();
  }

  async getRecipesList(options) {
    let result;
    options = removeFalsyValues(options);
    try {
      const query = {
        page: 1,
        with_rating_average: true,
        ...(options && { ...options }),
      };
      let url = `/recipes?${new URLSearchParams(query)}`;

      result = await this.#api.getData(url);
    } catch (err) {
      console.error("Error on getRecipesList: ", err);
      app.addToastMessage("Error while fetching Recipe list", {
        type: "error",
      });
      return false;
    }

    if (!result.status) return false;
    return result;
  }

  async getRecipe(recipeId) {
    let result;

    const url = `/recipes/${recipeId}`;
    result = await this.#api.getData(url);

    if (!result.status) return false;
    return result;
  }

  async getRecipeReview(recipeId) {
    return await this.#api.getData(`/recipes/${recipeId}/reviews`);
  }

  async createRecipeRating(recipeId, rating, comment = "") {
    let result;

    try {
      result = await this.#api.postData(`/recipes/${recipeId}/reviews`, {
        rating,
        comment,
      });
    } catch (err) {
      switch (err.response && err.response.status) {
        case 400:
          app.addToastMessage("Validation errors.", { type: "error" });
          return false;
        case 409:
          app.addToastMessage("You already added a review for this one", {
            type: "error",
          });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result;
  }
}
