import {html, LitElement} from "lit";
import {unsafeHTML} from "lit/directives/unsafe-html.js";

/**
 * Account link component
 */
customElements.define("tab-strip", class TabStrip extends LitElement {
  createRenderRoot() {
    return this;
  }

  static get properties() {
    return {
      activeTab: {type: String, attribute: false}, tabs: {type: Array, attribute: false},
    };
  }

  constructor() {
    super();
    this.tabs = [{
      label: 'Tab 1',
      icon: 'calendar',
      content: 'Content tab 1'
    }, {
      label: 'Tab 2',
      icon: 'people',
      content: 'Content tab 2'
    },];
    this.activeTab = this.tabs[0].label;
  }

  render() {
    return html`
      <div>
        <flex-container>
          ${this.tabs.map(tab => html`
            <flex-item class="grow-1">
              <button class="wide white" @click="${() => this.activeTab = tab.label}">
                <svg-icon size="18px" color="var(--color-primary-000)" icon="${tab.icon}"></svg-icon>
                <span data-text>${tab.label}</span>
              </button>
            </flex-item>
          `)}
        </flex-container>
      </div>

      <div class="tab-content">
        ${unsafeHTML(this.tabs.find(tab => tab.label === this.activeTab)?.content) || 'No content found'}
      </div>
    `
  }
});
