import { ApiRequest } from "../shared/APIRequest";
import { AppDomainHandler } from "../shared/app-domain-handler";
import { removeFalsyValues } from "@qogni-technologies/design-system/src/shared/common";

export class CircleDomain extends AppDomainHandler {
  #api;

  constructor() {
    super();
    this.#api = ApiRequest.factory();
  }

  async getCircles(options) {
    let result;
    let query;

    if (options) {
      query = removeFalsyValues(options);
    }

    try {
      let url = `/circles?${new URLSearchParams(query)}`;

      result = await this.#api.getData(url);
    } catch (err) {
      console.error("Error getCircles: ", err);
    }

    if (!result.status) return false;
    return result;
  }

  async createCircle(name, description = "") {
    let result;

    try {
      result = await this.#api.postData("/circles", { name, description });
      app.addToastMessage(`${name} channel successfully created.`);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        app.addToastMessage("Validation errors.", { type: "error" });
        return false;
      }
    }

    if (!result.status) return false;
    return result.data;
  }

  async getSingleCircle(id) {
    if (!id) return false;

    let result;

    try {
      result = await this.#api.getData(`/circles/${id}`);
    } catch (err) {
      switch (err.response && err.response.status) {
        case 403:
          app.addToastMessage("You do not have permission view this circle.", {
            type: "error",
          });
          return false;
        case 404:
          app.addToastMessage("Circle not found.", { type: "error" });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result.data;
  }

  async addUserToCircle(circleId, userId) {
    let result;

    try {
      result = await this.#api.postData(`/circles/${circleId}/users`, {
        user_id: userId,
      });
    } catch (err) {
      switch (err.response && err.response.status) {
        case 400:
          app.addToastMessage("Validation errors.", { type: "error" });
          return false;
        case 403:
          app.addToastMessage(
            "You do not have permission to add members to this circle.",
            { type: "error" }
          );
          return false;
        case 406:
          app.addToastMessage(
            "There is no connection between the users. (following eachother)",
            { type: "error" }
          );
          return false;
        case 409:
          app.addToastMessage("User already member of circle.", {
            type: "error",
          });
          return false;
        case 428:
          app.addToastMessage(
            "Maximum limit reached. System allow max 50 users.",
            { type: "error" }
          );
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result.status;
  }

  async getCircleGraph(circleId) {
    let result;

    try {
      result = await this.#api.getData(`/circles/${circleId}/graph`);
    } catch (err) {
      switch (err.response && err.response.status) {
        case 403:
          app.addToastMessage("You do not have permission view this circle.", {
            type: "error",
          });
          return { status: false, code: 403 };
        case 404:
          app.addToastMessage("Circle not found.", { type: "error" });
          return { status: false, code: 404 };
        case 406:
          return { status: false, code: 406 };
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result;
  }

  async removeUserFromCircle(circleId, userId) {
    let result;

    try {
      result = await this.#api.deleteData(
        `/circles/${circleId}/users/${userId}`
      );
    } catch (err) {
      switch (err.response && err.response.status) {
        case 403:
          app.addToastMessage(
            "Not permitted (user has no rights to the channel)",
            {
              type: "error",
            }
          );
          return false;
        case 404:
          app.addToastMessage(
            "Member or circle not found, or not a member of circle.",
            { type: "error" }
          );
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result;
  }

  async deleteCircle(circleId) {
    let result;

    try {
      result = await this.#api.deleteData(`/circles/${circleId}`);
    } catch (err) {
      switch (err.response && err.response.status) {
        case 403:
          app.addToastMessage(
            "You do not have permission to delete this circle.",
            {
              type: "error",
            }
          );
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result;
  }
}
