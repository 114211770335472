import { countTruthyFields } from "@qogni-technologies/design-system/src/shared/common";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { Converter } from "showdown";
import { AllergensValues, RecipesDomain } from "../../domain/recipes-domain";
import { config } from "../../qogni-app-config";
import { nativeWebShare } from "../../shared/common";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
  PullToRefreshMixin,
} from "../../shared/pwa-page";
import {msg, str} from '@lit/localize';
import { createRef, ref } from "lit/directives/ref.js";

const BaseClass = PullToRefreshMixin(
  OnboardedMixin(AuthenticatedMixin(PWAPage))
);

export class PageSingleRecipe extends BaseClass {
  #domain;
  #translatableContentRef = createRef();

  static get properties() {
    return {
      recipeId: { type: String },
      recipe: { type: Object },
      loading: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.#domain = new RecipesDomain();
  }

  async connectedCallback() {
    super.connectedCallback();
    await this.#getRecipe(this.recipeId);

    this.addEventListener("refresh", this.#refreshRecipe);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener("refresh", this.#refreshRecipe);
  }

  async #getRecipe(id) {
    const task = async () => {
      try {
        this.loading = true;
        const response = await this.#domain.getRecipe(id);
        this.recipe = response?.data;
      } catch (err) {
        app.addToastMessage(`Recipe not found: ${err}`, { type: "error" });
        throw err;
      } finally {
        this.loading = false;
      }
    };

    await Task.run(task);
  }

  async #refreshRecipe() {
    await this.#getRecipe(this.recipeId);
  }

  render() {
    if (this.loading)
      return html`
        <app-shimmer class="title"></app-shimmer>
        <app-shimmer class="tiny"></app-shimmer>
        <app-shimmer class="image"></app-shimmer>
        <app-shimmer class="title tiny"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer class="title tiny"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
      `;

    if (!this.recipe)
      return html`<a href="/"><button class="wide">${msg("Go Home")}</button></a>`;

    const {
      id,
      name,
      minutes,
      image_url,
      ingredients,
      description,
      breakfast,
      lunch,
      dinner,
      image_source,
      review_averages,
    } = this.recipe;
    const allergiesCount = countTruthyFields(this.recipe, AllergensValues);
    const allergiesText = `${allergiesCount} ${
      allergiesCount <= 1 ? msg("allergy") : msg("allergies")
    }`;
    const imgUrl = image_url || "/assets/img/food.webp";

    const familySize = app.session?.user?.family_size || 2;

    return html`
      <div class="heading" xmlns="http://www.w3.org/1999/html">
        <h1>${unsafeHTML(name)}</h1>
        <button class="small round white" @click=${this.#onShare}>
          <svg-icon icon="${navigator.canShare ? "share" : "copy"}"></svg-icon>
        </button>
      </div>

      <flex-container class="align-items-center">
        <flex-item>
          <div>
            ${breakfast
              ? html` <badge-tag class="green">${msg("Breakfast")}</badge-tag> `
              : nothing}
            ${lunch
              ? html` <badge-tag class="blue">${msg("Lunch")}</badge-tag> `
              : nothing}
            ${dinner
              ? html` <badge-tag class="yellow">${msg("Dinner")}</badge-tag> `
              : nothing}
          </div>
        </flex-item>

        <flex-item>
          <badge-tag class="simple">
            <svg-icon icon="clock"></svg-icon>
            ${minutes || 0} ${msg("minutes")}
          </badge-tag>
        </flex-item>

        <flex-item>
          <badge-tag class="simple">
            <svg-icon icon="drag"></svg-icon>
            ${allergiesText}
          </badge-tag>
        </flex-item>
        <flex-item>
          <badge-tag>
            <svg-icon icon="people"></svg-icon>
            ${familySize} ${familySize > 1 ? msg("Persons") : msg("Person")}
          </badge-tag>
        </flex-item>
        <flex-item>
          <recipe-range-rating-view
            .rating=${review_averages}
            .link=${`/rating/recipes/${id}`}
          ></recipe-range-rating-view>
        </flex-item>
      </flex-container>

      <section class="card">
        <image-card>
          <figure class="contain-image">
            <img src=${imgUrl} loading="lazy" />
            ${image_source
              ? html` <span class="source">&copy; ${image_source}</span> `
              : nothing}
          </figure>
        </image-card>
      </section>

      ${this.#renderIngredientsList(ingredients)}

      <section class="card">
        <flex-container class="align-items-center">
          <flex-item>
            <h2>${msg("Preparation")}</h2>
          </flex-item>

          <flex-item>
            <badge-tag class="simple">
              <svg-icon icon="clock"></svg-icon>
              ${minutes || 0} ${msg("minutes")}
            </badge-tag>
          </flex-item>
        </flex-container>

        <translatable-content>
          <steps-list
            >${unsafeHTML(new Converter().makeHtml(description))}</steps-list
          >
        </translatable-content>
      </section>
    `;
  }

  #renderIngredientsList(ingredients) {
    if (
      !ingredients ||
      (Array.isArray(ingredients) && ingredients.length === 0)
    )
      return;

    const familySize = app.session?.user?.family_size ?? 2;
    return html`
      <section class="card">
      
        <h2>${msg(str`Ingredients (people: ${familySize})`, { desc: "Heading for section to show ingredients of the receipe"})}</h2>

        <ingredients-list>
          <translatable-content ${ref(this.#translatableContentRef)}>
            <fieldset>
              ${repeat(ingredients, (ingredient) => {
                const { name, pivot, unit } = ingredient;
                const { amount } = pivot;
                const computedAmount = (amount / 2) * familySize;
                return html`
                  <label>
                    <input class="variant1" type="checkbox" value="1" />
                    <span></span>${computedAmount} ${unit} ${name}
                  </label>
                `;
              })}
            </fieldset>
          </translatable-content>

          <button
            class="outline wide"
            @click=${() => this.#onAddToShoppingList(ingredients)}
          >
            <svg-icon icon="plus"></svg-icon>
            ${msg("Add to shopping list", { desc: "Button label to add recipe's ingredients in the shopping list" })}
          </button>
        </ingredients-list>
      </section>
    `;
  }

  async #onAddToShoppingList(ingredients) {
    let text = ingredients
      .map((ingredient) => {
        const { name, pivot, unit } = ingredient;
        return `${pivot.amount} ${unit} ${name}`;
      })
      .join("\n");


    const translatableContentState = this.#translatableContentRef?.value?.state;

    if (translatableContentState === 'translation') {
      text = this.#translatableContentRef?.value?.translation;
    } 
    
    await nativeWebShare({ title: this.recipe.name, text });
  }

  async #onShare() {
    const task = async () => {
      const { absoluteUrl } = config;
      const link = `${absoluteUrl}/recipes#${this.recipe.id}`;

      await nativeWebShare({ title: this.recipe.name, text: link });
    };

    Task.run(task, {
      ghost: this,
      global: false,
    });
  }
}
