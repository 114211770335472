import { ApiRequest } from "../shared/APIRequest";
import { AppDomainHandler } from "../shared/app-domain-handler";
import { removeFalsyValues } from "@qogni-technologies/design-system/src/shared/common";

export class GroupDomain extends AppDomainHandler {
  #api;

  constructor() {
    super();
    this.#api = ApiRequest.factory();
  }

  async detail(groupId) {
    return await this.#api.getData(`/groups/${groupId}`);
  }
  
  async create(options) {
    let result;

    options = removeFalsyValues(options);

    try {
      result = await this.#api.postData("/groups", options);
      app.addToastMessage(`${options.name} channel successfully created.`);
    } catch (err) {
      switch (err.response && err.response.status) {
        case 400:
          app.addToastMessage("Validation errors.", {
            type: "error",
          });
          return false;
        case 403:
          app.addToastMessage("You are not allowed to create a group within the specified organisation.", { type: "error" });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result.data;
  }

  async list(options = {}) {
    const query = removeFalsyValues(options);
    return await this.#api.getData(`/groups?${new URLSearchParams(query)}`);
  }

  async join(groupId) {
    try {
      return await this.#api.postData(`/groups/${groupId}/join`);
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async leave(groupId) {
    try {
      return await this.#api.postData(`/groups/${groupId}/leave`);
    } catch (err) {
      console.error(err);
      return false;
    }
  }

}