import {AppDomainHandler} from "../shared/app-domain-handler";
import {ApiRequest} from "../shared/APIRequest";

export class TimelineDomain extends AppDomainHandler {
  #api;

  constructor() {
    super();
    this.#api = ApiRequest.factory();
  }

  async publishPost(post) {
    let result;

    try {
      result = await this.#api.postData('/posts', post);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        if (err.errorData && err.errorData.errors) {
          let errorText = '';
          for (const field of Object.keys(err.errorData.errors)) {
            const fieldError = err.errorData.errors[field];
            errorText += `\n${field}: ${fieldError}`;
          }
          app.addToastMessage("Validation failed: " + errorText, { type: "error" });
          return false;
        } else {
          app.addToastMessage("Validation failed.", { type: "error" });
        }

        return false;
      }
    }

    if (!result.status) return false;
    return result;
  }

  async likePost(postId, type) {
    if (isNaN(type)) return Promise.reject(new Error('Type is not a valid number.'));
    return await this.#api.postData(`/posts/${postId}/like`, {type});
  }

  async repostPost(postId) {
    return await this.#api.postData(`/reposts`, {
      id: postId
    });
  }

  async getComments(postId, options = {}) {
    return await this.#api.getData(`/posts/${postId}/comments?${new URLSearchParams(options?.query ?? {})}`);
  }

  async addComment(postId, content) {
    return await this.#api.postData(`/posts/${postId}/comments`, {content});
  }

  async getTimeline(options) {
    return await this.#api.getData(`/timeline?${new URLSearchParams(options?.query ?? {})}`);
  }

  async deletePost(postId) {
    return await this.#api.deleteData(`/posts/${postId}`);
  }
}
