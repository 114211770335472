import { html, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { AuthenticatedMixin, WidgetEnabledPWAPage } from "../shared/pwa-page";

const data = {
  new: [
    {
      title: "Reviews on recipes",
      description: "You can now review recipes in our system.",
    },
    {
      title: "Save posts",
      description: "You can now save several items in our system. Posts, recipes, tips and workouts can now be saved in your personal save lists.",
    },
    {
      title: "Facebook Login",
      description: "We added Facebook login."
    },
    {
      title: "Share recipes/workouts",
      description: "You can now share your favorite recipes and workouts with others."
    },
    {
      title: "HealthCheck results improvement",
      description: "We added more details to the HealthCheck section of the results page."
    }
  ],
  cooking: [
    {
      title: "Better search",
      description: "We will introduce a new way of searching through our application.",
    },
    {
      title: "Better results",
      description: "We will soon introduce a new way to view your results with more accurate statistics.",
    }
  ],
};

export class PageWhatsNew extends AuthenticatedMixin(WidgetEnabledPWAPage) {
  renderPage() {
    return this.renderItems(false);
  }

  getWidgetSettings(){
    return {
      ...super.getWidgetSettings() ?? {},
      priority: 900,
      title: 'New & Upcoming',
      cta: {
        href: '/whats-new'
      }
    };
  }

  renderWidget() {
    return this.renderItems(true);
  }

  renderItems(compact) {
    return html`
      ${compact ? nothing : html`
        <h2>Recent features and upcoming in the next releases</h2>
      `}

      <section class="card">
        <h3>New Qogni Features</h3>
        <ul class="mb-small">
          ${repeat(data.new, (item) => this.renderItem(item, compact))}
        </ul>

        <h3>${compact ? html`Coming in the next releases` : html`Check out what's coming in the next releases`}</h3>
        <ul>
          ${repeat(data.cooking, (item) => this.renderItem(item, compact))}
        </ul>
      </section>
    `;
  }

  renderItem(item, compact) {
    return html`<li>
      ${item.title} ${compact ? "" : html`<small>${item.description}</small>`}
    </li>`;
  }
}
