import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html, nothing } from "lit";
import { createRef, ref } from "lit/directives/ref.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { Converter } from "showdown";
import { GroupDomain } from "../../../domain/group-domain";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../../shared/pwa-page";
import { askConfirm } from "@qogni-technologies/design-system/src/components/base/modal-dialog.js";

export class PageSingleGroup extends OnboardedMixin(
  AuthenticatedMixin(PWAPage)
) {
  #domain;

  #followButtonRef = createRef();

  static get properties() {
    return {
      id: { type: String, routeOrigin: "pathname" },
      group: { type: Object },
    };
  }

  constructor() {
    super();
    this.#domain = new GroupDomain();
  }

  async connectedCallback() {
    await super.connectedCallback();

    if (this.id) {
      this.#getDetails();
    }
  }

  async #getDetails() {
    const task = async () => {
      const res = await this.#domain.detail(this.id);
      if (res) {
        this.group = res.data;
        console.log(this.group);
      }
    };

    Task.run(task, {
      ghost: this,
      description: "Loading group details...",
    });
  }

  async #groupFollowToggle() {
    const task = async () => {
      const { id, is_member } = this.group;
      if (is_member) {
        const userConfirmed = await askConfirm({
          title: "Please confirm?",
          message: `Are you sure you want to leave the group?`,
          okText: "Confirm",
        });

        if (!userConfirmed) return;
        await this.#domain.leave(id);
      } else {
        await this.#domain.join(id);
      }

      await this.#getDetails();
    };

    await Task.run(task, {
      ghost: this,
    });
  }

  render() {
    if (!this.group) {
      return html`
        <app-shimmer class="title"></app-shimmer>
        <app-shimmer class="tiny"></app-shimmer>
        <app-shimmer class="image"></app-shimmer>
        <app-shimmer class="title tiny"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer class="title tiny"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
      `;
    }

    const { name, description, is_member } = this.group;

    return html`
      <flex-container class="justify-content-space-between" breakpoint="tiny">
        <flex-item><h1>${name}</h1></flex-item>
        <flex-item class="align-self-center">
          <button
            type="button"
            class="button tiny outline align-content-end"
            ${ref(this.#followButtonRef)}
            @click=${() => this.#groupFollowToggle()}
          >
            ${is_member ? "Leave" : "Join"}
          </button>
        </flex-item>
      </flex-container>
      ${description
        ? html`<p>${unsafeHTML(new Converter().makeHtml(description))}</p>`
        : nothing}
    `;
  }
}
