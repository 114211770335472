import { QuestionnairePage } from "../shared/questionnaire";
import { html, nothing } from "lit";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { ref, createRef } from "lit/directives/ref.js";

export class PageBrainCheck extends QuestionnairePage {
  #startButtonRef = createRef();

  get categoryName() {
    return "BrainCheck";
  }

  get hasIntro() {
    return true;
  }

  get hasRewards() {
    return true;
  }

  get rewards() {
    return {
      25: {
        title: "You have completed 25 questions!",
        text: "Keep it up. You’ve earned 25 points already!",
        image: "/assets/img/balance.svg",
        rewardAmount: 25,
      },
      50: {
        title: "You have completed 50 questions!",
        text: "Keep it up. You’ve earned 50 points so far.",
        image: "/assets/img/balance.svg",
        rewardAmount: 50,
      },
      100: {
        title: "You have completed 100 questions!",
        text: "Impressive, you’re still here! This earned you 100 points! 98% made it up to here. 77% of them said: Qogni impacted my life..",
        image: "/assets/img/balance.svg",
        rewardAmount: 100,
      },
      200: {
        title: "You have completed 200 questions!",
        text: "You can do it. This earned you 200 points so far! 94% made it up to here. 52% uses the Qogni app on a daily basis.",
        image: "/assets/img/balance.svg",
        rewardAmount: 200,
      },
      275: {
        title: "You have completed 275 questions! Almost there",
        text: "You are in the final stretch. You Rock. Did you know that out of the 92% that completed the BrainCheck, 42% indicated that they are inspired to make a change",
        image: "/assets/img/balance.svg",
        rewardAmount: 275,
      },
    };
  }

  get demoQuestions() {
    return [
      {
        name: "I feel generally great",
        description: "Description of the question",
      },
    ];
  }

  async connectedCallback() {
    await super.connectedCallback();
    Task.run(
      async () => {
        await this.initPromise;
        this.loading = false;
      },
      {
        ghost: this.#startButtonRef.value,
      }
    );
  }

  renderTourIntro() {
    return html`
      <section class="hero">
        <h1>Let us explain!</h1>

        <p>
          In the next screens we’ll highlight the key features of this test.
          Or, if you prefer, you can skip ahead and start the ${this.categoryName} right away.
        </p>
      </section>

      <p class="center">
        <img
          src="/assets/img/explanation.svg"
          alt="explaining boy"
          class="fluid"
          style="max-height: 400px;"
          loading="lazy"
        />
      </p>

      <button type="button" class="wide" @click=${this.nextStep}>
        Start introduction
      </button>
      <button
        type="button"
        class="wide simple mt-small"
        @click=${this.skipIntro}
      >
        Skip the introduction
      </button>
    `;
  }

  renderIntro() {
    return html`
      <section class="hero hide-full-width">
        <h1>BrainCheck</h1>
        <p>Your Brain Performance: “Discover, Assess, Improve”</p>
      </section>

      <section class="card">
        <accordion-list>
          <details class="red" open>
            <summary>What is the BrainCheck?</summary>
            The BrainCheck is a comprehensive assessment tool designed to
            evaluate your cognitive functions and mental health. It analyzes
            neurotransmitter levels and cognitive abilities to help tailor
            personalized health strategies on the Qogni platform.
          </details>
          <details class="yellow">
            <summary>How long does it take?</summary>
            On average, the BrainCheck takes about 20 minutes to complete. Pause
            and resume anytime; your progress is saved.
          </details>
          <details class="green">
            <summary>Can I skip questions?</summary>
            While it's important to answer all questions to ensure the accuracy
            of your assessment,
            <u>please note that individual answers are not saved</u>, preserving
            your privacy.
          </details>
          <details class="blue">
            <summary>How important is the test?</summary>
            The BrainCheck is essential as it forms the foundation of your
            personalized health plan on Qogni, aiming to enhance cognitive
            performance and overall mental wellbeing.
          </details>
        </accordion-list>

        ${this.questionnaire
          ? html` <p>Resume your unfinished ${this.categoryName}.</p> `
          : nothing}
        ${this.canStartNew !== false
          ? html`
              <button
                type="button"
                class="wide"
                ?disabled=${this.loading}
                ${ref(this.#startButtonRef)}
                @click=${this.startButtonClick}
              >
                ${this.questionnaire
                  ? html` Continue the ${this.categoryName} `
                  : html` Start the ${this.categoryName} `}
              </button>
            `
          : html`
              <p>You recently finished a ${this.categoryName}.</p>
              <a href="/results" class="button wide small">
                Show results of last ${this.categoryName}
              </a>
            `}
        ${this.hasFinishedBefore && this.canStartNew !== false
          ? html`
              <section class="card mt-tiny">
                <p>
                  You previously finished another ${this.categoryName}. You can
                  see your results below.
                </p>
                <a href="/results" class="button outline wide small">
                  Show results of last ${this.categoryName}
                </a>
              </section>
            `
          : nothing}
      </section>
    `;
  }
}
