import {css, html, LitElement} from "lit";

customElements.define(
  "widget-container",
  class WidgetContainer extends LitElement {
    static get styles() {
      return [
        css`
          :host {
            display: block;
          }

          .widget-container {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
            gap: 1rem;
          }

          ::slotted(*) {
            display: block;
            position: relative;
            border-radius: 1rem;
            box-shadow: var(--widget-box-shadow, 2px 2px 12px rgba(0, 0, 0, 0.1));
            padding: var(--slot-padding, 10px);
            overflow: hidden;
            min-height: 80px;
            overflow-y: auto;
          }

          ::slotted([full]),
          ::slotted(.span2) {
            grid-column-end: span 2;
          }

          @media only screen and (max-width: 600px) {
            ::slotted(*) {
              grid-column-end: span 2;
            }
          }
        `
      ];
    }

    render() {
      return html`<div class="widget-container">
        <slot></slot>
      </div>`;
    }

    updated() {
      super.updated();
      const slot = this.shadowRoot.querySelector("slot");
      const nodes = slot.assignedNodes({flatten: true});
      nodes.forEach((node) => {
        if (node.nodeType === Node.ELEMENT_NODE)
          node.style.padding = "var(--slot-padding, 1rem); position: relative";
      });
    }
  }
);
