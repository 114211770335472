import { LitElement, html, css } from "lit";
import { createRef, ref } from "lit/directives/ref.js";
import { padNumber } from "../common";

customElements.define(
  "date-text",
  class DateText extends LitElement {
    static formAssociated = true;
    #textRef = createRef();
    #placeHolderRef = createRef();
    #year;
    #month;
    #day;
    #date;
    #textValue = "";
    #yearPos = 0;
    format = "dd-mm-yyyy";
    pattern = "\\d{2}-\\d{2}-\\d{4}";
    //placeholder = "dd-mm-yyyy";

    static get properties() {
      return {
        name: { type: String, attribute: true },
        value: { type: String },
        format: { type: String },
      };
    }

    static get styles() {
      return css`
        :host {
          position: relative;
          display: block;
          position: relative;
          min-height: 3rem;
          line-height: 26px;
        }

        input {
          padding: 0.75rem 1.6rem;
          border: 1px solid transparent;
          margin: 0;
          line-height: 26px;

          border-radius: 100px;
          font-family: var(--font-body, sans-serif);
          font-size: 1rem;
          display: inline-block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;

          &::placeholder {
            color: transparent;
          }
        }

        input#date {
          border-color: var(--color-text-100);
          outline: none;
          background-color: transparent;
          &:focus {
            border-color: var(--color-primary-000);
          }
        }

        input#placeholder {
          outline: none;
          pointer-events: none;

          content: "dd-mm-yyyy";
          color: #aaa;
        }
      `;
    }

    set value(value) {
      try {
        this.#date = new Date(Date.parse(value));
        if (!isNaN(this.#date)) {
          this.#textValue = this.formatForDisplay();
          return;
        }
      } catch {
        /**/
      }
      this.#textValue = "";
    }

    get value() {
      return this.#textValue;
    }

    formatForDisplay() {
      if (!isNaN(this.#date)) {
        return `${this.#date.getFullYear()}-${padNumber(
          this.#date.getMonth() + 1,
          2
        )}-${padNumber(this.#date.getDate(), 2)}`;
      }
    }

    parse(isInputEvent) {
      if (this.format?.length != 10) throw new Error("Invalid format");

      this.placeholder = this.format;

      const parts = this.#textValue?.split("-") ?? [];

      if (isInputEvent) {
        this.#year = parts[this.#yearPos];
        this.#month = padNumber(parts[1] ?? "", 2);
        this.#day = padNumber(parts[this.#yearPos === 0 ? 2 : 0]);
      } else {
        this.#year = parts[0];
        this.#month = padNumber(parts[1] ?? "", 2);
        this.#day = padNumber(parts[2], 2);
      }

      try {
        this.#date = new Date(
          Number(this.#year),
          Number(this.#month) - 1,
          Number(this.#day) + 1
        );

        this.#textValue =
          this.#yearPos === 0
            ? `${this.#year}-${this.#month}-${this.#day}`
            : `${this.#day}-${this.#month}-${this.#year}`;

        this.internals.setValidity({ valid: true });
        const iso = this.#date.toISOString().split("T")[0];

        this.internals.setFormValue(iso);
      } catch {
        this.internals.setValidity({ badInput: true }, "Value is not a date.");
      }

      //this.#updateValue();
    }

    constructor() {
      super();

      this.attachShadow({ mode: "open", delegatesFocus: true });
      this.internals = this.attachInternals();

      this.addEventListener("focus", () => {
        this.#textRef.value.focus();
      });
    }

    render() {
      if (this.#textValue) this.parse();

      return html`
        <input
          tabindex="-1"
          id="placeholder"
          ${ref(this.#placeHolderRef)}
          value="${this.value || this.placeholder}"
          maxlength="10"
          readonly
        />

        <input
          ${ref(this.#textRef)}
          id="date"
          type="text"
          .value=${this.#textValue}
          @input=${this.#onInput}
          pattern="${this.pattern}"
          maxlength="10"
        />
      `;
    }

    willUpdate() {
      this.#yearPos = this.format.indexOf("yyyy") === 0 ? 0 : 2;

      this.pattern =
        this.#yearPos === 0 ? "\\d{4}-\\d{2}-\\d{2}" : "\\d{2}-\\d{2}-\\d{4}";
    }

    firstUpdated() {
      //this.#updateValue();
      this.setAttribute("tabindex", "0");
    }

    #onInput(e) {
      e.target.value = e.target.value.replace(/[^0-9-]/g, "");

      this.#textValue = e.target.value;
      this.parse(true);

      const len = e.target.value.length;
      const mask =
        e.target.value.substring(0, len) + this.placeholder.substring(len);

      this.#placeHolderRef.value.value = mask;
    }
  }
);
