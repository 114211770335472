import { html } from "lit";
import {
  AuthenticatedMixin,
  FullscreenMixin,
  PWAPage,
} from "../shared/pwa-page";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { QogniFlow, UI } from "../shared/qogni-flow";
import { calculateAge } from "../shared/common";

const LANG_DISCLAIMERS = {
  "dk-DK": "I øjeblikket er KUN spørgeskemaerne på dansk",
  "de-DE": "Im Moment sind NUR die Fragebögen auf Deutsch.",
  "en-US":
    "Only the questionnaires are currently available in other languages than English.",
  "es-ES": "En este momento, SOLO los cuestionarios están en español.",
  "fr-FR": "En ce moment, SEULEMENT les questionnaires sont en français.",
  "nl-NL": "Op dit moment zijn alleen de Questionnaires in het Nederlands.",
  "no-NO": "For øyeblikket er BARE spørreskjemaene på norsk.",
  "pt-PT": "Neste momento, APENAS os questionários estão em português.",
  "sv-SV": "För närvarande är ENDAST frågeformulären på svenska.",
  "tu-TR": "Şu anda SADECE anketler Türkçe.",
};

const OnboardingPage = FullscreenMixin(AuthenticatedMixin(PWAPage));

export class OnboardingPage1 extends OnboardingPage {
  connectedCallback() {
    super.connectedCallback();

    this.flow = new QogniFlow({
      root: this.renderRoot,
      start: this.onboardQogniUser.bind(this),
      on: {
        init: (wf) => {
          wf.install("introduceLynn", this.introduceLynnAction.bind(this));
          wf.install(
            "languageDisclaimer",
            this.languageDisclaimerAction.bind(this)
          );
        },
        end: () => {
          alert("Yeah!");
        },
      },
    });
  }

  render() {
    return this.flow.render();
  }

  //#region flow

  // entrypoint
  async onboardQogniUser(wf) {
    const person = {
      name: app.session.user.firstname,
    };

    const params = new URLSearchParams(location.search);
    person.healthAdviceOnly = params.has("adv");

    if (person.healthAdviceOnly) {
      this.#prefillFromUser(person);
    } else {
      person.language = await wf.ask(
        "Choose your prefered language",
        UI.language
      );

      await wf.languageDisclaimer(person);

      await this._1_showIntroduction(wf);

      await this._2_getPersonalDetails(wf, person);
    }

    await this._3_getHealthDetails(wf, person);

    await this._4_showUserAround(wf, person);

    await this._5_nextStep(wf, person);
  }

  #prefillFromUser(person) {
    person.name = app.session.user.firstname;
    person.age = "unknown";
    person.birthDate = app.session.user.date_of_birth;
    
    if (person.birthDate) {
      const date = new Date(Date.parse(person.birthDate));
      person.birthYear = date.getFullYear();
      person.age = calculateAge(date);
    }
    person.height = Math.round(app.session.user.body_length ?? 0);
    person.weight = Math.round(app.session.user.body_weight ?? 0);
    person.sexe = app.session.user.sexe;
    person.job = app.session.user.job;
    
  }

  async _1_showIntroduction(wf) {
    await wf.introduceLynn(1000);

    await wf.ui(null, {
      renderInput: () => html` <section class="callout">
        <h3>We care deeply about your privacy</h3>
        <div>
          <p>
            Your data is safe with us—we never sell it, and we use it only to
            personalize your experience on Qogni.
          </p>
          <p>
            For details, check out our
            <a target="_blank" href="https://qogni.com/legal/"
              >Privacy Policy</a
            >
            and
            <a target="_blank" href="https://qogni.com/legal/"
              >Terms of Service</a
            >.
          </p>

          <p>By continuing, you agree to these terms.</p>
        </div>
      </section>`,
    });
  }

  async _2_getPersonalDetails(wf, person) {
    person.birthDate = await wf.ask(
      `What's your birthdate, ${person.name}?`,
      UI.birthdate
    );

    const date = new Date(Date.parse(person.birthDate));

    person.birthYear = date.getFullYear();
    person.age = calculateAge(date);

    const quote = await wf.askAI(
      `Give me an extremely short, pointy, factual one-liner
      about a health-related breakthrough of the year ${person.birthYear}.

      If you don't have any specific health-related stuff for that year,
      give a (non-American) sports highlight in under 10 words instead.


      IMPORTANT: if you have ANY problem getting or showing this info, please revert to showing a well-known sports fact about that year!
      `,
      {
        scope: false,
      }
    );

    await wf.ui(null, {
      renderInput: () =>
        html`<h3>${person.birthYear} was a great year for World Health!</h3>
          <blockquote>${quote}</blockquote>`,
    });

    person.height = await wf.ask("How tall are you (in CM)?", UI.height);

    if (person.height < 100) wf.back(UI.height.name);

    person.weight = await wf.ask("And what do you weigh (in KG)?", UI.weight);

    person.sexe = await wf.ask(`${person.name}, what's your sex? ¹`, UI.sexe);

    person.job = await wf.ask(
      `Tell me ${person.name}, what do you do for a living?`,
      UI.job
    );

    await wf.reward(html`<h3>Awesome!</h3>`);
  }

  async _3_getHealthDetails(wf, person) {
    person.foodPreference = await wf.ask(
      "What would you call yourself in terms of food intake?",
      UI.foodChoices
    );

    console.log("person.foodPreference", person.foodPreference);

    person.hasAllergies = await wf.ask(
      `Do you have any allergies or intolerances, ${person.name}?`,
      UI.hasAllergies
    );

    if (person.hasAllergies) {
      person.allergies = await wf.ask(
        `So what are your allergies and/or intolerances?`,
        UI.allergies
      );

      if (person.allergies.includes("Gluten"))
        person.hasCeliacDisease = await wf.ask(
          `Do you have Celiac Disease?`,
          UI.celiac
        );
    }

    person.healthScore = await wf.ask(
      `How would you rate your current health, overall, ${person.name}?`,
      UI.healthRating
    );

    person.healthTopics = await wf.ask(
      `${UI.healthTopics.scores[person.healthScore]}
        What are the 3 most important health-related topics for you?`,
      UI.healthTopics
    );

    person.topicSummary = await wf.askAI(
      this.createTopicSummarizationPrompt(person.healthTopics)
    );

    UI.intention.title = "Here's what you told us";
    person.healthIntention = await wf.ask(
      `${person.topicSummary}.`,
      UI.intention
    );

    const advicePrompt = this.createQuickAdvicePrompt(wf, person);

    person.quickAdvice = await wf.askAI(advicePrompt, {
      format: "string",
      html: true,
    });

    const debugPrompt = person.healthAdviceOnly
      ? `<br/><textarea readonly cols="80" rows="20">${advicePrompt}</textarea>`
      : ``;

    return await wf.ui(null, {
      renderInput: () =>
        unsafeHTML(`<h3>Lynn's advice</h3>${person.quickAdvice}${debugPrompt}`),
    });
  }

  async _4_showUserAround(wf, person) {
    await wf.ui(`Here's what you can expect in Qogni, ${person.name}  `, {
      ...UI.video,
      url: "https://dev.qogni.io/assets/videos/onboarding/onboarding-mobile-v1.mp4",
    });
  }

  async _5_nextStep(wf, person) {
    const nextStep = await wf.ask(
      `Select what you want to do next, ${person.name}...`,
      UI.afterOnboarding
    );
    switch (nextStep) {
      case "bc":
        app.goTo("/braincheck");
        break;
      default:
        app.goTo("/", {
          force: true,
        });
        break;
    }
  }

  //#endregion

  //#region Local Flow actions

  async introduceLynnAction(step) {
    step.render = () => html`
      <figure
        title="Lynn, your AI assistant"
        class="lynn intro"
        @click=${() => {
          this.workflow.end();
        }}
      >
        <img
          src="https://dev.qogni.io/assets/ugc/profile_picture/8478c07f-8a7a-4eb6-beee-befa106ecad6"
          alt="Profile picture of Lynn"
          class="circle"
        />
        <figcaption>
          <span class="when-completed">Lynn - AI assistant</span>
          <h2 class="when-running">
            <type-writer
              speed="2"
              @cycle=${(e) => {
                e.target.stop();
                setTimeout(() => {
                  try {
                    //e.target.remove();
                  } catch {
                    /**/
                  }
                  step.resolve();
                }, step.options.topic);
              }}
              suffix=""
              .items=${[
                "Hello",
                "I'm Lynn",
                "Let me introduce myself...",
                "I'm your AI Health Assistant",
              ]}
            ></type-writer>
          </h2>
        </figcaption>
      </figure>
    `;
    step.on("continue-request", (e) => {
      e.continue();
    });
  }

  async languageDisclaimerAction(step) {
    setTimeout(() => {
      step.resolve();
    }, 2000);
    step.render = () => html`
      <h3>${LANG_DISCLAIMERS[app.session.user.language]}</h3>
    `;
    step.on("continue-request", (e) => {
      e.continue();
    });
  }

  //#endregion

  //#region helpers
  createTopicSummarizationPrompt(healthTopics) {
    return `A person indicates that the most important health-related topics for them to work on are:

    ${healthTopics.join(", ")}

    Summarize and paraphrase the above, and address the person in the second form.`;
  }

  createQuickAdvicePrompt(wf, person) {
    let personSpec = "person",
      nouns = "themselves";
    switch (person.sexe) {
      case 1:
        personSpec = "man";
        nouns = "himself";
        break;
      case 2:
        personSpec = "woman";
        nouns = "herself";
        break;
    }
    let allergySummary = "";
    if (person.hasAllergies && person.allergies?.length)
      allergySummary = `, and suffers from these allergies/intolerances: ${person.allergies.join(
        ", "
      )}`;

    let hti = 0;
    const healthTopicList = person.healthTopics
      .map((name) => {
        hti++;
        return `${hti}: ${name}
`;
      })
      .join("");

    const prompt = `Give quick advice to ${person.name}, a ${personSpec} aged ${person.age}, who rates ${nouns} as ${person.healthScore}/5 in terms of overall health.

When giving advice, please address ${person.name} directly and in an informal manner.

Before the actual advice, please start by summarizing/paraphrasing the health intentions provided by ${person.name} as quoted below:

"${person.healthIntention}"

These are ${person.name}'s most important health topics, ordered by priority:
    
${healthTopicList}

By the way, ${person.name} has a ${person.foodPreference} diet ${allergySummary}.

Please make your advice extremely concise, separated into 3 groups (presented as H3 headings): 

food/nutrition, behavior, and 'other'.`;

    return prompt;
  }

  //#endregion
}
