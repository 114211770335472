import { ApiRequest } from "../shared/APIRequest";
import { AppDomainHandler } from "../shared/app-domain-handler";

export class NotificationDomain extends AppDomainHandler {
  #api;

  constructor() {
    super();
    this.#api = ApiRequest.factory();
  }

  async getNotifications(options) {
    return await this.#api.getData(
      `/notifications?${new URLSearchParams(options?.query ?? {})}`
    );
  }

  async getNotificationById(notificationId) {
    return await this.#api.getData(`/notifications/${notificationId}`);
  }

  async markReadBulk(untilDate) {
    return await this.#api.postData(`/notifications/read`, {
      until: untilDate.toISOString(),
    });
  }

  formatDateTime(date, withTime = true) {
    const today = new Date();

    let extraOptions = {
      mode: "auto",
      languages: navigator.languages,
      rangeDays: 7,
    };

    // If today, return only the time.
    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      extraOptions = { ...extraOptions, timeOnly: true, rangeHours: 6 };
      return new Date(date).format(extraOptions);
    }

    if (!withTime) {
      extraOptions = {...extraOptions, mode: 'format', timeStyle: undefined}
    }

    return new Date(date).format(extraOptions);
  }
}
