import { ApiRequest } from "../shared/APIRequest";
import { AppDomainHandler } from "../shared/app-domain-handler";
import { removeFalsyValues } from "@qogni-technologies/design-system/src/shared/common";

export const workoutDurations = [
  { value: 10, label: "10 Minutes" },
  { value: 15, label: "15 Minutes" },
  { value: 30, label: "30 Minutes" },
  { value: 45, label: "45 Minutes" },
  { value: 60, label: "60 Minutes" },
  { value: 90, label: "90 Minutes" },
  { value: 120, label: "120 Minutes" },
];

export class WorkoutDomain extends AppDomainHandler {
  #api;

  constructor() {
    super();
    this.#api = ApiRequest.factory();
  }

  async getWorkoutsList(options) {
    let result;
    options = removeFalsyValues(options);

    const query = {
      page: 1,
      ...(options && { ...options }),
    };
    let url = `/workouts?${new URLSearchParams(query)}`;

    result = await this.#api.getData(url);

    if (!result.status) return false;
    return result;
  }

  async getWorkoutCategories() {
    const result = await this.#api.getData(`/workout_categories`);

    if (!result.status) return false;
    return result;
  }

  async getWorkout(workoutId) {
    let result = await this.#api.getData(`/workouts/${workoutId}`);

    if (!result.status) return false;
    return result;
  }
}
