import { LitElement, html } from "lit";
import { version as packageVersion } from "../../../package.json";
import { ref, createRef } from "lit/directives/ref.js";
import { ApiRequest } from "../APIRequest";
import { markdownToHtml } from "@qogni-technologies/design-system/src/shared/common";
import { MessagesDomain } from "../../domain/messages-domain";
import { autoEscape, nativeWebShare, truncateString } from "../common";
import { AutoComplete } from "pure-web/ac";
import { SpeechTranscriber } from "../speech-transcriber";

const textFilter = (options, propertyName) => {
  return function (i) {
    if (!options.search) return true;

    if (i.hidden) return false;

    const prop = propertyName ? i[propertyName] : i;
    const isMatch = prop.match(new RegExp(`\\b(${options.search})\\w*`, "gi"));

    if (isMatch) return isMatch;

    if (i.config?.tags) {
      return i.config.tags.some((tag) => {
        return tag.match(new RegExp(options.search, "gi"));
      });
    }
  };
};

const isMobileView = () => {
  return screen.availWidth <= 600;
};

const createAISearchCategory = () => {
  const iconMap = {
    glossary_definitions: "info",
    posts: "timeline",
    options: "solution",
    articles: "document",
    recipes: "food",
    workouts: "workout",
  };

  const getItemText = (item) => {
    switch (item.type) {
      case "recipes":
        return item.title;
      default:
        return truncateString(item.content ?? item.title, 60);
    }
  };

  const getItemDescription = (item) => {
    switch (item.type) {
      case "glossary_definitions":
        return "Click to view explanation";
      case "options":
        return "Click to share this Tip, or copy to clipboard";
      case "articles":
        return "Click to read article";
      case "posts":
        return "Click to view post";
      case "channels":
        return "Click to go to channel";
      case "recipes":
        return "Click to view recipe";
      default:
        return "";
    }
  };

  const getActionForResult = (item) => {
    switch (item.type) {
      case "glossary_definitions":
        return function () {
          app.showGlossaryTerm(item.title);
        };
      case "posts":
        return function () {
          location.href = `/posts/${item.id}`;
        };
      case "channels":
        return function () {
          location.href = `/network/channels/${item.id}`;
        };
      case "articles":
        return function () {
          location.href = `/articles/${item.id}`;
        };
      case "options":
        return async function () {
          await nativeWebShare({
            title: "Share tip",
            text: item.content ?? item.title,
          });
        };
      case "recipes":
        return function () {
          location.href = `/recipes#${item.id}`;
        };

      case "workouts":
        return function () {
          location.href = `/workouts#${item.id}`;
        };

      default:
        return function () {
          alert("Not implemented");
        };
    }
  };

  const api = ApiRequest.factory();

  return {
    sortIndex: 100,
    action: (options) => {
      const action = getActionForResult(options);
      action();
    },
    trigger: (options) => {
      const wordCount = options.search.trim().split(" ").length;
      return (
        options.search.length > 2 &&
        wordCount < 4 &&
        options.search.indexOf("?") === -1
      );
    },
    getItems: async (options) => {
      const data = await api.postData(`/search/content`, {
        signal: options.signal,
        query: options.search,
        options: {
          top: 10,
        },
      });

      return data.map((item) => {
        return {
          ...item,
          text: markdownToHtml(getItemText(item)),
          description: getItemDescription(item),
          icon: iconMap[item.type] ?? item.type,
        };
      });
    },
  };
};

const actions = [
  {
    text: "Feedback",
    icon: "msg-badge",
    action: () => {
      app.sendFeedback();
    },
  },
  {
    text: "About",
    icon: "info",
    action: () => {
      alert(`App version ${packageVersion}`);
    },
  },
];

customElements.define(
  "fab-search",
  class FabSearch extends LitElement {
    #inputRef = createRef();
    #navRef = createRef();
    #messageDomain = new MessagesDomain();

    static get properties() {
      return {
        language: {
          type: String,
        },
      };
    }

    get omniBoxOptions() {
      const usersAutoComplete = this.getUserAutoComplete();

      return {
        //debug: true,

        hideCategory: true,
        controller: this.autoCompleteController,
        throttleInputMs: 250,

        categories: {
          Pages: {
            sortIndex: 0,
            trigger: (options) => {
              return !options.search.endsWith("?");
            },
            action: (options) => {
              let hash = options?.tags ? "#tag-" + options?.tags[0] : "";
              location.href = options.path + hash;
            },
            getItems: async (options) => {
              let tagsMatched = [];
              return app.config.pages
                .filter((i) => !i.hidden && !i.parentRoute && i.config?.icon)
                .filter((i) => {
                  const isNameMatch = i.name
                    .toLowerCase()
                    .startsWith(options.search.toLowerCase());

                  let itemTagsMatched = [];

                  if (options.search.length > 2) {
                    if (i.config?.tags) {
                      itemTagsMatched = i.config.tags.filter((tag) => {
                        return tag.match(new RegExp(options.search, "gi"));
                      });

                      if (itemTagsMatched.length)
                        tagsMatched.push(itemTagsMatched);
                    }
                  }

                  return itemTagsMatched.length || isNameMatch;
                })
                .map((i) => {
                  let tags = tagsMatched
                    .map((tag) => {
                      return `<badge-tag class="outlined">${tag}</badge-tag>`;
                    })
                    .join("");

                  return {
                    text: i.name,
                    path: i.route,
                    icon: i.config.icon,
                    tags: tags,
                    description: i.config?.description ?? "",
                  };
                });
            },
          },

          App: {
            sortIndex: 20,
            trigger: (options) => {
              return options.search.length > 0 && !options.search.endsWith("?");
            },
            action: (options) => {
              options.action(options);
            },
            getItems: async (options) => {
              return actions.filter(textFilter(options, "text"));
            },
          },
          Content: createAISearchCategory(),
          Users: {
            sortIndex: 11,
            trigger: (options) => {
              return options.search.length > 3 && !options.search.endsWith("?");
            },
            action: (options) => {
              usersAutoComplete.action(options);
            },
            getItems: async (options) => {
              return await usersAutoComplete.getItems(options);
            },
          },
          Questions: {
            sortIndex: (options) => {
              return options.search.endsWith("?") ? 1000 : 30;
            },
            trigger: (options) => {
              return options.search.length > 5 || options.search.endsWith("?");
            },
            format: (options) => {
              const i = options.item;
              return /*html*/ `<span class="q-${i.type}"><h4>${i.text}</h4><div>${i.question}</div><span>`;
            },
            action: (options) => {
              this.#messageDomain.askQoach(options.question);
            },

            getItems: async (options) => {
              return [
                {
                  icon: "comment",
                  type: "ac",
                  text: `Ask Qogni Qoach`,
                  question: options.search,
                },
              ];
            },
          },
        },
      };
    }

    createRenderRoot() {
      return this;
    }

    render() {
      const me = this;
      return html`
        <nav
          title="CTRL/CMD-Space to activate..."
          class="fab-search"
          ${ref(this.#navRef)}
        >
          <textarea
            class="search-input"
            rows="1"
            data-enter-change
            ${ref(this.#inputRef)}
            type="search"
            placeholder="Find anything in Qogni..."
            name="global-omnibox"
            @focus=${(e) => {
              me.autoCompleteImpl = AutoComplete.connect(
                e,
                this.omniBoxOptions
              ).on("result-selected", () => {
                this.close(true);
              });
            }}
            @blur=${(e) => {
              if (e.target.nodeName !== "TEXTAREA")
                setTimeout(this.close.bind(this), 300);
            }}
          ></textarea>
          <button id="enable-voice" @click=${this.enableVoice}>
            <svg-icon icon="microphone" size="20px"></svg-icon>
            <small>${me.language}</small>
          </button>
          <fab-button
            id="fab"
            class="simple"
            tabindex="0"
            @click=${this.fabClick}
          >
            <svg-icon icon="search" size="20px"></svg-icon>
          </fab-button>
        </nav>
      `;
    }

    enableVoice() {
      const me = this;

      if (me.transcriber && me.transcriber.recording) {
        me.transcriber.stop();
        return;
      }

      const input = this.#inputRef.value;
      input._placeholder = input.getAttribute("placeholder");

      if (me.transcriber) me.transcriber.stop();

      me.transcriber = new SpeechTranscriber(me.language, function (data) {
        input.value = data;
        input.blur();
        me.nav.classList.toggle("multiline", true);
        if (me.autoCompleteImpl) {
          me.autoCompleteImpl.hide();
        }
        setTimeout(() => {
          input.focus();
        }, 1000);
      })
        .on("start", () => {
          input.setAttribute("placeholder", "Listening, please speak...");
          me.classList.toggle("listening", true);
          input.addEventListener(
            "click",
            () => {
              if(me.transcriber) me.transcriber.stop();
              input.focus();
            },
            {
              once: true,
            }
          );
        })
        .on("stop", () => {
          input.setAttribute("placeholder", input._placeholder);
          me.classList.toggle("listening", false);
          me.transcriber = null;
        })
        .start();
    }

    close(force) {
      if (this.omniBoxOptions.debug) return;

      this.nav.classList.toggle("searching", false);
      this.querySelector("#fab svg-icon").setAttribute("icon", "search");

      if (force || this.searchInput.value.indexOf(" ") === -1)
        this.nav.classList.toggle("multiline", false);

      if (force) this.#inputRef.value.value = "";

      if (this.transcriber) this.transcriber.stop();
    }

    open() {
      this.nav.classList.toggle("searching", true);
      this.querySelector("#fab svg-icon").setAttribute("icon", "close");

      if (this.nav.classList.contains("searching")) {
        setTimeout(() => {
          try {
            this.nav.querySelector(".search-input").focus();
          } catch {
            //aa
          }
        }, 100);
      }
    }

    toggle(force) {
      this.nav.classList.contains("searching")
        ? this.close(force)
        : this.open();
    }

    firstUpdated() {
      this.searchInput.addEventListener("input", () => {
        const wordCount = this.searchInput.value.trim().split(" ").length;

        this.nav.classList.toggle("multiline", wordCount > 4);
      });

      autoEscape(this, this.close.bind(this));

      window.addEventListener("keypress", (e) => {
        if (e.key === " " && (e.metaKey || e.ctrlKey)) {
          e.preventDefault();
          this.open();
        }
      });
    }

    autoCompleteController(ac) {
      return {
        show: () => {
          if (isMobileView()) ac.resultsDiv.style.display = "block";
          else ac.show();
        },
        hide: () => {
          if (isMobileView()) ac.resultsDiv.style.display = "none";
          else ac.hide();
        },
        empty: () => {
          ac.empty();
        },
      };
    }

    getUserAutoComplete() {
      const networkHandler = app.config.routes["/network"];
      return new networkHandler.run().connectionsAutoComplete.categories[
        "Users"
      ];
    }

    get searchInput() {
      return this.#inputRef.value;
    }

    get nav() {
      return this.#navRef.value;
    }

    fabClick() {
      this.language = app.session.user.language.split("-")[0].toLowerCase();

      this.toggle(true);

      document.addEventListener(
        "mousedown",
        (e) => {
          if (!this.nav.contains(e.target)) {
            this.nav.classList.toggle("searching", false);
          }
        },
        {
          once: true,
        }
      );
    }

    // startDialog(text, options = { type: "notification" }) {
    //   this.nav.classList.add("ringing");
    // }
  }
);
