import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html, nothing } from "lit";
import { createRef, ref } from "lit/directives/ref.js";
import { repeat } from "lit/directives/repeat.js";
import { AccountDomain } from "../../domain/account-domain";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../shared/pwa-page";

export class PageFollowing extends OnboardedMixin(AuthenticatedMixin(PWAPage)) {
  #domain;
  #currentPage = 0;
  #lastPage;
  #infinityListElement = createRef();

  static get properties() {
    return {
      activeTab: { type: Number },
      usersList: { type: Array },
    };
  }

  constructor() {
    super();
    this.#domain = new AccountDomain();
    this.activeTab = "following";
  }

  connectedCallback() {
    super.connectedCallback();

    if (window.location.hash) {
      this.activeTab = window.location.hash.substring(1);
    }

    this.#fetch();
  }

  async #fetch() {
    const task = async () => {
      if (this.activeTab === "following") {
        const res = await this.#domain.getFollowing(this.#currentPage);
        this.usersList = res.data;
        this.#lastPage = res?.pagination?.last_page;
      } else if (this.activeTab === "followers") {
        const res = await this.#domain.getFollowers(this.#currentPage);
        this.usersList = res.data;
        this.#lastPage = res?.pagination?.last_page;
      } else if (this.activeTab === "connections") {
        const res = await this.#domain.getConnections({
          page: this.#currentPage,
        });
        this.usersList = res.data;
        this.#lastPage = res?.pagination?.last_page;
      }
    };

    await Task.run(task);
  }

  render() {
    return html`
      <section class="hero center">
        <h1>Following / Followers</h1>
      </section>

      ${this.#renderTabs()} ${this.#renderList()}
    `;
  }

  #renderTabs() {
    const tabs = [
      { id: "following", title: "Following" },
      { id: "followers", title: "Followers" },
      { id: "connections", title: "Connections" },
    ];
    return html`
      <tabs-component>
        ${repeat(tabs, (tab) => {
          return html` <button
            class="mb-tiny ${this.activeTab === tab.id ? "blue" : "outline"}"
            @click=${() => this.handleTabClick(tab.id)}
          >
            ${tab.title}
          </button>`;
        })}
      </tabs-component>
    `;
  }

  #renderList() {
    if (!this.usersList) {
      return html`
        <single-connection>
          <app-shimmer class="image circle"></app-shimmer>
          <div class="shimmer-flex">
            <app-shimmer></app-shimmer>
            <app-shimmer class="tiny"></app-shimmer>
          </div>
        </single-connection>
        <single-connection>
          <app-shimmer class="image circle"></app-shimmer>
          <div class="shimmer-flex">
            <app-shimmer></app-shimmer>
            <app-shimmer class="tiny"></app-shimmer>
          </div>
        </single-connection>
        <single-connection>
          <app-shimmer class="image circle"></app-shimmer>
          <div class="shimmer-flex">
            <app-shimmer></app-shimmer>
            <app-shimmer class="tiny"></app-shimmer>
          </div>
        </single-connection>
      `;
    }
    return html`
      <infinite-list
        ${ref(this.#infinityListElement)}
        .renderItem=${this.#renderItem.bind(this)}
        @scroll-end=${this.#onScrollEnd.bind(this)}
      ></infinite-list>
    `;
  }

  #renderItem(u) {
    const {
      id,
      slug,
      firstname,
      lastname,
      profile_img_url,
      badges,
      followers_count,
      following_count,
      has_connection,
    } = u;
    return html`
      <single-connection data-id="${id}">
        <profile-picture
          name="${firstname} ${lastname}" 
          img="${profile_img_url}"
          uuid="${id}"
          link=${`/profile/${slug || id}`}
          size="56px" >
        </profile-picture>

        <div class="info">
          <a href="/profile/${slug ?? id}">
            <h4>${firstname} ${lastname}</h4>
            ${badges
              ? html`
                  ${repeat(
                    badges,
                    (b) => html`
                      <badge-tag class="small blue filled">
                        <svg-icon icon="crown"></svg-icon> ${b.name}
                      </badge-tag>
                    `
                  )}
                `
              : nothing}
          </a>

          <div class="group">
            <div class="related">
              <svg-icon size="16px" icon="link"></svg-icon>
              <span class="count"> ${followers_count} </span>
            </div>

            <div class="connections">
              <svg-icon size="16px" icon="account-add"></svg-icon>
              <span class="count"> ${following_count} </span>
            </div>
          </div>
        </div>

        <div class="controls">
          ${this.activeTab === "following"
            ? html`
                <button
                  class="beige tiny"
                  type="button"
                  @click=${(e) => this.#unfollowUser(e, u)}
                >
                  <svg-icon size="16px" icon="minus"></svg-icon>
                  Unfollow
                </button>
              `
            : nothing}
          ${this.activeTab === "followers" && !has_connection
            ? html`
                <button
                  class="beige tiny"
                  type="button"
                  @click=${() => this.#followUser(u)}
                >
                  <svg-icon size="16px" icon="plus"></svg-icon>
                  Follow back
                </button>
              `
            : nothing}
        </div>
      </single-connection>
    `;
  }

  async #refreshList() {
    this.#currentPage = 1;
    await this.#fetch();
    this.#infinityListElement.value.addItems(this.usersList, true);
  }

  async #followUser(user) {
    const target = event.target;
    const singleConnection = target.closest("single-connection");

    const task = async () => {
      const { id } = user;
      await this.#domain.followUser(id);
      await this.#refreshList();
    };

    await Task.run(task, {
      ghost: singleConnection,
    });
  }

  async #unfollowUser(event, user) {
    const target = event.target;
    const singleConnection = target.closest("single-connection");

    const task = async () => {
      const { id } = user;
      await this.#domain.unfollowUser(id);
      await this.#refreshList();
    };

    await Task.run(task, {
      ghost: singleConnection,
    });
  }

  async #onScrollEnd() {
    this.#currentPage++;
    if (this.#currentPage === 1 || this.#currentPage <= this.#lastPage) {
      await this.#fetch();
      this.#infinityListElement.value.addItems(this.usersList);
    }
  }

  async handleTabClick(id) {
    this.activeTab = id;
    window.location.hash = `#${id}`;

    this.usersList = undefined;
    this.#currentPage = 1;
    await this.#fetch();
    this.#infinityListElement.value.addItems(this.usersList, true);
  }
}
