import { html, nothing } from "lit";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  WidgetEnabledPWAPage,
} from "../../shared/pwa-page";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { ProgramDomain } from "../../domain/program-domain";
import { repeat } from "lit/directives/repeat.js";
import { dateFormat } from "@qogni-technologies/design-system/src/shared/common";
import {msg, str} from '@lit/localize';

export class PageProgram extends OnboardedMixin(
  AuthenticatedMixin(WidgetEnabledPWAPage)
) {
  #domain;

  constructor() {
    super();
    this.program = null;
    this.loading = true;
    this.pending = false;
    this.startDate = null;
    this.programDays = [];
    this.#domain = new ProgramDomain();
  }

  static properties = {
    loading: { type: Boolean, attribute: false },
    pending: { type: Boolean, attribute: false },
    startDate: { type: Date, attribute: false },
    program: { type: Object, attribute: false },
    showingPastDays: { type: Boolean, attribute: false },
    programDays: { type: Array, attribute: false },
    checkedOffData: { type: Array, attribute: false },
  };

  isNotStartedYet() {
    // Compare with the this.startDate and now and see if it's today or after today.
    if (!this.startDate) return false;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return this.startDate.getTime() > today.getTime();
  }

  connectedCallback() {
    super.connectedCallback();
    if (!app.session.initialized) {
      app.session.on("initiated", () => {
        this.requestUpdate();
      });
    }

    this.#getProgram(); // fetch from server.

    try {
      if (localStorage.getItem("checked-off-program-items_v2"))
        this.checkedOffData =
          JSON.parse(localStorage.getItem("checked-off-program-items_v2")) ??
          [];
    } catch (e) {
      console.warn(e);
    }
    if (!this.checkedOffData) this.checkedOffData = [];
  }

  async #getProgram(includingPast = false) {
    return Task.run(async () => {
      try {
        const options = { query: {} };
        if (includingPast) options.query["including_past"] = true;
        
        const response = await this.#domain.getRunningProgram(options);
        this.program = response.data;
        this.showingPastDays = includingPast;
        this.programDays = response.data?.program_days;
        this.loading = false;
        this.startDate = new Date(response.data.start_date);
        this.startDate.setHours(0, 0, 0, 0);
      } catch (e) {
        this.loading = false;
        if (e?.response?.status === 403) {
          this.pending = true;
          this.loading = false;
          this.startDate = new Date(e?.errorData?.data?.start_date);
          this.program = e?.errorData?.data?.program;
        } else if (! this.widget) {
          window.location.replace("/program/activation");
        } else {
          this.program = null;
        }
      }
    });
  }

  getWidgetSettings(){
    return {
      ...super.getWidgetSettings(),
      priority: (app?.session?.user?.active_program ? 830: 700),
      title: msg('My program', { desc: "Title used in program widget" }),
      cta: {
        href: '/program',
      }
    };
  }

  renderWidget() {
    if (this.loading) return html`
      <app-shimmer style="height: 200px; width: 100%; margin-bottom: 0;"></app-shimmer>
    `;
    if (! this.program) return html`
      <section class="card">
        <h3>${msg("No active program", { desc: "Shown when no programs are running or selected."})}</h3>
        <p>
          ${msg("Please make sure you activate your program.", { desc: "Prompt shown when a program needs activation." })}
        </p>
        <a href="/program" class="button tiny outline">
          ${msg("More information")}
        </a>
      </section>
    `;

    return html`
      <a href="/program">
        <section class="card background-image" style="--img: url(/assets/img/workout.webp);">
          <h3>${this.program.name}</h3>
          <h4>${msg("Elevate your well-being with our Shaping Program!", { desc: "Promotional message encouraging users to try the Shaping Program." })}</h4>
        </section>
      </a>
    `;
  }

  renderPage() {
    if (this.loading)
      return html`
        <app-shimmer class="title"></app-shimmer>
        <app-shimmer class="tiny"></app-shimmer>
        <app-shimmer class="mb"></app-shimmer>

        <app-shimmer class="title"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer class="mb"></app-shimmer>

        <app-shimmer class="title tiny"></app-shimmer>
        <app-shimmer class="image"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
      `;
    if (this.pending && this.program)
      return html`
        <section class="hero center">
          <h1>${msg("Your program is about to start", { desc: "Notification shown shortly before a program begins." })}</h1>
        </section>

        <section class="card red">
          <h2>${this.program.name}</h2>
          <p>${this.program.description}</p>
          <section class="card">
            ${msg("Get ready, your program will start at:", { desc: "Notification prompting the user to prepare before a program starts, followed by the start time." })}
              <strong>${new Date(this.startDate).format({ mode: "auto" })}</strong>
          </section>
        </section>`;

    if (app.session.user?.active_program?.program_id && this.program) {
      return this.renderProgramOverview();
    }

    return html`${msg("No program found.", { desc: "Displayed when no matching program is available or located." })} <a href="/program/activation" class="button wide">${msg("Program Activation Page", { desc: "Button label to navigate to the page where users can activate their program." })}</a>`;
  }

  #diffInDays(date1, date2) {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Convert both dates to milliseconds
    const date1_ms = date1.getTime();
    const date2_ms = date2.getTime();

    // Calculate the difference in milliseconds
    const diff_ms = date1_ms - date2_ms;

    // Convert back to days and return
    return Math.round(diff_ms / ONE_DAY);
  }

  #isToday(date) {
    if (!(date instanceof Date)) return false;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return this.#diffInDays(date, today) === 0;
  }

  #friendlyProgramDate(day, plural = false) {
    if (!(day instanceof Date)) return nothing;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const diffDays = this.#diffInDays(day, today);
    if (diffDays === 0) return `Today${plural ? "'s" : ""}`;
    if (diffDays === 1) return `Tomorrow${plural ? "'s" : ""}`;
    if (diffDays === -1) return `Yesterday${plural ? "'s" : ""}`;
    return dateFormat(day, dateFormat.masks.fullDate);
  }

  #showPreviousDays(e) {
    e.preventDefault();

    this.#getProgram(true);
  }

  #changeProgramCheckState(e) {
    const dayId = e.target.closest("section.card").dataset["dayId"];
    const blockId = e.target.closest("section.card").dataset["blockId"];
    const type = e.target.closest("program-check-list-item").dataset["type"];
    const id = e.target.closest("program-check-list-item").dataset["id"];
    if (!dayId || !blockId || !type || !id) return;

    const item = {
      day_id: dayId,
      block_id: blockId,
      type,
      id,
    };

    if (
      this.checkedOffData.some(
        (i) =>
          i.day_id === item.day_id &&
          i.block_id === item.block_id &&
          i.type === item.type &&
          i.id === item.id
      )
    ) {
      if (!e.target.checked) {
        this.checkedOffData = this.checkedOffData.filter(
          (i) =>
            i.day_id !== item.day_id ||
            i.block_id !== item.block_id ||
            i.type !== item.type ||
            i.id !== item.id
        );
        localStorage.setItem(
          "checked-off-program-items_v2",
          JSON.stringify(this.checkedOffData)
        );
      }
      return;
    }
    this.checkedOffData.push(item);
    localStorage.setItem(
      "checked-off-program-items_v2",
      JSON.stringify(this.checkedOffData)
    );
  }

  renderProgramBlock(day, block, idx) {
    let name = "Morning";
    let color = "green";
    if (idx === 1) {
      name = "Lunch";
      color = "yellow";
    }
    if (idx === 2) {
      name = "Afternoon";
      color = "red";
    }
    const { recipe, supplements, workout } = block;
    let isChecked = false;
    if (recipe)
      isChecked = this.checkedOffData.some(
        (item) =>
          item.block_id === block.id &&
          item.day_id === day.id &&
          item.type === "recipe" &&
          item.id === recipe.id
      );
    if (workout)
      isChecked = this.checkedOffData.some(
        (item) =>
          item.block_id === block.id &&
          item.day_id === day.id &&
          item.type === "workout" &&
          item.id === workout.id
      );

    return html`
      <section class="card" data-block-id="${block.id}" data-day-id="${day.id}">
        <h4 class="accent-ball ${color}">${name}</h4>
        ${recipe
          ? html`
              <program-check-list-item
                text="${recipe.name}"
                icon="food"
                data-type="recipe"
                data-id=${recipe.id}
                theme=${color}
                link=${`/recipes#${recipe.id}`}
                ?checked=${isChecked}
                @change=${this.#changeProgramCheckState.bind(this)}
              >
              </program-check-list-item>
            `
          : nothing}
        ${repeat(
          supplements,
          (supplement) => html`
            <program-check-list-item
              text="${supplement.amount > 1
                ? `${supplement.amount}x `
                : ``}${supplement.supplement.name}"
              icon="supplement"
              data-type="supplement"
              data-id=${supplement.id}
              theme=${color}
              ?checked=${this.checkedOffData.some(
                (item) =>
                  item.block_id === block.id &&
                  item.day_id === day.id &&
                  item.type === "supplement" &&
                  item.id === supplement.id
              )}
              @change=${this.#changeProgramCheckState.bind(this)}
            >
            </program-check-list-item>
          `
        )}
        ${workout
          ? html`
              <program-check-list-item
                text="${workout.name}"
                icon="workout"
                data-type="workout"
                data-id=${workout.id}
                theme=${color}
                link=${`/workouts#${workout.id}`}
                ?checked=${isChecked}
                @change=${this.#changeProgramCheckState.bind(this)}
              >
              </program-check-list-item>
            `
          : nothing}
      </section>
    `;
  }

  renderProgramDay(day) {
    const dayDate = new Date(this.startDate);
    dayDate.setDate(dayDate.getDate() + (day.day - 1));
    dayDate.setHours(0, 0, 0, 0);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const diffInDays = this.#diffInDays(dayDate, today);
    return html`
      <details
        class="large no-dot ${diffInDays < 0
          ? "past"
          : diffInDays > 0
          ? "future"
          : "today"}"
        ?open=${this.#isToday(dayDate)}
      >
        <summary>
          ${this.#friendlyProgramDate(dayDate, true)} ${msg("program")}
          <badge-tag class="blue">${msg("Day")} ${day.day}</badge-tag>
        </summary>

        <section>
          ${repeat(day.program_blocks, (block, idx) =>
            this.renderProgramBlock(day, block, idx)
          )}
        </section>
      </details>
    `;
  }

  renderProgramOverview() {
    return html`
        <section class="hero center">
          <h1>${this.program.name}</h1>
        </section>

        ${
          this.isNotStartedYet()
            ? html`
                <section class="card red">
                  <h2>
                    ${msg("Your program will start at:", {desc: "Message indicating the scheduled start time of the program."})}
                    <strong
                      >${new Intl.DateTimeFormat(navigator.languages).format(
                        this.startDate
                      )}</strong
                    >
                  </h2>
                  <p>
                    ${msg(str`To be able to get groceries and start directly at ${new Intl.DateTimeFormat(navigator.languages).format(this.startDate)} we will show you the 7 first days of the program.`, {desc: "Message explaining that the user will see the first 7 days of the program starting on a specific date."})}
                  </p>
                </section>
              `
            : nothing
        }

        <flex-container breakpoint="tiny">
          <flex-item class="col-6">
            <button class="white wide" @click=${this.#showPreviousDays.bind(
              this
            )} ?disabled=${this.showingPastDays}>
              <svg-icon icon="calendar"></svg-icon>
              ${msg("Previous days")}
            </button>
          </flex-item>

          <flex-item class="col-6" data-tooltip="Coming soon" data-tooltip-position="bottom">
            <button class="wide" disabled>
              <svg-icon icon="ul"></svg-icon>
              ${msg("Shopping list")}
            </button>
          </flex-item>
        </flex-container>

        ${repeat(this.programDays, this.renderProgramDay.bind(this))}

        </section>
      `;
  }
}
