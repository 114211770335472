import { html } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { WorkoutDomain } from "../../domain/workout-domain";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
  PullToRefreshMixin,
} from "../../shared/pwa-page";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { createRef, ref } from "lit/directives/ref.js";
import {
  isLandscape,
  isMobile,
} from "@qogni-technologies/design-system/src/shared/common";
import { config } from "../../qogni-app-config";
import { nativeWebShare } from "../../shared/common";

const BaseClass = PullToRefreshMixin(
  OnboardedMixin(AuthenticatedMixin(PWAPage))
);

export class PageSingleWorkout extends BaseClass {
  #domain;
  #resizeObserver;
  #videoPlayerRef = createRef();

  static get properties() {
    return {
      workoutId: { type: String },
      workout: { type: Object },
      loading: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.#domain = new WorkoutDomain();
  }

  async connectedCallback() {
    super.connectedCallback();
    this.#getWorkout(this.workoutId);

    this.addEventListener("refresh", this.#refreshWorkout);

    this.#resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        this.#handleResize(entry.contentRect);
      }
    });
    this.#resizeObserver.observe(document.body);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener("refresh", this.#refreshWorkout);
  }

  willUpdate(chngeProps) {
    if (
      chngeProps.get("workoutId") &&
      this.workoutId !== chngeProps.get("workoutId")
    ) {
      this.#getWorkout(this.workoutId);
    }
  }

  async #getWorkout(id) {
    const task = async () => {
      try {
        this.loading = true;
        const response = await this.#domain.getWorkout(id);
        this.workout = response?.data;
      } catch (err) {
        app.addToastMessage("Workout not found", { type: "error" });
        throw err;
      } finally {
        this.loading = false;
      }
    };

    await Task.run(task);
  }

  async #refreshWorkout() {
    await this.#getWorkout(this.workoutId);
  }

  render() {
    if (this.loading)
      return html`
        <app-shimmer class="title"></app-shimmer>
        <app-shimmer class="tiny"></app-shimmer>
        <app-shimmer class="image"></app-shimmer>
        <app-shimmer class="title tiny"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer class="title tiny"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
      `;
    // TODO: show workout not found view with GO HOME button to redirect Home
    if (!this.workout)
      return html`<a href="/"><button class="wide">Go Home</button></a>`;

    const {
      name,
      workout_category,
      duration,
      related,
      thumbnail_urls,
      video_urls,
    } = this.workout;

    const videoUrls = this.sortVideosByURL(video_urls).map(
      (video) => video.url
    );

    return html`
      <div class="heading" xmlns="http://www.w3.org/1999/html">
        <h1>${name}</h1>
        <button class="small round white" @click=${this.#onShare}>
          <svg-icon icon="${navigator.canShare ? "share" : "copy"}"></svg-icon>
        </button>
      </div>


      <flex-container class="align-items-center">
        <flex-item>
          <badge-tag class="green">${workout_category?.name}</badge-tag>
        </flex-item>

        <flex-item>
          <badge-tag class="simple">
            <svg-icon icon="clock"></svg-icon>
            ${duration || 0} minutes
          </badge-tag>
        </flex-item>
      </flex-container>

      <section class="card xs">
        <video-player
          ${ref(this.#videoPlayerRef)}
          .urls=${videoUrls}
          .poster=${thumbnail_urls[0]}
        ></video-player>
      </section>

      <h2>You may also like</h2>

      ${repeat(related, (item) => {
        return html` <workout-card .workout=${item}> </workout-card> `;
      })}
    `;
  }

  sortVideosByURL(videos) {
    const sortedVideos = [];

    // Separate videos based on URL patterns
    const h2651080pVideos = videos.filter((video) =>
      video.url.includes("1080p_h265_mp4")
    );
    const h265720pVideos = videos.filter((video) =>
      video.url.includes("720p_h265_mp4")
    );
    const h264720pVideos = videos.filter((video) =>
      video.url.includes("720p_h264_mp4")
    );

    // Add filtered videos to the sorted array in the required order
    sortedVideos.push(...h2651080pVideos);
    sortedVideos.push(...h265720pVideos);
    sortedVideos.push(...h264720pVideos);

    // Add remaining videos as they are
    const otherVideos = videos.filter(
      (video) =>
        !video.url.includes("1080p_h265_mp4") &&
        !video.url.includes("720p_h265_mp4") &&
        !video.url.includes("720p_h264_mp4")
    );
    sortedVideos.push(...otherVideos);

    return sortedVideos;
  }

  #handleResize() {
    if (!this.#videoPlayerRef.value) return;
    if (isMobile() && isLandscape() && this.#videoPlayerRef.value.playing) {
      this.#videoPlayerRef.value.requestFullscreen();
    }
  }

  async #onShare() {
    const task = async () => {
      const { absoluteUrl } = config;
      const link = `${absoluteUrl}/recipes#${this.workout.id}`;

      await nativeWebShare({title: this.workout.name, text: link});
    }

    Task.run(task, {
      ghost: this,
      global: false,
    });
  }
}
