import { css, html, LitElement, nothing } from "lit";
import { config } from "../../qogni-app-config";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { nl2br } from "@qogni-technologies/design-system/src/shared/common";
import { SharedButtonStyles, SharedVariables } from "@qogni-technologies/design-system/src/styles/shared-styles.css";

customElements.define(
  "translatable-content",
  class TranslatableContent extends LitElement {
    #text = null;

    static get properties() {
      return {
        currentLanguage: {type: String, attribute: 'current-language'},
        targetLanguage: {type: String, attribute: 'target-language'},
        contentName: {type: String, attribute: 'content-name'},
        userAware: {type: Boolean, attribute: 'user-aware'},
        size: {type: String, attribute: true},
        collapsing: {type: Boolean, attribute: true},
        state: {type: String, attribute: true},
        loading: {type: Boolean, attribute: false},
        translation: {type: String, attribute: false},
      };
    }

    static get styles() {
      return [
        SharedVariables,
        SharedButtonStyles,
        css`
          :host {
            display: block;
            word-break: break-word;

            --translate-button-color: inherit;
            --translate-icon-color: inherit;
          }

          translatable-translation {
            display: block;
            padding-left: 20px;
            border-left: 2px solid #c3c3c3;
          }

          :host > a {
            display: inline-block;
            font-size: 0.8rem;
            margin-top: -5px;
            margin-bottom: 10px;

            color: var(--translate-button-color) !important;

            svg-icon {
              display: inline-block;
              --icon-fill-color: var(--translate-icon-color) !important;
            }
          }
        `
      ];
    }

    constructor() {
      super();
      this.contentName = 'text';
      this.state = 'original';
      this.size = 'normal';
      this.userAware = true;
      this.currentLanguage = null;
      this.targetLanguage = null;
    }

    connectedCallback() {
      super.connectedCallback();
      if (this.userAware) this.targetLanguage = app.session.user?.language;
      if (! this.targetLanguage) { // Set browser language if the target language is not set and the user didn't override it (yet).
        this.targetLanguage = navigator.languages ? navigator.languages[0] : (navigator.language || navigator.userLanguage);
      }
    }

    get shouldShow() {
      if (this.userAware) {
        const userLanguage = app.session.user?.language?.split('-')[0]?.toLowerCase();
        return this.currentLanguage !== userLanguage;
      }
      return true;
    }

    render() {
      return html`
        ${this.state === 'translation' && this.translation ? html`
          <translatable-translation>
            <p class="translation">
              ${typeof this.translation === 'string' ? html`
                ${nl2br(this.translation)}
              ` : nothing}
            </p>
          </translatable-translation>
        ` : nothing}

        <slot ?hidden=${this.state === 'translation' && this.translation}></slot>

        ${config.enableTranslations && !this.loading ? html`
          ${this.state === 'original' && this.shouldShow ? html`
            <a href="#" @click=${this.translateText.bind(this)} class="button simple">
              <svg-icon icon="translate-qogni" size="18px"></svg-icon>
              ${this.targetLanguage === 'nl-NL' ? html`
                Vertaal deze ${this.contentName}
              ` : html`
                Translate this ${this.contentName}
              `}
            </a>
          ` : (this.shouldShow ? html`
            <a href="#" @click=${this.originalText.bind(this)} class="button simple">
              <svg-icon icon="translate-qogni" size="18px"></svg-icon>
              ${this.targetLanguage === 'nl-NL' ? html`
                Toon de originele ${this.contentName}
              ` : html`
                Show original ${this.contentName}
              `}
            </a>
          ` : nothing)}
        ` : nothing}
      `;
    }

    translateText(e) {
      e.preventDefault(); e.stopPropagation();

      if (! this.#text) {
        this.#text = '';
        const slot  =this.shadowRoot.querySelector('slot');
        const slottedElements = slot.assignedElements();
        slottedElements.forEach((el) => {
          this.#text += el.innerText + "\n";
        });
      }

      if (! this.translation) {
        this.loading = true;
        Task.run(async () => {
          this.translation = await app.translator.translateText(this.#text);
          this.loading = false;
        }, {
          ghost: this.shadowRoot.querySelector('slot'),
          description: 'Translation fetch'
        });
      }

      this.state = 'translation';
    }

    originalText(e) {
      e.preventDefault(); e.stopPropagation();

      this.state = 'original';
    }
  }
);
