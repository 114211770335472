import "../settings";
import "../notifications";
import "../messages/messages";

import { html, nothing } from "lit";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  WidgetEnabledPWAPage,
} from "../../shared/pwa-page";
import { AccountDomain, FoodPreferences } from "../../domain/account-domain";
import { throttle } from "@qogni-technologies/design-system/src/shared/common";

import "./manage-emails";
import { resizeImage } from "@qogni-technologies/pwa-utils-library/src/utils/resize-image";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { msg, str } from "@lit/localize";
import { repeat } from 'lit/directives/repeat.js';
import { sexes } from "../../shared/qogni-flow";

export class PageAccount extends OnboardedMixin(
  AuthenticatedMixin(WidgetEnabledPWAPage)
) {
  #domain;
  #topics = [];

  #deleteVisible = false;
  #deleteJwt = null;
  #deleteReason;
  #deleteReasonExtra;


  static get properties() {
    return {
      deleteVisible: { type: Boolean },
      deleteJwt: { type: String },
    };
  }

  constructor() {
    super();
    this.#domain = AccountDomain.singleton;
    this.deleteVisible = false;
  }

  get allergiesAutoComplete() {
    return {
      hideCategory: true,
      cache: false,
      categories: {
        Preferences: {
          getItems: () => {
            return FoodPreferences.filter(
              (p) => p.category === "Preferences"
            ).map((p) => {
              return {
                text: p.name,
              };
            });
          },
        },
        Allergy: {
          getItems: () => {
            return FoodPreferences.filter(
              (p) => p.category === "Allergies"
            ).map((p) => {
              return {
                text: p.name,
              };
            });
          },
        },
      },
    };
  }

  get goalsAutoComplete() {
    return {
      hideCategory: true,
      categories: {
        Goals: {
          getItems: () => {
            return this.#topics.map((t) => {
              return {
                text: t.name,
              };
            });
          },
        },
      },
    };
  }

  async #handleUploadImage(e) {
    if (!e.target.files || e.target.files.length !== 1) return;

    const task = async () => {
      const imageFile = e.target.files[0];
      const resizedImage = await resizeImage(imageFile, {
        maxWidth: 500,
        maxHeight: 500,
        contentType: "image/jpeg",
        quality: 7.5,
      });

      try {
        await this.#domain.uploadProfileImage(
          resizedImage.url,
          resizedImage.contentType
        );
        app.addToastMessage("Profile picture updated!");
      } catch (err) {
        if (!err.errorData) {
          return app.addToastMessage(
            "Unknown error when uploading new image, try again later or with a different picture.",
            { type: "error" }
          );
        }
        app.addToastMessage(err.errorData.errors.file.join(","), {
          type: "error",
        });
      }

      await app.session.refreshUser(true);
      app.topBar.requestUpdate();
    };

    return Task.run(task, {
      ghost: e.target.closest(".profile-picture"),
      description: "Updating profile image",
    });
  }

  async #deleteProfilePicture(e) {
    const task = async () => {
      await this.#domain.deleteProfilePicture();
      await app.session.refreshUser();
      app.topBar.requestUpdate();
    };

    return Task.run(task, {
      ghost: e.target.closest(".profile-picture"),
      description: "Updating profile image",
    });
  }

  // #updateFoodPreferences(e) {
  //   const keyObj = {};
  //   for (const pref of FoodPreferences) {
  //     keyObj[pref.key] =
  //       e.detail.value.filter((p) => p === pref.name).length > 0;
  //   }
  //   return keyObj;
  // }
  // #updateGoalsPreferences(e) {
  //   const keyObj = { interest_topics: [] };
  //   for (const goal of e.detail.value) {
  //     const goalFiltered = this.#topics.filter((g) => g.name === goal);
  //     if (goalFiltered.length !== 1) continue;
  //     keyObj["interest_topics"].push(goalFiltered[0].id);
  //   }
  //   return keyObj;
  // }

  /**
   * On-the-fly part of the form, change actions.
   * @param e
   * @returns {Promise<void>}
   */
  async #updateForm(e) {
    if (e.detail.name === "") return;
    let updatedData = {};

    // if (e.detail.name === "food_preferences") {
    //   updatedData = this.#updateFoodPreferences(e);
    // } else if (e.detail.name === "goals") {
    //   updatedData = this.#updateGoalsPreferences(e);
    // } else
    if (e.detail.name === "privacy") {
      if (e.detail.value !== undefined) {
        updatedData = e.detail.value
          ? { privacy: "private" }
          : { privacy: "public" };
      }
    } else {
      if (e.detail.name === "x-form-data") return; // Ignore initial event.
      updatedData[e.detail.name] = e.detail.value;
    }

    const task = async () => {
      await this.#domain.updateProfile(updatedData);
      if (app.session.user) {
        app.session[e.detail.name] = e.detail.value;
      }
      await app.session.refreshUser(true);
    };

    return Task.run(task, {
      ghost: null,
      description: "Updating profile",
    });
  }

  async connectedCallback() {
    await super.connectedCallback();
    app.session.on("profile-updated", () => {
      this.requestUpdate();
    });
    if (!this.widget) app.session.refreshUser();
    try {
      this.#topics = (await this.#domain.getAllPossibleTopics()).data;
    } catch {
      // ignore
    }
  }

  renderPage() {
    return html`
      ${this.renderProgress()} ${this.renderProfile()}

      <section class="card">
        <button
          type="button"
          class="primary wide outline mb-tiny"
          @click="${() => {
            window.location.replace(
              `/profile/${app.session.user?.slug ?? app.session.user?.id}`
            );
          }}"
        >
          ${msg("Show public profile", { desc: "Button label to view a user's public profile." })}
        </button>


      </section>

      <details class="bg-white red">
      <summary>
        ${msg("Danger zone")}
        <svg-icon
          icon="warning"
          size="30px"
          style="--icon-fill-color: var(--color-accent)"
          class="right"
        ></svg-icon>
      </summary>
      <section class="card">
        <button
          name="delete"
          class="primary wide red"
          type="button"
          @click="${this.#toggleDeleteContainer}"
        >
          ${msg("Delete my account")}
        </button>
      </section>

      ${this.renderDeletePart()}
    </details>

    `;
  }

  renderWidget() {
    return this.renderProgress(true);
  }

  renderProfile() {
    return html`
      <section class="card">
        <health-settings summary></health-settings>
      </section>

      <section class="card">
        <div class="profile-picture">
          <profile-picture
            name="${app.session?.user?.firstname} ${app.session?.user
              ?.lastname}"
            img="${app.session?.user?.profile_img_url}"
            uuid="${app.session?.user?.id}"
            size="100px"
          >
          </profile-picture>

          <div class="options">
            <button
              class="wide outline small"
              @click=${(e) => e.target?.querySelector("input")?.click()}
            >
              ${msg("Change profile picture", { desc: "Button label to update or change the user's profile picture." })}
              <input
                type="file"
                style="display:none;"
                name="profile_upload"
                accept="image/*"
                @change=${this.#handleUploadImage}
              />
            </button>

            <button
              class="wide simple red"
              @click=${this.#deleteProfilePicture}
              ?disabled=${!app.session?.user?.profile_img_url}
            >
              <svg-icon icon="trash" size="18px"></svg-icon>
              ${msg("Delete this picture", { desc: "Button label to remove the selected picture." })}
            </button>
          </div>
        </div>

        <x-form
          live
          @statechange=${throttle(this.#updateForm.bind(this), 1000)}
        >
          <form action="" method="post" class="material">
            <fieldset class="flex horizontal">
              <input
                name="firstname"
                data-label=${msg("First name")}
                required
                value=${app.session?.user?.firstname}
              />

              <input
                name="lastname"
                data-label=${"Last name"}
                required
                value=${app.session?.user?.lastname}
              />
            </fieldset>

            <label>
              <span data-label="">${msg("Gender")}</span>
              <select name="sexe" class="mb-tiny">
                <option value="">-- ${msg("Select sex")} --</option>
                ${repeat(Object.entries(sexes()).map(([key, label]) => ({value: key, label})), (s) => {
                  return html`
                    <option value="${s.value}" ?selected=${app.session?.user?.sexe === Number(s.value)}>
                      ${s.label}
                    </option>
                  `
                })}
              </select>
              <svg-icon icon="caret" size="10px"></svg-icon>
            </label>

            <manage-emails></manage-emails>

            <label>
              <span data-label>${msg("Job")}</span>
              <jobs-autocomplete
                name="job"
                value="${app.session?.user?.job}"
              ></jobs-autocomplete>
            </label>

            <fieldset class="flex horizontal">
              <input
                name="body_length"
                data-label=${msg("Height", { id: "body-height", desc: "height (for body measurement)" })}
                type="number"
                value=${Math.trunc(app.session?.user?.body_length)}
              />

              <input
                name="body_weight"
                data-label=${msg("Weight", { id: "body-weight", desc: "weight (for body measurement)" })}
                type="number"
                value=${Math.trunc(app.session?.user?.body_weight)}
              />
            </fieldset>

            <date-text
              name="date_of_birth"
              data-label=${"Date of birth"}
              value=${app.session?.user.date_of_birth}
            >
            </date-text>

            <label>
              ${msg("Your profile biography")}
              <textarea name="bio_short" rows="4">
                ${app.session?.user?.bio_short}
              </textarea>
            </label>

            <switch-check
              name="privacy"
              text=${msg("Set profile to private", { desc: "Button label to make the user's profile private." })}
              .value=${app.session.user.privacy === "private"}
              rtl
            ></switch-check>
            <small
              >${msg(`When your profile is set to private your last name is capped to one letter and your bio is not visible to others.`, { desc: "Explains the limitations applied when a profile is set to private." })}</small
            >
          </form>
        </x-form>
      </section>
    `;
  }

  getWidgetSettings() {
    const defaultSettings = super.getWidgetSettings();

    const progress = app.session.user.progress;
    const totalPercentage = (progress.total * 100).toFixed(0);
    const dynamicPrio = 12 * (100 - totalPercentage);
    return {
      ...defaultSettings,
      priority: dynamicPrio,
      full: totalPercentage < 30,
      title: msg("Profile Progress"),
    };
  }

  renderProgress(widget = false) {
    if (!app.session.user?.progress) return nothing;
    const progress = app.session.user.progress;
    const totalPercentage = (progress.total * 100).toFixed(0);

    return html` <section class="card">
      <profile-progress ?widget=${widget}>
        <div class="wrap">
          <progress-indicator
            type="circle"
            value=${totalPercentage}
            theme="${progress.total < 1.0 ? `yellow` : `green`}"
            size="50"
            bg="beige"
          >
          </progress-indicator>

          <div class="info" style="margin-left: var(--gutter-tiny)">
            <h5>
              <strong>${msg(str`Your profile is ${totalPercentage}% completed.`)}</strong>
            </h5>
            <p>
              ${progress.total < 1.0
                ? html` ${msg('Ready to make it 100%?')} `
                : html` ${msg('Amazing work reaching 100%!')} `}
            </p>
          </div>
        </div>

        <div class="flex horizontal wrap">
          <a class="progress-wrap" href="/account#settings">
            <b>${msg('Profile')}</b>

            <progress-indicator
              type="circle"
              value=${Number(progress.profile * 100).toFixed(0)}
              theme="${progress.profile <= 0.5
                ? `red`
                : progress.profile === 1.0
                ? `green`
                : `yellow`}"
              bg="white"
            >
            </progress-indicator>
          </a>

          <a class="progress-wrap" href="/braincheck">
            <b>${msg("BrainCheck")}</b>

            <progress-indicator
              type="circle"
              value=${Number(progress.braincheck * 100).toFixed(0)}
              theme="${progress.braincheck <= 0.5 ? `red` : `green`}"
              bg="white"
            >
            </progress-indicator>
          </a>

          <a class="progress-wrap" href="/healthcheck">
            <b>${msg("HealthCheck")}</b>

            <progress-indicator
              type="circle"
              value=${Number(progress.healthcheck * 100).toFixed(0)}
              theme="${progress.healthcheck <= 0.5 ? `red` : `green`}"
              bg="white"
            >
            </progress-indicator>
          </a>
        </div>
      </profile-progress>
    </section>`;
  }

  renderDeletePart() {
    if (!this.#deleteVisible) return nothing;
    return html`
      <section class="card delete-account">
        <x-form @action=${this.#deleteAction}>
          <form method="get" action="" class="material center">
            <fieldset>
              <legend>
                <h2>${msg("Delete your account")}</h2>
                <p>
                  ${msg("We sent a verification email in order to confirm the deletion of your account. Please copy and paste the code here.", { desc: "Instruction to enter the verification code sent via email to confirm account deletion." })}
                </p>
              </legend>
              <input
                name="pin"
                inputmode="numeric"
                pattern="[0-9]"
                autocomplete="one-time-code"
                data-label=${msg("Verification code")}
                type="number"
                placeholder=${msg("Paste code from email to delete account.")}
              />

              <p>
                ${msg("After confirming, you will receive an email once the deletion has been completed. Remember, after deleting your account we can not recover any of your data.", { desc: "Notification about receiving a confirmation email after account deletion, with a reminder of data irrecoverability." })}
              </p>
              <button name="confirm" class="primary wide" type="submit">
                ${msg("Confirm deleting my account", { desc: "Button label to confirm the deletion of the user's account." })}
              </button>
            </fieldset>
          </form>
        </x-form>
      </section>
    `;
  }


  // async #logout() {
  //   await app.session.logout();
  //   window.location.href = "/";
  // }

  async #toggleDeleteContainer(e) {
    const task = async () => {
      const userConfirmed = await this.#askDeletionConfirm();
      if (!userConfirmed) return;

      this.#deleteJwt = await this.#domain.deleteRequest();
      this.#deleteVisible = !this.#deleteVisible;
      this.requestUpdate();
    };
    Task.run(task, {
      ghost: e.target.closest("section"),
      description: "Requesting deletion code.",
    });
  }

  async #deleteAction(e) {
    if (e.detail?.name !== "--submit") return;
    const task = async () => {
      try {
        await this.#domain.deleteConfirm(this.#deleteJwt, e.detail.value.pin, this.#deleteReason, this.#deleteReasonExtra);
      } catch (err) {
        if (err.response && err.errorData && err.errorData.message) {
          app.addToastMessage(err.errorData.message, { type: "error" });
          return;
        }
        app.addToastMessage(err, { type: "error" });
        return;
      }

      // Logout user.
      await app.session.logout();
      window.location.href = "/";
    };

    Task.run(task, {
      ghost: e.target,
      description: "Delete user account",
    });
  }

  async #askDeletionConfirm() {
    return new Promise((resolve) => {
      const dialog = document.createElement("modal-dialog");
      dialog.setAttribute("open", "");
      dialog.setAttribute("animation", "slideUp");
      document.body.appendChild(dialog);

      let index = 0;

      const askQuestion = async (index) => {
        if (index === 0) {
          const content = `
            <h3 slot="title">${msg("Cancel Qogni Account?")}</h3>
            <div>
              <p>You are about to <b>delete your Qogni Account</b>.</p>

              <strong>This action is irreversible!</strong>

              <p>All your Qogni data, including health-related questionnaires, posts, chat messages and other
              content will be lost.</p>

              <p>Are you sure?</p>

            </div>

            <button slot="action" name="confirm" class="mb-tiny blue" value="cancel">No, keep using Qogni</button>

            <button slot="action" name="confirm" class="mb-tiny outline red" value="yes">Yes, cancel my account</button>
          `

          dialog.innerHTML = content;
        }

        if (index === 1) {
          const content = `
            <span slot="title">Why are you leaving us 😭 ?</span>
            <div style="display: flex; flex-direction: column; gap: var(--gutter-tiny); margin-bottom: var(--gutter-small);">
              <label style="margin: 0;">
                <input class="variant2" type="radio" name="reason" value="It wasn't what I was expecting">
                <span class="data-label">It wasn't what I was expecting</span>
              </label>
              <label style="margin: 0;">
                <input class="variant2" type="radio" name="reason" value="I don't use the App (anymore)">
                <span class="data-label">I don't use the App (anymore)</span>
              </label>
              <label style="margin: 0;">
                <input class="variant2" type="radio" name="reason" value="The packages are too expensive">
                <span class="data-label">The packages are too expensive</span>
              </label>
              <label style="margin: 0;">
                <input class="variant2" type="radio" name="reason" value="The App is too complicated">
                <span class="data-label">The App is too complicated</span>
              </label>
              <label style="margin: 0;">
                <input class="variant2" type="radio" name="reason" value="Missing feature(s)">
                <span class="data-label">Missing feature(s)</span>
              </label>
              <label style="margin: 0;">
                <input class="variant2" type="radio" name="reason" value="I have privacy concerns about the content and my data.">
                <span class="data-label">I have privacy concerns about the content and my data.</span>
              </label>
              <label style="margin: 0;">
                <input class="variant2" type="radio" name="reason" value="Other">
                <span class="data-label">Other</span>
              </label>
            </div>
            <label>
              Feedback
              <textarea name="extra" rows="4"></textarea>
            </label>
            <button slot="action" name="confirm" class="mb-tiny blue" value="cancel">Cancel</button>
            <button slot="action" id="submit-btn" name="confirm" class="mb-tiny outline red" value="yes" disabled>Submit</button>
          `;

          dialog.innerHTML = content;
        }
      }



      dialog.addEventListener('click', (event) => {
        const target = event.target;

        if (index === 0 && target.matches('[name="confirm"]')) {
          setTimeout(() => {
            const value = target.value;
            if (value === "yes") {
              index++;
              askQuestion(index);
            } else {
              finishWizard(false);
            }
          }, 100);
        }

        if (index === 1 && target.matches('[name="reason"]')) {
          const submitBtn = dialog.querySelector('button[id="submit-btn"]');
          submitBtn.removeAttribute('disabled');

          this.#deleteReason = target.value;
        }

        if (index === 1 && target.matches('[name="confirm"]')) {
          setTimeout(() => {
            const value = target.value;
            if (value === "yes") {
              const textarea = dialog.querySelector('textarea');
              this.#deleteReasonExtra = textarea.value;
              finishWizard(true);
            } else {
              finishWizard(false);
            }
          }, 100);
        }
      });

      const finishWizard = (isConfirmed = true) => {
        dialog.remove();
        resolve(isConfirmed);
      };

      askQuestion(index);
    })
  }
}
