import {OAuth2} from "../shared/oauth2";
import {AppDomainHandler} from "../shared/app-domain-handler";
import {ApiRequest} from "../shared/APIRequest";

export class AuthenticationDomain extends AppDomainHandler {
  #oauth2;
  #api;
  #urlParams;

  constructor() {
    super();
    this.#api = ApiRequest.factory();
    this.#oauth2 = OAuth2.factory();
  }

  async handleOAuthRedirect() {
    return this.#oauth2.handleRedirect();
  }

  /**
   * Returns true if the current URL is a return from an external OAuth2 provider signin.
   */
  get isOAUthRedirect() {
    if (!this.#urlParams)
      this.#urlParams = new URLSearchParams(window.location.search);

    return (
      this.#urlParams.has("oauth2_provider") &&
      this.#urlParams.get("action") === "redirect"
    );
  }

  async getSocialProviders() {
    return await this.#oauth2.getProviders();
  }

  /**
   * Start LinkedIn login redirect
   */
  async startWithSocial(provider) {
    const data = await this.#oauth2.initiate(provider);
    localStorage.setItem("state", data.state);
    window.location.href = data.url;
  }

  /**
   * Start sign-in/up with email
   * @param {string} email
   * @returns
   */
  async startWithEmail(email) {
    if (!email)
      throw new Error("Email cannot be empty")

    await this.#api.postData("/auth/initiate", {
      email
    });
  }

  get user() {
    return app.session.user;
  }

  /**
   * Activate user session with email and assigned PIN (emailed).
   * @param {String} email
   * @param {Number} pin
   * @param {String|null} invitation
   * @returns { Object }
   */
  async activateUserSession(email, pin, invitation = null) {
    const response = await this.#api.postData(`/auth/activate`, {
      email,
      pin,
      invitation
    });

    const jwt = response.authorisation.token;
    ApiRequest.jwt = jwt;

    await app.session.setJwt(jwt);
    await app.session.setUser(response.data.user);
    await app.session.setRoles(response.data.roles);

    // Remove queue.
    try {
      localStorage.removeItem('questionnaire_queue_braincheck');
      localStorage.removeItem('questionnaire_queue_healthcheck');
    } catch (e) {
      console.error(e);
    }

    return {
      user: response.data.user,
      jwt: jwt
    };
  }
}
