import { html } from "lit";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../shared/pwa-page";
import { PostDomain } from "../../domain/post-domain";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";

export class PageSinglePost extends OnboardedMixin(
  AuthenticatedMixin(PWAPage)
) {
  #domain;

  static get properties() {
    return {
      id: { type: String, attribute: true, routeOrigin: 'pathname' },
      post: { type: Object },
    };
  }

  constructor() {
    super();
    this.#domain = new PostDomain();
  }

  connectedCallback() {
    super.connectedCallback();
    
    if (this.id) {
      this.#getPost();
    }
  }

  async #getPost() {
    const task = async () => {
      this.post = await this.#domain.getPost(this.id);
    };

    await Task.run(task, {
      ghost: this,
      description: "Loading post...",
    });
  }

  render() {
    if (!this.post)
      return html`
        <app-shimmer class="title"></app-shimmer>
        <app-shimmer class="tiny"></app-shimmer>
        <app-shimmer class="mb"></app-shimmer>

        <app-shimmer class="title"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer class="mb"></app-shimmer>
      `;

    return html`<post-entry
      .postId=${this.id}
      .stats=${this.post.stats}
      .post=${this.post}
      index=0
      showComments
    ></post-entry>`;
  }
}
