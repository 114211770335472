import {css, html, LitElement} from "lit";
import {autoEscape} from "../common";
import {
  SharedButtonStyles,
  SharedFormStyles,
  SharedVariables
} from "@qogni-technologies/design-system/src/styles/shared-styles.css";
import {version as AppVersion} from "../../qogni-app-config";
import {ApiRequest} from "../APIRequest";
import {Task} from "@qogni-technologies/pwa-utils-library/src/utils/task";

customElements.define(
  "feedback-panel",
  class FeedbackPanel extends LitElement {
    #api;

    constructor() {
      super();
      this.#api = ApiRequest.factory();
    }

    static get properties() {
      return {
        open: {type: Boolean, attribute: true},
      };
    }

    static get styles() {
      return [
        SharedVariables,
        SharedButtonStyles,
        SharedFormStyles,
        css`
          :host {
            display: none;
          }

          .toggle {
            text-decoration: none;
            color: unset;
            padding: 1.2rem 0rem 1.4rem .4rem;;
            outline: none;
            border: 0;
            background-color: transparent;
          }

          aside {
            position: fixed;
            bottom: 160px;
            right: 10px;
            padding: 0;
            border-radius: var(--radius-small, 0.5rem);
            background-color: white;
            transform: rotate(-90deg);
            transform-origin: 100%;
            padding: 0;
            width: 85px;
            height: 30px;
            transition: all 0.2s ease-in-out;

            &[open] {
              transform: unset;
              width: 350px;
              height: 250px;
              right: 0;
              transition: all 0.2s ease-in-out;

              .toggle {
                padding: 0.4rem 0.8rem 1rem;
                float: right;
                font-size: large;
                font-weight: 800;
                filter: grayscale(20%);
                &:hover {
                  filter: unset;
                }
              }
            }

            box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.2);
          }

          form {
            margin: 0.4rem;

            textarea {
              height: 100px;
            }
          }

          button {
            width: 340px;
          }

          fieldset {
            border: 0;
          }
        `,
      ];
    }

    render() {
      document.documentElement.removeAttribute("data-feedback-open");
      return html`<aside ?open=${this.open} title="Send feedback">
        <a href="#" class="toggle" @click=${this.toggle}>
          ${this.open ? "❌" : "Feedback"}
        </a>
        ${this.renderOpen()}
      </aside>`;
    }

    firstUpdated() {
      autoEscape(this, this.close.bind(this));
    }

    renderOpen() {
      if (this.open) {
        document.documentElement.setAttribute("data-feedback-open", "");
        return html`<x-form>
          <form method="POST" action="#" @submit=${this.submitFeedback.bind(this)}>
            <label>
              <span data-label>Provide feedback to the Qogni Team</span>
              <textarea
                required
                minlength="20"
                name="message"
                placeholder="Enter your message..."
              ></textarea>
            </label>
            <button class="small">Send feedback</button>
          </form>
        </x-form>`;
      }
    }

    async submitFeedback(e) {
      e.stopPropagation();
      const formData = new FormData(e.target);
      try {
        await this.createFeedback(formData.get("message"));
        app.addToastMessage('Feedback has been submitted successfully');
        this.clear();
      } catch (e) {
        app.addToastMessage('Error with submitting feedback: ' + e);
      }
    }

    clear() {
      this.renderRoot.querySelector("textarea").value = "";
      this.close();
    }

    close() {
      this.toggle(null, false);
    }

    toggle(e, force) {
      if (e && typeof e === 'object') e.stopPropagation();
      if (e && typeof e === "object") e.preventDefault();
      else if (typeof e === "boolean") force = e;

      if (this.open && this.renderRoot.querySelector("textarea").value.length)
        if (!confirm("You'll lose your feedback message. Are you sure?"))
          return;

      this.open = force ?? !this.open;
    }

    async createFeedback(msg) {
      const payload = {
        comment: msg,
        user_agent: navigator.userAgent,
        application: 'pwa',
        version: AppVersion,
        page: window.location.href + "",
        browser: JSON.stringify(getBrowser())
      }

      return Task.run(async () => this.#api.postData("/feedback", payload), {
        ghost: this,
        description: 'Posting feedback'
      });


      function getBrowser() {
        const b = {
          name: "Unknown",
          version: "Unknown",
          agent: navigator.userAgent,
        };

        const getVersion = (s) => {
          const match = s.split("/")[1];
          return match.split(".")[0];
        };

        switch (true) {
          case b.agent.indexOf("Edg/") > -1:
            b.name = "Edge";
            b.id = "Edg";
            break;
          case b.agent.indexOf("Opr/") > -1 && !!window.opr:
            b.name = "Opera";
            break;
          case b.agent.indexOf("Chrome/") > -1 && !!window.chrome:
            b.name = "Chrome";
            b.id = "Chrome/";
            break;
          case b.agent.indexOf("Trident/") > -1:
            b.name = "Internet Explorer";
            break;
          case b.agent.indexOf("Firefox/") > -1:
            b.name = "Firefox";
            break;
          case b.agent.indexOf("Safari/") > -1:
            b.name = "Safari";
            break;
        }
        const i = b.agent.indexOf(b.id);
        const s = b.agent.substring(i);
        b.version = getVersion(s);
        return b;
      }

      // return {
      //   feedback: {
      //     language: navigator.language || navigator.userLanguage,
      //     message: msg,
      //   },
      //   system: {
      //     platform: navigator.platform,
      //     device: /Mobi|Android/i.test(navigator.userAgent)
      //       ? "mobile"
      //       : "desktop",
      //     browser: b,
      //     touch:
      //       "ontouchstart" in window ||
      //       navigator.maxTouchPoints > 0 ||
      //       navigator.msMaxTouchPoints > 0,
      //     orientation: screen.orientation.type,
      //   },
      //   screen: {
      //     width: window.screen.width,
      //     height: window.screen.height,
      //     colorDepth: window.screen.colorDepth,
      //     pixelRatio: window.devicePixelRatio,
      //   },
      //   pwa: {
      //     installed:
      //       window.matchMedia("(display-mode: standalone)").matches ||
      //       window.navigator.standalone === true,
      //   },
      // };
    }
  }
);
