const TEMP_SPACING_CHAR = "⌁";
const MATCH_START = "\u200b";
const MATCH_END = "\u200d";

/**
 * Uses MutationObserver to wait for DOM changes
 */
export function listenForDOMChanges(
  targetNode,
  config = {
    childList: true,
    subtree: true, // Observe changes in the descendants of the target node
  }
) {
  return new Promise((resolve) => {
    let observer;
    // Create an observer instance linked to the callback function
    observer = new MutationObserver(() => {
      observer.disconnect();
      resolve();
    });
    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
  });
}

/**
 * Processes the DOM to highlight glossary terms,
 * by enclosing them in a <dfn> tag, so the user
 * sees that more information is available.
 */
export class Glossarizer {
  #processTimer;

  /**
   * Constructs a Glossarizer instance.
   * @param {*} rootElement the element to apply the glossary <dfn> tags onto.
   * @param {*} glossary the glossary (key-value pairs, with 'description' property on each value)
   */
  constructor(
    rootElement,
    glossary,
    options = {
      skipSelector: "h1,h2,h3,h4,h5,h6,a,button,label,summary,input,textarea,health-settings,page-account",
    }
  ) {
    this.glossary = glossary;
    this.options = options;
    this.rootElement = rootElement;
    this.glossaryIndex = this.#generateGlossaryIndex(glossary); // lookup
    this.sortedWordList = this.#sortTermsByLength(glossary); // get a sorted list (longest first) of lowercased glossary terms
    this.fullRegex = this.#createFullGlossaryRegex(); // matches ANY glossary word
  }

  /**
   * Runs the DOM parser and waits
   */
  run() {
    const me = this;
    const queue = [];

    const iterator = document.createNodeIterator(
      me.rootElement,
      NodeFilter.SHOW_TEXT,
      {
        // use acceptNode() method to make sure iterator only gets
        // to process nodes that contain gflo
        acceptNode: function (node) {
          if (node.parentNode.closest(me.options.skipSelector))
            return NodeFilter.FILTER_REJECT;

          return me.fullRegex.test(node.nodeValue)
            ? NodeFilter.FILTER_ACCEPT
            : NodeFilter.FILTER_REJECT;
        },
      }
    );

    let currentNode;
    while ((currentNode = iterator.nextNode())) {
      const parent = currentNode.parentNode;

      let text = currentNode.nodeValue;

      for (const term of this.sortedWordList)
        text = Glossarizer.#tempReplace(
          term,
          text,
          this.glossaryIndex,
          parent,
          queue
        );

      currentNode.nodeValue = text;
    }

    /** now that we're outside the Iterator loop,
     * sort the queue by DOM nesting level
     * and do the final replacements.
     */
    const sortedQueue = queue.sort((a, b) => {
      if (a.level > b.level) return -1;
      if (a.level < b.level) return 1;
      return 0;
    });

    for (const occurrence of sortedQueue) {
      occurrence.parent.innerHTML = Glossarizer.#replaceTempWithDfn(
        occurrence.parent.innerHTML,
        occurrence
      );
    }

    listenForDOMChanges(this.rootElement).then(() => {
      if (this.#processTimer) clearInterval(this.#processTimer);

      this.#processTimer = setTimeout(() => {
        me.run();
      }, 100);
    });
  }

  #generateGlossaryIndex() {
    const index = {};
    for (const [key, value] of Object.entries(this.glossary)) {
      index[key.toLowerCase()] = {
        name: key,
        ...value,
      };
    }
    return index;
  }

  #createFullGlossaryRegex() {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    };
    return new RegExp(
      `\\b(${this.sortedWordList.map((w) => escapeRegExp(w)).join("|")})\\b`,
      "gi"
    );
  }

  #sortTermsByLength() {
    return Object.keys(this.glossary)
      .sort((a, b) => {
        return a.length > b.length ? -1 : 1;
      })
      .map((w) => w.toLowerCase());
  }

  static #getNestingLevel(element) {
    let level = 0;
    while ((element = element.parentElement)) level++;
    return level;
  }

  static #tempReplace(term, text, index, parent, queue) {
    const regex = new RegExp(`\\b(${term})(?!${TEMP_SPACING_CHAR})\\b`, "gi");
    text = text.replace(regex, (match) => {
      const occurrence = {
        term: index[term].name, // index uses lowercase keys
        word: match,
        find: `${MATCH_START}${match.replace(
          / /g,
          TEMP_SPACING_CHAR
        )}${MATCH_END}`,
        parent: parent,
        level: this.#getNestingLevel(parent),
      };
      queue.push(occurrence);
      return occurrence.find;
    });
    return text;
  }

  /**
   * Read glossary definition key from 'data-tag' attribute (b64-encoded)
   */
  static readDfn(element) {
    const encoded = element?.getAttribute("data-tag");
    return encoded ?? undefined;
  }

  static #replaceTempWithDfn(text, occurrence) {
    let newString = text.replace(
      occurrence.find,
      /*html*/ `<dfn data-tag="${occurrence.term}">${occurrence.word}</dfn>`
    );

    newString = newString.replace(new RegExp(`${TEMP_SPACING_CHAR}`, "g"), " ");

    return newString;
  }
}
