import {css} from "lit";

/**
 * Shared global stylesheet for use inside Web Components with Shadow DOM.
 */
export const SharedStyles = css`
  button,
  .button {
    border: 0;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    background-color: var(--color-primary-000);
    color: var(--color-primary-200);
    --icon-fill-color: white;
    will-change: filter;
    transition: all 0.2s ease;
    margin: 0.2rem;
    cursor: pointer;
    border-radius: 2rem;
    display: inline-block;
  }

  button.round,
  .button.round {
    padding: 0.5rem 0.8rem;
    border-radius: 50%;
    aspect-ratio: 1/1;
  }

  .disabled {
    pointer-events: none;
  }

  [data-primary] {
    background-color: var(--color-primary-000);
    color: var(--color-primary-200);
  }

  button:hover,
  .button:hover {
    filter: brightness(120%);
    will-change: filter;
    transition: all 0.2s ease;
  }

  button:active,
  button:focus,
  .button:active,
  .button:focus {
    box-shadow: 0 0 0 3px rgba(81, 203, 238, 0.5); /* Example of a custom focus style */
  }

  button:active,
  .button.active {
    filter: brightness(140%);
    outline-color: #aaa;
  }

  button.wide,
  .button.wide {
    width: 100%;
  }
`;
