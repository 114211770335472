import { UserStatesDomain } from "../domain/user-states-domain";
import {ApiRequest} from "./APIRequest";

/**
 * Storage of session and verification of session in-memory.
 * @event logout User is logged out, can be triggered due to JWT-session being expired or by action of logout (will be defined in event_source).
 * @event profile-updated User profile is updated.
 * @event authenticated Authenticated
 * @event initiated Session has been initiated.
 * @event timezone-change The users' timezone has been changed since last time.
 * @author Tom Valk
 */
export class Session extends EventTarget {
  #dbName = "d62097de-c42e-419d-af60-03ccbe2d2b00";
  #storeName = "35c7f2f6-14a3-4f60-b448-8ce7e0a9c79c";
  #keyName = "4f6c11f2-dd13-4520-b10d-300cb613f562";
  #dbVersion = 1;

  #app;

  #initiated = false;
  #jwt;
  #jwtPayload;
  #jwtExpiresAt;
  #user;
  #roles;

  #isRefreshed = false;
  #apiClient;
  #db;

  #userStatesDomain;

  static ROLE_INDIVIDUAL = "Individual";
  static ROLE_HR = "HR";
  static ROLE_QOGNI_ADMIN = "Qogni Super Admin";

  get jwt() {
    return this.#jwt;
  }

  get user() {
    return this.#user;
  }

  get roles() {
    return this.#roles;
  }

  get timezone() {
    return this.#user?.timezone;
  }

  get states() {
    return this.#userStatesDomain;
  }

  get jwtPayload() {
    if (!this.jwt) return null;
    if (this.#jwtPayload === undefined) {
      this.#jwtPayload = this.#parseJwt(this.jwt);
      this.#jwtExpiresAt = new Date(this.#jwtPayload.exp * 1000);
    }
    return this.#jwtPayload;
  }

  get isAuthenticated() {
    if (this.#jwt === undefined) {
      throw new Error(
        "Session is not opened/initiated! Please open session first before accessing anything related to the token."
      );
    }
    if (this.jwt === null || !this.jwtPayload) {
      return false;
    }

    // Verify expiration.
    let currentUnixTime = Math.floor(new Date().getTime() / 1000);
    return this.jwtPayload.exp >= currentUnixTime;
  }

  get isAdmin() {
    return this.roles?.filter(
      (role) => role.name === Session.ROLE_QOGNI_ADMIN
    ).length > 0;
  }

  get isOnboarded() {
    if (!this.isAuthenticated || !this.user) return null;
    return !!(this.user.firstname
      && this.user.lastname
      && this.user.sexe
      && this.user.job
      && this.user.body_length);
  }

  /**
   * @param {QogniApp} app
   */
  constructor(app) {
    super();
    this.#app = app;
    this.#apiClient = ApiRequest.factory();
    this.#userStatesDomain = new UserStatesDomain();
  }

  get initialized() {
    return this.jwt !== undefined && this.#initiated;
  }

  /**
   * @param {QogniApp} app
   * @returns {Session}
   */
  static factory(app) {
    return new this(app);
  }

  async #upgrade(db, event) {
    if (event.oldVersion === 0) {
      db.createObjectStore(this.#storeName, {
        keyPath: "key",
        autoIncrement: false,
      });
    }
  }

  /**
   * Initializes the API client by opening the database and retrieving the JWT-token.
   *
   * @returns {Promise<void>} A promise that resolves when the initialization is complete.
   */
  async init() {
    await this.#open();

    this.#jwt = await this.#getJwt();
    if (this.isAuthenticated) {
      ApiRequest.jwt = this.#jwt;

      const user = await this.#getData("user");
      this.#user = user;
      this.#roles = await this.#getData("roles");

      try {
        if (Object.prototype.hasOwnProperty.call(window, 'OneSignalDeferred') && window.OneSignalDeferred)
          window.OneSignalDeferred.push(async function (OneSignal) {
            OneSignal.login(user.id);
          });
      } catch (e) {
        console.error(e); // ignore
      }

      // Check if the JWT is expiring soon (within 14 days).
      if (this.isAuthenticated && this.jwtPayload) {
        let currentUnixTime = Math.floor(new Date().getTime());
        let diff = Math.floor(this.#jwtExpiresAt.getTime()) - currentUnixTime;
        let diffDays = diff / (1000 * 60 * 60 * 24);
        if (diffDays < 14) {
          await this.refreshToken();
        }
      }
    } else {
      try {
        if (Object.prototype.hasOwnProperty.call(window, 'OneSignalDeferred') && window.OneSignalDeferred)
          window.OneSignalDeferred.push(async function (OneSignal) {
            OneSignal.logout();
          });
      } catch (e) {
        console.error(e); // ignore
      }
    }

    // Dispatch initiated event.
    this.#initiated = true;
    this.dispatchEvent(new CustomEvent("initiated", {
      detail: {
        is_authenticated: this.isAuthenticated,
        user: this.#user,
        roles: this.#roles
      },
      bubbles: true,
    }));
    await this.#timezoneCheck();
    if(this.isAuthenticated) {
      await this.userStates();
      window.addEventListener('states-changed', this.userStates.bind(this));
    }
  }

  /**
   * Logs out the user by making a POST request to the '/auth/logout' endpoint.
   * Clears the JWT token by calling the setJwt method with a null value.
   *
   * @returns {Promise<void>} A promise that resolves when the logout process is complete.
   * If an error occurs during the logout process, the promise will still resolve without any value.
   */
  async logout() {
    try {
      await this.#apiClient.postData("/auth/logout");
    } catch {
      // eslint-disable-line no-unused-vars
      // ignore.
    }
    await this.setJwt(null);
    await this.setUser(null);
    await this.setRoles(null);
    app.cache.clear();
    this.#userStatesDomain.setLocal(null);

    try {
      if (Object.prototype.hasOwnProperty.call(window, 'OneSignalDeferred') && window.OneSignalDeferred)
        window.OneSignalDeferred.push(async function (OneSignal) {
          await OneSignal.logout();
        });
    } catch (e) {
      console.error(e);
    }
  }

  /**
   *
   * @returns {Promise<void>}
   */
  async refreshToken() {
    // Use the API to refresh the JWT token in order to extend the session.
    try {
      const response = await this.#apiClient.postData('/auth/refresh');
      await this.setJwt(response.authorisation.token);
    } catch (e) {
      console.warn('Failed to refresh token, logging out');
      await this.logout();
    }
  }

  /**
   * Open database, migrate if required.
   * @returns {Promise<unknown>}
   */
  async #open() {
    return new Promise((resolve, reject) => {
      const dbRequest = indexedDB.open(this.#dbName, this.#dbVersion);

      dbRequest.onsuccess = (event) => {
        this.#db = event.target.result;
        return resolve(this);
      };
      dbRequest.onerror = (event) => {
        return reject(event);
      };

      dbRequest.onupgradeneeded = (event) => {
        this.#db = event.target.result;
        this.#upgrade(event.target.result, event);
      };
    });
  }

  /**
   * Parses a JWT token and returns its payload.
   * @param {string} token - The JWT token to be parsed.
   * @returns {object|null} - The payload object if the token is valid, otherwise null.
   */
  #parseJwt(token) {
    if (!token) return null;
    const base64Url = token.split(".")[1];
    if (!base64Url) return null;
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  async #getJwt() {
    if (!this.#db) {
      throw new Error("Database is not opened!");
    }
    const jwt = await this.#getData(this.#keyName);
    ApiRequest.jwt = jwt;
    return jwt;
  }

  async setJwt(jwt) {
    if (!this.#db) {
      throw new Error("Database is not opened!");
    }
    ApiRequest.jwt = jwt;
    this.#jwt = jwt;

    await this.#setData(this.#keyName, jwt);

    // Fire event for jwt-change.
    app.fire("session-change", {
      jwt: jwt,
    });
  }

  async setUser(user) {
    const wasUnauthenticated = !this.user;
    this.#user = user;
    await this.#setData("user", user);
    if (user && wasUnauthenticated) {
      try {
        if (Object.prototype.hasOwnProperty.call(window, 'OneSignalDeferred') && window.OneSignalDeferred)
          window.OneSignalDeferred.push(async function (OneSignal) {
            await OneSignal.login(user.id);
          });
      } catch (e) {
        console.error(e); // ignore
      }


      this.dispatchEvent(
        new CustomEvent("authenticated", {
          bubbles: true,
          details: {user: this.user, roles: this.user.roles},
        })
      );
    } else if (!wasUnauthenticated) {
      this.dispatchEvent(
        new CustomEvent("signedout", {
          bubbles: true,
        })
      );
    }
  }

  async setRoles(roles) {
    this.#roles = roles;
    await this.#setData("roles", roles);
  }

  hasRole(roleCode) {
    return this.#roles.filter((role) => role.name === roleCode).length > 0;
  }

  /**
   * Refresh user from server into our local user storage.
   * @param {boolean} force Force refresh on server.
   * @returns {Promise<void>}
   */
  async refreshUser(force = false) {
    if (this.#isRefreshed && ! force) return;
    this.#isRefreshed = true;
    try {
      let url = "/users/me";
      if (force) url += "?force=1";
      const response = await this.#apiClient.getData(url);
      await this.setUser(response.data);
      await this.setRoles(response.data.roles);

      // Set local data.
      this.#user = response.data;
      this.#roles = response.data.roles;

      try {
        if (Object.prototype.hasOwnProperty.call(window, 'OneSignalDeferred') && window.OneSignalDeferred)
          window.OneSignalDeferred.push(async function (OneSignal) {
            await OneSignal.login(response.data.id);
          });
      } catch (e) {
        console.error(e); // ignore
      }

      this.dispatchEvent(new CustomEvent('profile-updated', {
        detail: {
          user: this.#user,
          roles: this.#roles,
        },
        bubbles: true,
      }));
    } catch (e) {
      // If we encounter a 401, it means we are no longer recognized by our API as being logged in, even when our JWT
      // should say so.
      if (e.response && e.response.status === 401) {
        await this.logout();
        app.addToastMessage("You have been logged out", {type: "error"});
        location.replace("/enter");
      }
    }
  }

  async #getData(key, _default = null) {
    const request = this.#db
      .transaction([this.#storeName])
      .objectStore(this.#storeName)
      .get(key);

    return new Promise((resolve, reject) => {
      request.onerror = (event) => {
        return reject(event);
      };
      request.onsuccess = (event) => {
        if (event.target.result && event.target.result.value) {
          return resolve(event.target.result.value);
        }
        return resolve(_default);
      };
    });
  }

  /**
   * Listen for app-level event
   * @param {String} eventName
   * @param {Function} func
   */
  on(eventName, func) {
    this.addEventListener(eventName, func);
    return this;
  }

  /**
   * Sets the data in the object store with the specified key.
   * If a value is provided, it updates the existing data with the new value.
   * If no value is provided, it deletes the data with the specified key.
   *
   * @param {string} key - The key of the data to be set.
   * @param {any} value - The new value for the data (optional).
   * @returns {Promise} - A Promise that resolves if the operation is successful, and rejects with an error if it fails.
   */
  async #setData(key, value) {
    const objectStore = this.#db
      .transaction([this.#storeName], "readwrite")
      .objectStore(this.#storeName);

    let request;
    if (value) {
      request = objectStore.get(key);
    } else {
      request = objectStore.delete(key);
    }

    return new Promise((resolve, reject) => {
      request.onerror = (event) => reject(event);
      request.onsuccess = (event) => {
        // If it was a delete operation, return resolve.
        if (!value) {
          return resolve();
        }

        // Update.
        if (event.target.result) {
          const data = event.target.result;
          data.value = value;
          const request = objectStore.put(data);

          request.onerror = reject;
          request.onsuccess = resolve;
        } else {
          // Insert.
          const request = objectStore.add({key, value});

          request.onerror = reject;
          request.onsuccess = resolve;
        }

        return resolve(null);
      };
    });
  }

  /**
   * Checks the user's timezone and updates it if necessary.
   *
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   */
  async #timezoneCheck() {
    let timezone;
    try {
      timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
    } catch {
      return;
    }
    if (!timezone || !this.#user) {
      return;
    }
    if (this.#user.timezone === timezone) {
      return;
    }

    // Update user timezone.
    const oldTimezone = this.#user?.timezone;
    try {
      await this.#apiClient.patchData('/users/me', {timezone});
      await this.refreshUser();
    } catch (e) {
      console.error(e); // But still ignore when we have issues for now.
    }

    // Trigger event for timezone change.
    this.dispatchEvent(new CustomEvent('timezone-change', {
      detail: {
        from: oldTimezone,
        to: timezone,
      },
      bubbles: true,
    }));
  }

  async userStates() {
    const states = await this.#userStatesDomain.getStates() ?? [];
    this.#userStatesDomain.setLocal(states);
  }

  async onStatesChanged() {
    const states = await this.#userStatesDomain.getStates() ?? [];
    this.#userStatesDomain.setLocal(states);
  }

  async apiStatus() {
    let result;
    try {
      result = await this.#apiClient.getData('/status');
    } catch (err) {
      throw Error('App is offline', err);
    }

    if (!result.status) return false;
    return true;
  }

}
