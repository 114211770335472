import { html } from "lit";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../shared/pwa-page";
import { AccountDomain } from "../domain/account-domain";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { throttle } from "@qogni-technologies/design-system/src/shared/common";
import { QOGNI } from "../shared/qogni";
import { msg } from "@lit/localize";

export class PageSettings extends OnboardedMixin(AuthenticatedMixin(PWAPage)) {
  #domain;

  constructor() {
    super();
    this.#domain = new AccountDomain();
  }

  connectedCallback() {
    super.connectedCallback();
    app.session.on("profile-updated", () => {
      this.requestUpdate();
    });
  }

  render() {
    return html`
      <section class="hero">
        <h1 class="hide-full-width">${msg("Settings")}</h1>
      </section>


      <section class="card">

        <h3>${msg("Notifications")}</h3>

        <setting-radio>
          <input
            name="setting"
            type="radio"
            value="default"
            id="default"
            @change=${this.#updateNotificationState}
            ?checked=${app?.session?.user?.notifications_enabled}
          />
          <label for="default">
            <svg-icon icon="bell" size="32px"></svg-icon>
            <div>
              <h4>${msg("Ring or vibrate", { desc: "One of the Notification preferences" })}</h4>
            </div>
          </label>

          <input
            name="setting"
            type="radio"
            value="silent"
            id="silent"
            @change=${this.#updateNotificationState}
            ?checked=${app?.session?.user?.notifications_enabled === 0}
          />
          <label for="silent">
            <svg-icon icon="bell" size="32px"></svg-icon>
            <div>
              <h4>${msg("Silent")}</h4>
            </div>
          </label>
        </setting-radio>
      </section>


      <section class="card account">

        <h3>${msg("Language")}</h3>
        <x-form
          live
          @statechange=${throttle(this.#updateForm.bind(this), 1000)}
          @action=${this.#updateForm}
        >
          <form action="" method="post" class="material">
            <label>
              <select name="language">
                ${Object.entries(QOGNI.meta.languages).map(
                  ([key, value]) => html`
                    <option
                      value="${key}"
                      ?selected=${app.session?.user?.language === key}
                    >
                      ${value}
                    </option>
                  `
                )}
              </select>
              <svg-icon icon="caret" size="10px"></svg-icon>
            </label>
          </form>
        </x-form>
      </section>
    `;
  }

  /**
   * On-the-fly part of the form, change actions.
   * @param e
   * @returns {Promise<void>}
   */
  async #updateForm(e) {
    let updatedData = {};
    if (e.detail.name === "x-form-data") return; // Ignore initial event.
    updatedData[e.detail.name] = e.detail.value;

    const task = async () => {
      await this.#domain.updateProfile(updatedData);
      if (app.session.user) {
        app.session[e.detail.name] = e.detail.value;
        await app.setLocale(e.detail.value);
      }
      await app.session.refreshUser(true);
    };

    return Task.run(task, {
      ghost: e.target,
      description: "Updating profile",
    });
  }

  async #updateNotificationState(e) {
    e.preventDefault();

    const task = async () => {
      const isEnabled = e.target.value !== "silent";

      if (isEnabled) {
        if (app.notification.blockedNotifications) {
          e.target.checked = false;
          e.target
            .closest("setting-radio")
            .querySelector('input[value="silent"]').checked = true;
          return app.addToastMessage(
            "You disabled Push Notifications in your Browser, please re-enable to receive notifications!"
          );
        }

        // Ask for permission right now at this spot.
        if ((await app.notification.askPermission()) === "denied") {
          e.target.checked = false;
          e.target
            .closest("setting-radio")
            .querySelector('input[value="silent"]').checked = true;
          return app.addToastMessage(
            "You denied Notifications in your browser, please re-enable to receive notifications!"
          );
        }
      }

      // Only now update the settings of the profile.
      await this.#domain.updateProfile({
        notifications_enabled: isEnabled,
      });
    };

    return Task.run(task, {
      ghost: e.target.closest("setting-radio"),
      description: "Setting notification status.",
    });
  }
}
