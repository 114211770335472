import { html, nothing } from "lit";
import { ResultsBase } from "./results-base";
import {msg} from '@lit/localize';

export class PageResults extends ResultsBase {
  renderContent() {
    const bc = this.results?.braincheck;
    const hc = this.results?.healthcheck;

    if (!bc && !hc) {
      return html`
        <div class="preview-wrap">
          <div class="preview-content">
            <badge-tag class="mb-tiny blue small">
              ${msg("BrainCheck Results")}
            </badge-tag>

            <h1>${msg("BrainCheck not finished!", { desc: "Header text indicating that the BrainCheck is incomplete." })}</h1>
            <p>
              ${msg("You haven't completed a BrainCheck before. In order to see your personalized brain performance, head over to the BrainCheck and complete it!", { desc: "Prompt to complete the BrainCheck in order to view personalized brain performance." })}
            </p>

            <flex-container class="justify-content-center">
              <flex-item>
                <a href="/braincheck" class="button green wide">
                  ${msg("Go to the BrainCheck")}
                </a>
              </flex-item>
            </flex-container>
          </div>
          ${this.#renderGirdPages()}
        </div>
      `;
    }
    return html` ${this.#renderGirdPages()} ${this.renderProgram()} `;
  }

  #renderGirdPages() {
    const performanceScore = this.coqnitiveScore?.output
      ? this.coqnitiveScore?.output * 100
      : this.domain.getScorePercentage(this.coqnitiveScore?.score);

    return html`
      <div class="grid-list">
        <a href="/results/performance">
          <section class="card grid-sub-item">
            <label>
              ${msg("Performance")} <svg-icon icon="arrow" size="20px"></svg-icon>
            </label>
            <vector-stat
              score="${performanceScore || 71}"
              vector="${"brain"}"
            ></vector-stat>
          </section>
        </a>

        <a href="/results/personality">
          <section class="card grid-sub-item">
            <label>
              ${msg("Personality")} <svg-icon icon="arrow" size="20px"></svg-icon>
            </label>
            <column-chart
              style="height: 210px;"
              max="100"
              .data=${this.personalGraph}
            ></column-chart>
          </section>
        </a>

        <a href="/results/potential">
          <section class="card grid-sub-item">
            <label>
              ${msg("Potential")} <svg-icon icon="arrow" size="20px"></svg-icon>
            </label>
            ${this.improvementGraph
              ? html`<column-chart
                  style="height: 210px;"
                  max="100"
                  .data=${this.improvementGraph}
                  type="beside"
                ></column-chart>`
              : nothing}
          </section>
        </a>

        ${this.#renderTopicsGridItem()}
      </div>
    `;
  }

  #renderTopicsGridItem() {
    const bc = this.results?.braincheck;
    const hc = this.results?.healthcheck;

    return html`
      <div class="${bc && !hc ? "preview-wrap small" : ""}">
        ${bc && (!hc || !hc.totals)
          ? html`
              <div class="preview-content">
                <badge-tag class="mb-tiny blue small">
                  ${msg("HealthCheck Results")}
                </badge-tag>

                <h4>${msg("HealthCheck not finished!", { desc: "Header text indicating that the HealthCheck is incomplete." })}</h4>
                <p>
                  ${msg("You haven't completed the HealthCheck before. In order to see your personalized health status, head over to the HealthCheck and complete it!", { desc: "Prompt to complete the HealthCheck to view personalized health status." })}
                </p>

                <flex-container class="justify-content-center">
                  <flex-item>
                    <a href="/healthcheck" class="button tiny green wide">
                      ${msg("Start HealthCheck")}
                    </a>
                  </flex-item>
                </flex-container>
              </div>
            `
          : nothing}

        <a href="/results/topics">
          <section class="card grid-sub-item">
            <label>
              ${msg("Your topics", { desc: "Label for the section where users can view their HealthCheck topics." })} <svg-icon icon="arrow" size="20px"></svg-icon>
            </label>
            <figure class="bell">
              <div>
                <svg-icon
                  icon="bell"
                  color="var(--color-primary-200);"
                  size="60px"
                ></svg-icon>
                <span class="count">${this.yourTopicsCount}</span>
              </div>
            </figure>
          </section>
        </a>
      </div>
    `;
  }
}
