import { askConfirm } from "@qogni-technologies/design-system/src/components/base/modal-dialog.js";
import { countTruthyFields } from "@qogni-technologies/design-system/src/shared/common";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { LitElement, html, nothing } from "lit";
import { AccountDomain } from "../../domain/account-domain";
import { AllergensValues } from "../../domain/recipes-domain";
import { config } from "../../qogni-app-config";
import { nativeWebShare } from "../../shared/common";
import {msg, str} from '@lit/localize';

export class RecipeCard extends LitElement {
  createRenderRoot() {
    return this;
  }

  #accountDomain;

  static get properties() {
    return {
      recipe: { type: Object },
      showRating: { type: Boolean },

      // For saved items page
      saveId: { type: String },
      unsave: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.#accountDomain = new AccountDomain();
  }

  render() {
    const {
      id,
      name,
      image_url,
      image_source,
      minutes,
      breakfast,
      lunch,
      dinner,
      review_averages,
      reviews_count,
    } = this.recipe;

    const imgUrl = image_url || "/assets/img/food.webp";
    const allergiesCount = countTruthyFields(this.recipe, AllergensValues);
    const allergiesText = `${allergiesCount} ${
      allergiesCount <= 1 ? msg("allergy") : msg("allergies")
    }`;
    return html`
      <article>
        <a href="/recipes#${id}">
          <figure>
            <img src="${imgUrl}" alt="${image_source}" loading="lazy" />
          </figure>

          <div class="info">
            <hgroup>
              <flex-container>
                ${breakfast
                  ? html`
                      <flex-item
                        ><badge-tag class="small green"
                          >${msg("Breakfast")}</badge-tag
                        ></flex-item
                      >
                    `
                  : nothing}
                ${lunch
                  ? html`
                      <flex-item
                        ><badge-tag class="small blue"
                          >${msg("Lunch")}</badge-tag
                        ></flex-item
                      >
                    `
                  : nothing}
                ${dinner
                  ? html`
                      <flex-item
                        ><badge-tag class="small yellow"
                          >${msg("Dinner")}</badge-tag
                        ></flex-item
                      >
                    `
                  : nothing}
              </flex-container>

              <nav data-dropdown class="align-right img-button">
                <button title="Menu" class="simple">
                  <svg-icon size="24px" icon="meatballs"></svg-icon>
                </button>
                <menu>
                  ${!this.unsave
                    ? html`
                        <li @click=${(e) => this.#onSave(e, id, "recipe_id")}>
                          <svg-icon icon="save" size="20px"></svg-icon>
                          ${msg("Save")}
                        </li>
                      `
                    : nothing}
                  ${this.unsave
                    ? html`
                        <li @click=${this.#onUnsave}>
                          <svg-icon icon="save" size="20px"></svg-icon>
                          ${msg("Unsave")}
                        </li>
                      `
                    : nothing}
                  <li @click=${this.#onShare}>
                    <svg-icon
                      icon="${navigator.canShare ? "share" : "copy"}"
                      size="20px"
                    ></svg-icon>
                    ${navigator.canShare ? msg("Share") : msg("Copy Link")}
                  </li>
                </menu>
              </nav>
            </hgroup>

            <h4>${name}</h4>

            <div class="meta">
              <div>
                <svg-icon size="18px" icon="clock"></svg-icon>
                ${minutes || 0} ${msg("minutes")}
              </div>

              <div>
                <svg-icon size="18px" icon="drag"></svg-icon>
                ${allergiesText}
              </div>

              ${this.showRating
                ? html`<recipe-range-rating-view
                    .rating=${review_averages}
                    .count=${reviews_count}
                  ></recipe-range-rating-view>`
                : nothing}
            </div>
          </div>
        </a>
      </article>
    `;
  }

  async #onShare(e) {
    e.preventDefault();
    e.stopPropagation();

    const task = async () => {
      const { absoluteUrl } = config;
      const link = `${absoluteUrl}/recipes#${this.recipe.id}`;

      await nativeWebShare({ title: this.recipe.name, text: link });
      const parentElement = e.target.parentElement;
      parentElement.setAttribute("hidden", "");
    };

    Task.run(task, {
      ghost: this,
      global: false,
    });
  }

  async #onSave(e, id, key) {
    e.preventDefault();
    e.stopPropagation();

    const task = async () => {
      const res = await this.#accountDomain.saveItemToSavedList({ [key]: id });

      const parentElement = e.target.parentElement;
      parentElement.setAttribute("hidden", "");

      if (!res.status) return false;
      app.addToastMessage("Saved successfully.");
    };

    await Task.run(task, {
      ghost: this,
    });
  }

  async #onUnsave(e) {
    e.preventDefault();
    e.stopPropagation();

    const task = async () => {
      const parentElement = e.target.parentElement;
      parentElement.setAttribute("hidden", "");

      const userConfirmed = await askConfirm({
        title: msg("Confirm Unsave", { desc: "Title for unsave dialog." }),
        message: msg(str`Are you sure you want to unsave this ${"recipe"}?`, { desc: "Description for Confirm Unsave dialog." }),
        okText: msg("Confirm"),
      });
      if (!userConfirmed) return;

      await this.#accountDomain.unsaveItemToSavedList(this.saveId);
      this.dispatchEvent(new CustomEvent("refresh"));
    };

    Task.run(task, {
      ghost: this,
    });
  }

  updated(changeProps) {
    if (changeProps.has("recipe")) {
      setTimeout(() => {
        app.enhancers.run(this);
      });
    }
  }
}

customElements.define("recipe-card", RecipeCard);
