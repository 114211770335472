import { html, nothing } from "lit";
import { ResultsBase } from "./results-base";
import { repeat } from "lit/directives/repeat.js";
import { Converter } from "showdown";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import {msg} from '@lit/localize';

export class PagePersonality extends ResultsBase {
  constructor() {
    super();
    this.title = msg("Personality");
  }

  renderContent() {
    return html`
      ${this.#renderNeurotransmitterProfile()} ${this.#renderCharacteristics()}
      ${this.renderProgram()}
    `;
  }

  #renderNeurotransmitterProfile() {
    return html`
      <section class="card dark-green">
        <h2>${msg("Personal neurotransmitter profile", { desc: "Heading text for the user's personalized neurotransmitter profile." })}</h2>
        <p>
          ${msg("This graph shows the dominance of neurotransmitters and associated personal traits See the graph below.", { desc: "Explanation that the following graph displays neurotransmitter dominance and related traits." })}
        </p>

        <section class="card">
          <h4 class="center">${msg("Personal characteristics", { desc: "Label for the section displaying the user's personal graph." })}</h4>
          ${this.personalGraph
            ? html`<column-chart
                max="100"
                .data=${this.personalGraph}
              ></column-chart>`
            : nothing}
        </section>
      </section>
    `;
  }

  #renderCharacteristics() {
    if (!this.personalProfile) return nothing;
    return html`
      <section class="card">
        <h2>${msg("Your natural personal characteristics", { desc: "Label for the section showing the user's natural personal characteristics" })}</h2>
        <translatable-content current-language="en">
          <p>${this.personalProfile.subtext ?? this.personalProfile.name}</p>
        </translatable-content>

        ${repeat(this.personalProfile.texts, (text, idx) => {
          const colors = ["red", "yellow", "green", "blue"];
          const color = colors[idx % colors.length];
          const { name, value } = text;

          return html`
            <details
              class="${color}"
              ?open=${name.toLowerCase() === "to summarize"}
            >
              <summary>${name}</summary>
              <!-- Content -->
              <section>
                <translatable-content current-language="en">
                  ${unsafeHTML(new Converter().makeHtml(value))}
                </translatable-content>
              </section>
            </details>
          `;
        })}
      </section>
    `;
  }
}
