import "@lit-labs/virtualizer";
import { throttle } from "@qogni-technologies/design-system/src/shared/common";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { FoodPreferences } from "../../domain/account-domain";
import { RecipesDomain } from "../../domain/recipes-domain";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PullToRefreshMixin,
  WidgetEnabledPWAPage,
} from "../../shared/pwa-page";
import "./recipe-card";
import "./recipe-range-rating-view";

const BaseClass = PullToRefreshMixin(
  OnboardedMixin(AuthenticatedMixin(WidgetEnabledPWAPage))
);

export class PageRecipes extends BaseClass {
  #domain;
  #page = 1;
  #lastPage;
  #pagination;
  #filterAllergens = "";
  #filterBreakfast = null;
  #filterLunch = null;
  #filterDinner = null;
  #search = "";

  #fullList = [];

  get #appliedFilters() {
    return {
      page: this.#page,
      filter_allergens: this.#filterAllergens,
      filter_breakfast: this.#filterBreakfast,
      filter_lunch: this.#filterLunch,
      filter_dinner: this.#filterDinner,
      search: this.#search,
    };
  }

  constructor() {
    super();
    this.#domain = new RecipesDomain();
    this.onHashChangeBound = this.onHashChange.bind(this);
    this._computedRecipeList = [];

    // Initial allergens are taken from the users' profile.
    if (app.session?.user) {
      for (const pref of FoodPreferences) {
        if (app.session.user[pref.key]) {
          if (this.#filterAllergens) {
            this.#filterAllergens += `,${pref.key}`;
          } else {
            this.#filterAllergens = pref.key;
          }
        }
      }
    }
  }

  static get properties() {
    return {
      recipeList: { type: Array },
      recipeId: { type: Number },
      _computedRecipeList: { type: Array },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener("refresh", this.#refreshList);

    if (this.widget) {
      this.#getRecipesList();
    } else {
      window.addEventListener("hashchange", this.onHashChangeBound);

      if (window.location.hash) {
        this.recipeId = window.location.hash.substring(1);
      }
    }

    this.#getRecipesList();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener("refresh", this.#refreshList);
    window.removeEventListener("hashchange", this.onHashChangeBound);
  }

  willUpdate(changeProps) {
    if (changeProps.has("recipeList")) {
      this.#fullList = [...this.#fullList, ...this.recipeList];
      const numOfRecipes = this.#fullList.length;
      const { total } = this.#pagination;
      const numOfShimmer = total - numOfRecipes;

      const shimmerList = Array.from({ length: numOfShimmer }, () => undefined);
      this._computedRecipeList = [...this.#fullList, ...shimmerList];
    }
  }

  onHashChange() {
    this.recipeId = window.location.hash.substring(1);
  }

  async #getRecipesList() {
    const task = async () => {
      const options = this.#appliedFilters;
      if (this.widget) {
        options["per_page"] = 1;
        options["sort"] = "random_daily";
      }

      const response = await this.#domain.getRecipesList(options);
      if (!response) return;
      this.recipeList = response?.data;
      this.#lastPage = response?.pagination?.last_page;
      this.#pagination = response?.pagination;
    };

    await Task.run(task);
  }

  get #momentOptions() {
    return [
      { value: "breakfast", label: "Breakfast" },
      { value: "lunch", label: "Lunch" },
      { value: "dinner", label: "Dinner" },
    ];
  }

  get #allergiesOptions() {
    return FoodPreferences.map((p) => {
      return {
        value: p.key,
        label: p.name,
      };
    });
  }

  getWidgetSettings() {
    return {
      ...super.getWidgetSettings(),
      title: "Recipe of the Day",
      priority: app?.session?.user?.active_program ? 825 : 700,
      cta: {
        href: "/recipes",
      },
    };
  }

  renderWidget() {
    if (this.recipeList === undefined)
      return html`
        <app-shimmer
          style="height: 200px; width: 100%; margin-bottom: 0;"
        ></app-shimmer>
      `;
    const recipe = this.recipeList.pop();
    if (!recipe)
      return html`<section class="card"><h3>No recipe found!</h3></section>`;

    return html`
      <a href="/recipes#${recipe.id}">
        <section
          class="card recipe background-image"
          style="--img: url(${recipe.image_url ?? "/assets/img/ico-512.png"})"
        >
          <h3>${recipe.name}</h3>
        </section>
      </a>
    `;
  }

  renderPage() {
    return html`
      ${this.recipeId
        ? html`<master-detail
            ><page-single-recipe .recipeId=${this.recipeId}></page-single-recipe
          ></master-detail>`
        : nothing}

      <section class="hero hide-full-width">
        <h1>Recipes</h1>
      </section>

      <recipe-search>
        <x-form>
          <form>
            <label>
              <span data-label>Search for recipes</span>
              <input
                type="search"
                placeholder="Search for recipes"
                @input="${throttle(this.#onSearchChange.bind(this), 500)}"
              />
            </label>
          </form>
        </x-form>
        <flex-container breakpoint="tiny">
          <flex-item class="col-6">
            <filter-options
              id="moment-form"
              title="Moment of day"
              type="radio"
              .options=${this.#momentOptions}
              icon="ul"
              @change=${this.#onMomentChange}
            ></filter-options>
          </flex-item>
          <flex-item class="col-6">
            <filter-options
              id="allergies-id"
              title="Preferences &amp; Allergies"
              type="checkbox"
              .options=${this.#allergiesOptions}
              .value=${this.#filterAllergens
                ? this.#filterAllergens.split(",")
                : []}
              icon="drag"
              @change="${this.#onAllergiesChange}"
            ></filter-options>
          </flex-item>
        </flex-container>
      </recipe-search>

      ${this.#renderList()}
    `;
  }

  #renderList() {
    if (this._computedRecipeList.length === 0) {
      const shimmerList = Array.from({ length: 5 }, () => ({}));
      return html`
        ${repeat(
          shimmerList,
          () => html`<general-shimmer type="recipe"></general-shimmer>`
        )}
      `;
    }

    return html`
      <lit-virtualizer
        .items=${this._computedRecipeList}
        .renderItem=${this.#renderRecipeListItem}
        @rangeChanged=${this.#onRangeChanged}
      ></lit-virtualizer>
    `;
  }

  #renderRecipeListItem(recipe) {
    if (!recipe) return html`<general-shimmer type="recipe"></general-shimmer>`;
    return html` <recipe-card .recipe=${recipe} showRating> </recipe-card> `;
  }

  async #onSearchChange(event) {
    this.#page = 1;
    this.#search = event.target.value || "";
    this.#fullList = [];
    await this.#getRecipesList();
  }

  async #onMomentChange(e) {
    this.#page = 1;
    this.#fullList = [];

    this.#filterBreakfast = e.target.value === "breakfast";
    this.#filterLunch = e.target.value === "lunch";
    this.#filterDinner = e.target.value === "dinner";

    await this.#getRecipesList();
  }

  async #onAllergiesChange(e) {
    this.#page = 1;
    this.#fullList = [];
    const value = e.target.value || [];
    this.#filterAllergens = value.join(",");
    await this.#getRecipesList();
  }

  async #onScrollEnd() {
    this.#page++;
    if (this.#page === 1 || this.#page <= this.#lastPage) {
      await this.#getRecipesList();
    }
  }

  async #refreshList() {
    this.#page = 1;
    this.#fullList = [];
    await this.#getRecipesList();
  }

  async #onRangeChanged(e) {
    const { last } = e;
    if (last > this.#page * 25) {
      await this.#onScrollEnd();
    }
  }
}
