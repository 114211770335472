import { ApiRequest } from "../shared/APIRequest";
import { AppDomainHandler } from "../shared/app-domain-handler";

const StatesLocalKey = 'states';

export class UserStatesDomain extends AppDomainHandler {
  #api;

  constructor() {
    super();
    this.#api = ApiRequest.factory();
  }

  async getStates() {
    const res = await this.#api.getData('/users/me/states');
    return res.data;
  }

  async storeNewStates(scope, state) {
    return await this.#api.postData('/users/me/states', {scope, state});
  }

  setLocal(data = []) {
    localStorage.setItem(StatesLocalKey, JSON.stringify(data));
  }

  getLocal() {
    const localString = localStorage.getItem(StatesLocalKey);
    return JSON.parse(localString) ?? [];
  }

  hasState(scope, state) {
    return !!this.getState(scope, state);
  }

  getState(scope, state) {
    const states = this.getLocal();
    const o = states.find(i => i.scope === scope && i.state === state);
    return o;
  }
}