import {html, LitElement, nothing} from "lit";
import {repeat} from "lit/directives/repeat.js";
import {ButtonBarAppearance} from "@qogni-technologies/design-system/src/shared/common";

/**
 * Customizable button bar component
 */
export class ButtonBar extends LitElement {
  static get properties() {
    return {
      items: {type: Object, attribute: true},
      class: {type: String, attribute: true},
      global: {type: Boolean, attribute: true},
      appearance: {type: ButtonBarAppearance, attribute: true},
    };
  }

  enable(buttonId, enabled) {
    const button = this.renderRoot.querySelector(`[data-id='${buttonId}']`);
    if (button) button.classList.toggle("disabled", !enabled);
  }

  highlight(buttonId) {
    const button = this.renderRoot.querySelector(`[data-id='${buttonId}']`);
    if (button) {
      button.classList.toggle("highlighted", true);
      setTimeout(() => {
        button.classList.toggle("highlighted", false);
      }, 1000);
    }
  }

  connectedCallback() {
    super.connectedCallback();

    app.on("activeRoute", () => {
      this.requestUpdate();
    });

    app.notification.addEventListener("statechange", () => {
      this.requestUpdate();
    });
  }

  navClick(e) {
    if (
      e.target.closest("a")?.getAttribute("target") !== "_blank" &&
      window.navigation?.polyfill === true
    )
      e.preventDefault();

    this.dispatchEvent(
      new CustomEvent("click", {
        detail: {href: e.target.closest("a")?.getAttribute("href")},
        bubbles: true,
        composed: true,
      })
    );
  }

  render() {
    if (!this.items) return html``;

    const cls = (button) => {
      let classNames =
        (button.class || "") + (button.config?.primary ? " primary" : "");
      if (button.bottomMenuIndex > 0) {
        classNames += " in-bottom";
      }

      if (
        button &&
        button.config &&
        button.config.menu &&
        Object.prototype.hasOwnProperty.call(button.config.menu, 'active') &&
        typeof button.config.menu.active === "function" &&
        button.config.menu.active(app.activeRoute, button)
      ) {
        classNames += " active";
      } else if (
        button &&
        button.config &&
        button.config.menu &&
        !(
          Object.prototype.hasOwnProperty.call(button.config.menu, 'active') &&
          typeof button.config.menu.active === "function"
        )
      ) {
        const activeUrl = app.activeRoute?.options?.route;
        if (activeUrl && (activeUrl + "/").indexOf(button.path + "/") !== -1) {
          classNames += " active";
        }
      }

      return classNames.trim();
    };

    return html`
      <slot name="control"></slot>
      <slot name="before"></slot>
      <nav @click=${this.navClick} class="${this.class}">
        ${repeat(
          this.items,
          (button) => button.id,
          (button) => {
            const badgeCount =
              app.notification.unreadStats[button?.unreadStateKey] || 0;

            return html`
              <a
                title="${button.name}"
                data-id="${button.id}"
                class="${cls(button)}"
                href="${button.path}"
                target="${button.target ?? "_self"}"
                @click=${button.click}
              >
                <figure>
                  <svg-icon
                    size="100%"
                    icon="${button.config?.icon}"
                  ></svg-icon>
                  <!-- TODO: Add a way to responsible page/route component to update its own badge -->
                  ${badgeCount
              ? html`<span class="count">${badgeCount}</span>`
              : nothing}
                </figure>
                <span data-text>${button.name}</span>
              </a>
            `;
          }
        )}
      </nav>
    `;
  }
}
