import {AppDomainHandler} from "../shared/app-domain-handler";
import {ApiRequest} from "../shared/APIRequest";

export class QuestionnaireDomain extends AppDomainHandler {
  #api;
  #latest;

  static #instance;

  scoring = [
    'Deficient',
    'Insufficient',
    'Marginal',
    'Sufficient',
    'Adequate',
    'Optimal',
  ];

  constructor() {
    super();
    if (QuestionnaireDomain.#instance) return QuestionnaireDomain.#instance;
    this.#api = ApiRequest.factory();
    QuestionnaireDomain.#instance = this;
  }

  static get instance() {
    if (!this.#instance) new this();
    return this.#instance;
  }

  async latest() {    
    
    if(!this.#latest || (this.#latest && (!this.#latest?.data?.braincheck || !this.#latest?.data?.healthcheck))) {
      this.#latest = await this.getLatest();
    }
    return this.#latest;
  }

  /**
   * @deprecated
   * @param scoreName
   * @returns {number|null}
   */
  getScorePercentage(scoreName) {
    const scoreIndex = this.scoring.indexOf(scoreName);
    if (scoreIndex === -1) return null;
    const percentage = ((scoreIndex + 1) / this.scoring.length) * 100;
    return Math.round(percentage);
  }

  async getQuestionnaireCategoryIdByName(questionnaireCategoryName) {
    try {
      const response = await this.#api.getData(
        `/questionnaire_categories?${new URLSearchParams({filter: questionnaireCategoryName})}`
      );
      if (response.data.length !== 1) return null;
      return response.data[0].id;
    } catch {
      return null;
    }
  }

  async retrieveQuestions(questionnaireId, locale = null) {
    const query = {locale};
    const response = await this.#api.getData(`/questionnaires/${questionnaireId}/questions?${new URLSearchParams(query)}`);
    return response.data;
  }

  async getLatest(options = {}) {
    const res = await this.#api.getData(`/questionnaires/latest?${new URLSearchParams(options.query ?? {})}`);
    this.#latest = res.data;
    return res;
  }

  filterRunningQuestionnaire(questionnaires) {
    if (! questionnaires.length) return null;
    const activeQuestionnaires = questionnaires.filter((q) => q.current_step !== q.total);
    return activeQuestionnaires.length > 0 ? activeQuestionnaires[0] : null;
  }

  filterFinishedQuestionnaire(questionnaires) {
    if (! questionnaires.length) return null;
    const activeQuestionnaires = questionnaires.filter((q) => q.current_step === q.total);
    return activeQuestionnaires.length > 0 ? activeQuestionnaires[0] : null;
  }

  canStartNewQuestionnaire(questionnaires) {
    if (! questionnaires.length) return true;
    const timeLimit = new Date();
    timeLimit.setMonth(timeLimit.getMonth() - 3);
    const questionnairesFinishedRecently = questionnaires.filter((q) => {
      if (q.current_step !== q.total) return false; // don't include non-finished questionnaires. Can't start new one if we have one running already.
      const qDate = new Date(q.created_at);
      return timeLimit < qDate;
    });
    return questionnairesFinishedRecently.length === 0;
  }

  /**
   * Enrich and filter out questions without any question-text.
   *
   * @param {Object} questionnaire
   * @param {array} questions
   * @returns {array}
   */
  enrichQuestions(questionnaire, questions) {
    return questions.map((q, idx) => {
      if (! q.question) return null;
      if (q.question.translation && q.question.translation.value) q.question.question = q.question.translation.value;
      return {
        name: q.question?.question,
        description: q.question?.description,
        answered: questionnaire.current_step > idx,
        ...q
      };
    }).filter((q) => q);
  }

  async sendQueue(queue) {
    if (queue.length === 0) return;
    const questionnaireId = queue[0].questionnaire_id;
    return this.#api.postData(`/questionnaires/${questionnaireId}/process`, {
      queue
    });
  }

  async retrieveMyQuestionnaires(categoryId = null, finished = null) {
    const query = {
      personalized: 1,
      per_page: 1000,
      sort: 'created_at',
      direction: 'desc'
    };
    if (categoryId) query.questionnaire_category_id = categoryId;
    if (finished === true || finished === false) query.finished = finished;

    const response = await this.#api.getData(
      `/questionnaires?${new URLSearchParams(query)}`
    );
    return response.data;
  }

  async createQuestionnaire(categoryId) {
    const response = await this.#api.postData(`/questionnaires`, {
      questionnaire_category_id: categoryId
    });

    return response.data;
  }
}
