import {html, LitElement, nothing} from "lit";
import {map} from "lit/directives/map.js";
import {createRef, ref} from "lit/directives/ref.js";
import { config } from "../../qogni-app-config";
import { msg, str } from "@lit/localize";

customElements.define(
  "top-bar",
  class TopBar extends LitElement {
    createRenderRoot() {
      return this;
    }

    #wrapperRef = createRef();

    static get properties() {
      return {
        /**
         * Used to set page specific actions
         * If action is defined url field will not consider
         *
         * Example:
         * ```
         * [
         *  {
         *    name: 'notification',
         *    icon: 'bell',
         *    label: 'Notitfication'
         *    url: '/account/notifications'
         *    action: () => {}
         *  }
         * ]
         * ```
         */
        pageActions: {type: String},

        unreadStats: {type: Object},
      };
    }

    constructor() {
      super();
      this.pageActions = [];
    }

    connectedCallback() {
      super.connectedCallback();

      app.notification.addEventListener("statechange", () => {
        this.requestUpdate();
      });
    }

    render() {
      let environmentColor, environmentName = '';
      switch (config.environment) {
        case 'local': environmentColor = 'green'; environmentName = 'local'; break;
        case 'stg': environmentColor = 'yellow'; environmentName = 'staging'; break;
        case 'dev': environmentColor = 'red'; environmentName = 'dev'; break;
      }

      return html`
        <div class="wrapper" ${ref(this.#wrapperRef)}>
          <div>
            <button class="round small back" @click=${() => history.back()} aria-label="Back button">
              <svg-icon icon="arrow" size="18px" rotation=180></svg-icon>
            </button>
            ${config.environment !== 'prd' ? html`
              <badge-tag class="environment ${environmentColor} filled">
                ${environmentName}
              </badge-tag>
            ` : nothing}
          </div>


          <nav class="actions">
            ${this.#renderPageActions()}
            <ul>
              ${map(this.getButtons(), (item) => {
                const badgeCount =
                  app.notification.unreadStats[item?.config?.menu?.top?.unreadStateKey] || 0;

                return html`<li>
                  <a href=${item.path} title="${item.name ?? ""}">
                    <svg-icon
                      icon=${item.config?.topBarIcon ?? item.config?.icon}
                    ></svg-icon>
                    ${badgeCount
                  ? html`<span class="count">${badgeCount}</span>`
                  : nothing}
                  </a>
                </li>`;
              })}
            </ul>
          </nav>

          <nav data-dropdown id="account" class="align-right img-button">
            <button title="Profile / Settings menu" aria-label="profile submenu button">
              <profile-picture
                name="${app.session?.user?.firstname} ${app.session?.user?.lastname}" 
                img="${app.session?.user?.profile_img_url}"
                uuid="${app.session?.user?.id}">
              </profile-picture>
            </button>

            <menu hidden>
              <li>
                <a href="/account" title="Account page">
                  <svg-icon icon="account"></svg-icon>
                  ${msg("Account")}
                </a>
              </li>
              <li>
                <a href="/settings" title="App Settings"
                >
                  <svg-icon icon="settings"></svg-icon>
                  ${msg("Settings")}</a
                >
              </li>
              <li>
                <a href="/saved-items" title="Saved Items">
                  <svg-icon icon="save"></svg-icon>
                  ${msg("Saved Items")}</a
                >
              </li>
              <li>
                <hr/>
              </li>
              <li>
                <a id="share-benefit" href="/benefits" title="Benefit Centre"
                >
                  <svg-icon icon="diamond"></svg-icon>
                  ${msg("Benefit Centre")}</a
                >
              </li>
              <li>
                <hr/>
              </li>
              <li>
                <a href="/whats-new" title="Send us feedback"
                >
                  <svg-icon icon="info"></svg-icon>
                  ${msg("New & Upcoming")}</a
                >
              </li>
              <li>
                <a
                  title="Send us feedback"
                  @click=${app.sendFeedback}
                >
                  <svg-icon icon="like"></svg-icon>
                  ${msg("Send Feedback")}</a
                >
              </li>
              <li>
                <hr/>
              </li>
              <li>
                <a href="/logout" title="Sign out" @click=${app.signOut}
                >
                  <svg-icon icon="logoff"></svg-icon>
                  ${msg("Sign out")}</a
                >
              </li>
            </menu>
          </nav>
        </div>
      `;
    }

    getButtons() {
      const items = app.getGlobalMenuButtons();
      return items
        .filter((i) => {
          return i.config?.menu?.top?.index;
        })
        .sort((a, b) => {
          const aIndex = a?.config?.menu?.top?.index || 0;
          const bIndex = b?.config?.menu?.top.index || 0;
          return aIndex - bIndex;
        });
    }

    #renderPageActions() {
      if (this.pageActions.length === 0) {
        return nothing;
      }

      return html`
        <ul>
          ${map(this.pageActions, (item) => {
        const {url, icon, action} = item;

        return html`<li @click=${action}>
              <a href=${!action ? url : ""}>
                <svg-icon icon=${icon}></svg-icon>
              </a>
            </li>`;
      })}
        </ul>
        <div class="divider"></div>
      `;
    }

    reset() {
      this.pageActions = [];
    }
  }
);
