import { html, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { AuthenticatedMixin, WidgetEnabledPWAPage } from "../shared/pwa-page";
import { msg } from "@lit/localize";

const data = {
  new: [
    {
      title: "Better results",
      description: "You now have a better overview over your results.",
    },
    {
      title: "Edit comment",
      description: "You can now edit your comments.",
    },
    {
      title: "Qogni Groups",
      description: "Introducing groups for community building."
    }
  ],
  cooking: [
    {
      title: "Translations",
      description: "You will see our application being translated part by part in several languages.",
    }
  ],
};

export class PageWhatsNew extends AuthenticatedMixin(WidgetEnabledPWAPage) {
  renderPage() {
    return this.renderItems(false);
  }

  getWidgetSettings(){
    return {
      ...super.getWidgetSettings() ?? {},
      priority: 900,
      title: msg('New and Upcoming', {desc: 'Label for widget that shows new and upcoming features.'}),
      cta: {
        href: '/whats-new'
      }
    };
  }

  renderWidget() {
    return this.renderItems(true);
  }

  renderItems(compact) {
    return html`
      ${compact ? nothing : html`
        <h2>${msg('Recent features and upcoming in the next releases')}</h2>
      `}

      <section class="card">
        <h3>${msg('New Qogni Features')}</h3>
        <ul class="mb-small">
          ${repeat(data.new, (item) => this.renderItem(item, compact))}
        </ul>

        <h3>${compact ? html`${msg('Coming in the next releases')}` : html`${msg("Check out what's coming in the next releases")}`}</h3>
        <ul>
          ${repeat(data.cooking, (item) => this.renderItem(item, compact))}
        </ul>
      </section>
    `;
  }

  renderItem(item, compact) {
    return html`<li>
      ${item.title} ${compact ? "" : html`<small>${item.description}</small>`}
    </li>`;
  }
}
