import { html } from "lit";
import { PureSPA } from "pure-web/spa";

import {
  EventTargetMixin,
  createProxy,
  debounce,
  isMobile,
  parseHTML,
} from "@qogni-technologies/design-system/src/shared/common";
import { config, version } from "./qogni-app-config";
import { Session } from "./shared/session";
import { NotificationCenter } from "./shared/notification";
import { Translator } from "./shared/translator";
import { ServiceWorkerManager } from "./shared/service-worker-manager";
import {
  enhanceDropdownButton,
  enhanceButtonWithIcon,
  enhanceNavDropdownButton,
  enhanceCardsBg,
} from "@qogni-technologies/design-system/src/shared/common.js";
import { enhanceTimeElement } from "@qogni-technologies/pwa-utils-library/src/enhancements/time-element";
import { CacheStorage } from "./shared/cache";
import { UrlPreview } from "@qogni-technologies/design-system/src/shared/url-preview.js";
import { VisibilityObserver } from "./shared/visibility-observer";
import { Glossarizer } from "./shared/glossarizer";
import { ApiRequest } from "./shared/APIRequest";
import { polyfillsLoaded } from "./polyfills/polyfillsLoader";
import "./shared/web-components/fab-search";
import { DomainProxy } from "./shared/domain-proxy";

import { msg, updateWhenLocaleChanges } from "@lit/localize";
import { configureLocalization } from "@lit/localize";
// Generated via output.localeCodesModule
import { sourceLocale, targetLocales } from "./generated/locale-codes.js";

export const { getLocale, setLocale } = configureLocalization({
  sourceLocale,
  targetLocales,
  loadLocale: (locale) => import(`/assets/locales/${locale}.js`),
});

const debug = ["localhost", "127.0.0.1"].includes(location.hostname);

let lastScrollTop;

/**
 * Qogni App
 * @property {NotificationCenter} notification
 * @property {Session} session
 */
customElements.define(
  "qogni-app",
  class QogniApp extends EventTargetMixin(PureSPA) {
    #session;
    #notification;
    #translator;
    #cache;
    #state; // eslint-disable-line no-unused-private-class-members
    #feedback;
    #serviceWorkerManager;

    #toaster;
    #initialized = false;

    #metaObserver;
    #glossarizer;

    #domainProxy;

    static get config() {
      return config;
    }

    get session() {
      return this.#session;
    }
    get notification() {
      return this.#notification;
    }
    get translator() {
      return this.#translator;
    }
    get cache() {
      return this.#cache;
    }

    constructor() {
      super();
      window.app = this;

      updateWhenLocaleChanges(this);

      this.enhancers.add("button[data-dropdown]", enhanceDropdownButton);
      this.enhancers.add("nav[data-dropdown]", enhanceNavDropdownButton);
      this.enhancers.add(
        "button[data-prepend-icon], button[data-append-icon]",
        enhanceButtonWithIcon
      );
      this.enhancers.add(".card[data-image]", enhanceCardsBg);
      this.enhancers.add("time[datetime]", enhanceTimeElement);
    }

    async connectedCallback() {
      try {
        super.connectedCallback();

        if (debug) this.addVersion();

        await this.initialize();
      } catch (ex) {
        console.error("Error initializing: ", ex);
      }
    }

    async initialize() {
      this.#session = Session.factory(this);
      this.#notification = NotificationCenter.factory(this);
      this.#translator = Translator.factory(this);
      this.#cache = CacheStorage.factory(this);

      this.#state = createProxy(this, {});

      this.#domainProxy = new DomainProxy();

      this.#session.addEventListener("authenticated", () => {
        this.#updateUserDetails();

        // TODO: Text for new users (signed-up) different from returning users
        setTimeout(() => {
          app.addToastMessage(
            `Welcome ${this.session.user?.firstname || ""} to Qogni!`
          );
        }, 5000);
      });

      // Update account-link details.
      this.session.on("initiated", (e) => {
        this.#updateUserDetails(e.detail.user);

        if (app.session.user?.language) {
          this.setLocale(app.session.user.language);
        }
      });
      this.session.on("profile-updated", (e) => {
        this.#updateUserDetails(e.detail.user);
      });
      this.session.on("authenticated", () => {
        this.#updateUserDetails(this.session.user);
      });

      this.#initialized = true;

      window.addEventListener("online", this.#onOnline.bind(this));
      window.addEventListener("offline", this.#onOffline.bind(this));
    }

    async setLocale(code) {
      const currentCode = getLocale();
      //const code = languageCode.split("-")[0];
      if (currentCode !== code) {
        console.log("Setting language", code);
        await setLocale(code);
        return true;
      }
      return false;
    }

    get fabSearch() {
      return this.querySelector("fab-search");
    }

    render() {
      return html`
        <main>
          <header></header>
          <main-grid>${super.render()}</main-grid>
        </main>
        <slide-panel id="meta-panel"></slide-panel>
      `;
    }

    updated(props) {
      super.updated(props);
      const path = "/" + location.pathname.substring(1);
      setTimeout(() => {
        this.fire("activeRoute", {
          path: path,
        });
      }, 100);
    }

    firstUpdated() {
      this.addGlobalAppDOM();

      this.urlPreview = new UrlPreview().on("fetched", (e) => {
        this.metaPanel.show(this.getUrlMetaHtml(e.detail));
      });

      this.setupMetaObserver();

      this.addDfnHook();

      this.applyGlossary();
    }

    // run glossary term detection process on <main>
    async applyGlossary() {
      return new Promise((resolve) => {
        this.session.on("initiated", async (e) => {
          const glossary = await ApiRequest.factory().getData(
            "/glossary_definitions.json"
          );
          // TODO: use specified user language glossary

          window.dataSources = {
            glossary: {
              source: glossary,
            },
          };

          const main = this.querySelector("main");
          this.#glossarizer = new Glossarizer(
            main,
            window.dataSources.glossary.source
          );
          this.#glossarizer.run();
        });
      });
    }

    getMetaHtml(selectedTerm) {
      // eslint-disable-next-line no-unused-vars
      const getRelatedContent = (tag) => {
        // TODO - use Global Search (Elastic / AI Search)
        return "";
      };

      const renderRelatedTag = (tag) => {
        return /*html*/ `<badge-tag class="small green">${tag}</badge-tag>`;
      };

      const renderRelatedTags = (term) => {
        if (!Array.isArray(term.tags)) return "";

        return /*html*/ `<div class="badges">
          ${term.tags
            .map((tag) => {
              return renderRelatedTag(tag);
            })
            .join("")}
        </div>`;
      };

      const getHost = (url) => {
        try {
          return "on " + new URL(url).host;
        } catch {
          /* */
        }
      };

      const isInternalLink = (url) => {
        return url.startsWith("/");
      };

      const getIcon = (url) => {
        const p = url.substring(1).indexOf("/");
        const routePart = url.substring(0, p + 1);
        const route = app.config.routes[routePart];
        return route?.config?.icon ?? "link";
      };

      const renderLink = (item) => {
        if (!item?.url) return "";

        if (isInternalLink(item.url))
          return /*html*/ `<a
            href="${item.url}"
        ><svg-icon color="var(--color-accent-400)" icon="${getIcon(
          item.url
        )}" size="24px"></svg-icon
      ></a>`;

        return /*html*/ `<a
          title="Open external link ${getHost(item.url)}"
          href="${item.url}"
          rel="noopener"
          target="_blank"
          ><svg-icon icon="link" size="24px"></svg-icon
        ></a>`;
      };

      const term = window.dataSources.glossary.source[selectedTerm];

      return /*html*/ `
      <h4>${selectedTerm} ${renderLink(term)}</h4>
      <small>${term?.description}</small>
      <div class="related">${getRelatedContent(selectedTerm)}</div>
      ${renderRelatedTags(term)}
    `;
    }

    addDfnHook() {
      document.addEventListener("click", (e) => {
        const tagToSelect = this.#metaObserver.generateKey(
          e.target.closest("[data-tag]")
        );

        if (tagToSelect) this.showGlossaryTerm(tagToSelect);
      });
    }

    showGlossaryTerm(term) {
      this.metaPanel.show(this.getMetaHtml(term));
    }

    setupMetaObserver() {
      this.#metaObserver = new VisibilityObserver(
        this.querySelector("main"),
        "[data-tag]",
        (target) => {
          return Glossarizer.readDfn(target);
        }
      );

      this.#metaObserver
        .on("update", (e) => {
          if (Array.isArray(e.detail)) {
            this.currentMetaElements = e.detail;
          }
        })
        .observe();
    }

    getUrlMetaHtml(metaTags) {
      const title = metaTags["og:title"] ?? metaTags.title;

      return /*html*/ `
      <h3 title="${title}">${title}</h3>
      <article>
        <header style="--bg-color: ${metaTags["theme-color"]}">
          ${
            metaTags["og:image"]
              ? "<img src='" + metaTags["og:image"] + "'/>"
              : "<svg-icon icon='link' size='20px'></svg-icon>"
          }
        </header>
        <section class="details">
          ${
            metaTags.description ??
            `No further info available on ${metaTags.host}`
          }
        </section>
        <footer>
        <a title="Go to ${metaTags.host}" href="${
        metaTags.url
      }" rel="noopener" target="_blank">
          <svg-icon icon="link" color="var(--color-h)" size="20px"></svg-icon>
            ${metaTags.host}
          </a>
        </footer>
      </article>
    `;
    }

    get metaPanel() {
      return this.querySelector("#meta-panel");
    }

    sendFeedback(e) {
      if (e) e.preventDefault();
      app.querySelector("feedback-panel").toggle(true);
    }

    addGlobalAppDOM() {
      const topBar = parseHTML(/*html*/ `<top-bar></top-bar>`)[0];
      this.insertAdjacentElement("afterbegin", topBar);

      const footer = parseHTML(/*html*/ `<footer>
          <bottom-bar appearance="global" class="not-anonymous"></bottom-bar>
        </footer>`)[0];

      this.insertAdjacentElement("beforeend", footer);

      const aside = parseHTML(/*html*/ `<aside>
          <side-bar appearance="global" class="not-anonymous">
            <button class="round small close-trigger" slot="control">
              <svg-icon icon="close" size="16px"></svg-icon>
            </button>
          </side-bar>
        </aside>`)[0];

      this.insertAdjacentElement("beforeend", aside);

      this.#toaster = parseHTML(/*html*/ `
            <message-toaster></message-toaster>
          `)[0];

      this.insertAdjacentElement("beforeend", this.#toaster);

      this.insertAdjacentHTML(
        "beforeend",
        `<fab-search id="omnibox"></fab-search>`
      );

      this.#feedback = parseHTML(/*html*/ `
          <feedback-panel id="tag-feedback"></feedback-panel>
      `)[0];

      this.insertAdjacentElement("beforeend", this.#feedback);

      const header = document.querySelector("header");
      if (header) {
        header.addEventListener("mousedown", () => {
          location.href = "/";
        });
      }

      aside.querySelector("side-bar").items = this.getButtons("side");
      footer.querySelector("bottom-bar").items = this.getButtons("bottom");

      this.#serviceWorkerManager = new ServiceWorkerManager();
      this.#serviceWorkerManager.addEventListener("new-version", (e) => {
        app.addToastMessage(`Update installed: ${e.detail.version}`);
      });

      this.initView();

      // react to 'open' class on side-bar
      this.monitorSideBarOpen(() => {
        this.updateDocumentClasses();
      });

      // qogni-app throttles resize and fires own event
      app.on("throttled-resize", () => {
        this.updateDocumentClasses();
      });

      // Refresh user entity.
      const refreshUserMethod =
        (repeat = false) =>
        () => {
          this.updateDocumentClasses();

          if (this.session.initialized && this.session.isAuthenticated) {
            this.session.refreshUser();
          } else if (!this.session.initialized && repeat === false) {
            setTimeout(refreshUserMethod(true), 500);
          }
        };
      this.session.addEventListener("initiated", () => {
        setTimeout(refreshUserMethod(), 250);
      });

      this.listenToVisibilityChange();

      this.monitorScroll();
    }

    signOut(e) {
      e.preventDefault();
      app.session.logout().then(() => {
        window.location.href = "/";
      });
    }

    addVersion() {
      console.warn("Qogni PWA", version);

      // put package.json version in comment
      document.documentElement.insertAdjacentHTML(
        "afterbegin",
        `<!-- Qogni PWA ${version} -->`
      );
    }

    routeComplete() {
      this.tryHighlightTaggedElement();
    }

    // from omnibox search - scroll to item using tags
    tryHighlightTaggedElement() {
      if (location.hash) {
        let element = null;
        try {
          element = document.querySelector(location.hash);
        } catch {
          // ignore.
        }

        if (element) {
          setTimeout(() => {
            element.classList.add("tag-highlighted");
            element.scrollIntoView({
              behavior: "smooth",
            });
          }, 500);
          setTimeout(() => {
            try {
              element.classList.remove("tag-highlighted");
            } catch {
              /* */
            }
          }, 2000);
        }
      }
    }

    monitorScroll() {
      const htmlElement = document.documentElement;
      const scrollElement = document.querySelector("main");
      if (!scrollElement) return;

      scrollElement.addEventListener(
        "scroll",
        debounce(() => {
          let scrollTop = window.pageYOffset || scrollElement.scrollTop;
          if (scrollTop === 0) htmlElement.removeAttribute("data-scroll");
          else
            htmlElement.setAttribute(
              "data-scroll",
              scrollTop > lastScrollTop ? "down" : "up"
            );

          lastScrollTop = scrollTop;
        })
      );
    }

    async beforeInitialize() {
      await polyfillsLoaded;
      await this.session.init();
      return true;
    }

    get initialized() {
      return this.#initialized;
    }

    get topBar() {
      return document.querySelector("top-bar");
    }

    get mainPage() {
      return document.querySelector("qogni-app main");
    }

    hideSidebar() {
      this.querySelector("aside > side-bar").display = "none";
    }

    listenToVisibilityChange() {
      document.addEventListener("visibilitychange", () => {
        if (document.hidden) {
          document.oldTitle = document.title;
          document.title = this.inactiveTabTitle;
          app.notification.pollInterval = 60000; // 1 min;
        } else {
          document.title = document.oldTitle || "Qogni";
          app.notification.pollInterval = 10000; // 10 seconds;
        }
      });
    }

    #updateUserDetails(u) {
      if (!u) return;

      const src = u.profile_img_url || "/assets/img/profile-picture.webp";
      const account = document.getElementById("account");
      account.setAttribute("data-dropdown-image", src);
      account.setAttribute("title", `${u.firstname} ${u.lastname}\n${u.email}`);
      const detailsImage = account.querySelector("summary > img");
      if (detailsImage) detailsImage.src = src;
    }

    get inactiveTabTitle() {
      if (
        app.notification.unreadStats &&
        app.notification.unreadStats.unread_messages !== undefined
      ) {
        return `Qogni - ${app.notification.unreadStats?.unread_messages} messages`;
      }
      return "Qogni";
    }

    monitorSideBarOpen(func) {
      const me = this;
      me.aside = this.querySelector("aside");

      const config = {
        attributes: true,
        childList: true,
        characterData: true, // This is the key option to observe text changes
        subtree: true, // Observe changes in the descendants of the target node
      };
      // Callback function to execute when mutations are observed
      const callback = function (mutationsList) {
        for (let mutation of mutationsList) {
          if (mutation.type === "attributes") {
            func(me.aside.classList.contains("open"));
          }
        }
      };
      // Create an observer instance linked to the callback function
      const observer = new MutationObserver(callback);
      // Start observing the target node for configured mutations
      observer.observe(this.aside, config);
    }

    // classes for correct positioning on html element
    updateDocumentClasses() {
      const open = this.aside.classList.contains("open");
      const asideVisible = open || window.innerWidth >= 601;
      const asideFull = window.innerWidth >= 901;

      this.sideBar = this.aside.querySelector("side-bar");
      this.sideBar.classList.toggle("open", asideVisible);
      document.documentElement.classList.toggle(
        "side-bar-visible",
        asideVisible
      );
      document.documentElement.classList.toggle("side-bar-full", asideFull);
    }

    get isOnboarded() {
      try {
        return this.session?.isOnboarded;
      } catch {
        return false;
      }
    }

    /**
     * Get sorted list of buttons for top, side, or bottom bar
     * @param {String} position (top|side|bottom)
     * @returns {Array}
     */
    getButtons(position) {
      const items = this.getGlobalMenuButtons();

      // filter and sort menu items depending on type
      return items
        .filter((i) => {
          const menu = i.config?.menu ?? { index: 0 };
          return menu[position]?.index > 0;
        })
        .sort((a, b) => {
          const aSettings = a.config?.menu[position],
            aIndex = aSettings?.index;
          const bSettings = b.config?.menu[position],
            bIndex = bSettings?.index;
          return aIndex > bIndex ? 1 : -1;
        });
    }

    get loadingPage() {
      return html` <div class="fill skeleton">
        <app-shimmer class="title"></app-shimmer>
        <app-shimmer class="tiny"></app-shimmer>
        <app-shimmer class="mb"></app-shimmer>
      </div>`;
    }

    get notFoundPage() {
      return html`
        <section class="card">
          <h2>Not found!</h2>
          <p>
            This page could not be found because it does not exist (anymore).
            Please use the button below to return to the homepage.
          </p>
          <a href="/" class="button">To home</a>
        </section>
      `;
    }

    initView() {
      /**
       * document-level classes for mobile and landscape orientation
       */
      const rootElement = window.document.documentElement;
      const getOrientation = () => {
        return window.innerWidth / innerHeight > 1 ? "landscape" : "portrait";
      };
      let lastOrientation = getOrientation();
      const resize = () => {
        rootElement.style.setProperty(
          "--viewport-height",
          `${window.innerHeight}px`
        );
        this.fire("throttled-resize");

        const orientation = getOrientation();
        rootElement.classList.toggle(
          "vw-landscape",
          orientation === "landscape"
        );

        rootElement.classList.toggle("is-mobile", isMobile());
        if (lastOrientation !== orientation)
          window.dispatchEvent(
            new CustomEvent("orientation-change", {
              detail: {
                orientation: orientation,
              },
            })
          );

        lastOrientation = orientation;
      };

      window.addEventListener("resize", debounce(resize));
      resize();
    }

    /**
     * Add a toast message
     * @param {*} msg
     * @param {*} options
     */
    addToastMessage(msg, options = { type: "info" }) {
      const ev = new CustomEvent("notification", {
        detail: {
          text: msg,
          ...(options || {}),
          type: options?.type || "info",
        },
      });
      window.dispatchEvent(ev);
    }

    getGlobalMenuButtons() {
      const items = [];
      items.push({
        id: "menu",
        path: "#",
        config: {
          icon: "hamburger",
          menu: {
            bottom: {
              index: 1,
            },
            side: {
              index: 0,
            },
          },
        },
      });

      for (const pageConfig of app.config.pages) {
        items.push({
          id: pageConfig.id,
          path: pageConfig.path,
          ...pageConfig,
        });
      }

      if (app.session.isAdmin) {
        const backofficeUrl = "https://backoffice.dev.qogni.io/";
        // todo: determinate url when in production later on.
        items.push({
          id: "backoffice",
          name: "Backoffice",
          config: {
            icon: "tune",
            path: backofficeUrl,
            target: "_blank",
            menu: {
              side: {
                index: 9999,
              },
            },
          },
        });
      }
      return items;
    }

    lastPage;

    async #onOnline() {
      let retries = 0;
      let maxRetries = 5;
      let status;

      do {
        status = await this.session.apiStatus();
        if (status) break;
        retries++;
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } while (retries < maxRetries);

      if (!this.lastPage) {
        location.replace("/");
      }
      if (
        this.activeRoute.path === "/offline" &&
        this.lastPage !== "/offline"
      ) {
        location.replace(`${this.lastPage}`);
      } else {
        location.replace(`/`);
      }
      this.lastPage = undefined;
      document.documentElement.classList.remove("offline");
    }

    #onOffline() {
      const p = this.activeRoute.patternResult?.pathname?.input;
      if (p) this.lastPage = p;
      location.replace("/offline");
      document.documentElement.classList.add("offline");
    }
  }
);
