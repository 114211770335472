import { html } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { LightElement } from "../common";
import { SortableContainer } from "../sortable-container";

customElements.define(
  "multi-select",
  class CheckGroup extends LightElement {
    static formAssociated = true;

    constructor() {
      super();
      this.max = 9999;
      this.internals = this.attachInternals();
    }

    static get properties() {
      return {
        name: { type: String, attribute: true },
        items: { type: Array },
        value: { type: Array },
        max: { type: Number, attribute: true },
        autosort: { type: Boolean, attribute: true },
      };
    }

    connectedCallback() {
      super.connectedCallback();
      this.setAttribute("tabindex", "0");
      this.hasContent =
        this.querySelectorAll("label input[type=checkbox]").length > 0;

      this.addEventListener("focus", () => {
        this.querySelector("input:first-child").focus();
      });


    }

    render() {
      this.removeAttribute("name");
      
      if (this.hasContent) return;

      if (!Array.isArray(this.items)) {
        console.error("Items array not set");
        return;
      }

      console.log("Rendering with items array", this.items);

      return html`<fieldset class="check-group flex">
        ${repeat(
          this.items,
          (item) => item.id,
          (item) => {
            return html`<label>
              <input name="${this.name}" value=${item} type="checkbox" />
              <span>${item}</span>
            </label>`;
          }
        )}
      </fieldset>`;
    }

    firstUpdated() {
      this.addEventListener("input", this.change.bind(this));

      if (typeof this.value === "string") {        
        this.value = this.value.split(",");
      }

      if (this.hasContent) {
        if(this.autosort)
          SortableContainer.attach(this, {
            childClass: ".checked"
        });
        this.setOrder();
      }
    }

    updated() {
      const selectedItems = this.querySelectorAll("input:checked");

      if (selectedItems.length === 0) {
        this.internals.setValidity(
          { valueMissing: true },
          "This field is required."
        );
      } else {
        if (selectedItems.length > this.max) {
          this.internals.setValidity(
            { rangeOverflow: true },
            "You can only select a maximum of ${this.max} items."
          );
        } else {
          this.internals.setValidity({ valid: true });
        }
      }
    }

    // capture all change/uinput events, and collect all values as array.
    change(e) {
      const currentItem = e.target;
      const currentItemText = currentItem.getAttribute("value");
      const selectedItems = this.querySelectorAll("input:checked");

      if (selectedItems.length > this.max) {
        currentItem.checked = false;
      }

      e.stopPropagation();

      const newValue = [];

      for (const input of this.querySelectorAll("input")) {
        const checked = input.checked;
        const label = input.closest("label");

        label.classList.toggle("checked", checked);
        label.style.removeProperty("--order");

        if (checked) newValue.push(input.value);
      }

      this.value = newValue.sort((a) => {
        return a === currentItemText ? 1 : -1;
      });

      if (this.autosort) this.setOrder();
      
      this.internals.setFormValue(this.value);

      this.dispatchEvent(
        new Event("change", {
          bubbles: true,
        })
      );
    }

    setOrder() {
      let i = 1;
      for (const text of this.value ?? []) {
        const input = this.querySelector(`input[value="${text}"]`);
        const label = input?.closest("label");

        label.classList.add("checked");
        label.style.setProperty("--order", i - this.value.length - 1);
        label.style.setProperty("--order-name", `"${i}"`);
        i++;
      }
    }
  }
);
