import { nl2br } from "@qogni-technologies/design-system/src/shared/common";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { AccountDomain } from "../../domain/account-domain";
import { MessagesDomain } from "../../domain/messages-domain";
import { config } from "../../qogni-app-config";
import { nativeWebShare } from "../../shared/common";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../shared/pwa-page";
import { msg, str } from "@lit/localize";

export class PageProfile extends OnboardedMixin(AuthenticatedMixin(PWAPage)) {
  #domain;
  #messagesDomain;

  static get properties() {
    return {
      profile: { type: Object },
      selector: { type: String, attribute: true, routeOrigin: "pathname" },
      loading: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.#domain = new AccountDomain();
    this.#messagesDomain = new MessagesDomain();
    this.loading = true;
    this.profile = null;
  }

  connectedCallback() {
    super.connectedCallback();
    this.fetch();
  }

  async fetch() {
    if (this.selector === "me")
      this.selector = app.session.user.slug ?? app.session.user.id;
    return Task.run(async () => {
      try {
        this.profile = (
          await this.#domain.getUser(this.selector, {
            query: { last_post: true },
          })
        ).data;
      } catch (e) {
        // ignore.
      }
      this.loading = false;
    });
  }

  render() {
    if (this.loading)
      return html`
        <app-shimmer class="title"></app-shimmer>
        <app-shimmer class="image"></app-shimmer>
        <app-shimmer class=""></app-shimmer>
        <app-shimmer class=""></app-shimmer>
        <app-shimmer class=""></app-shimmer>
      `;
    if (!this.profile)
      return html`
        <section class="card">
          <h2 class="center">${msg("Account not found", { desc: "Heading label in specific page which indicating that the user's account could not be found." })}</h2>

          <figure class="center">
            <img src="/assets/img/boy-with-arrow.svg" alt="boy with arrow" />
          </figure>

          <a href="/connections" class="button wide"> ${msg("Connections")} </a>
        </section>
      `;

    const currentLevel = this.profile.current_point_level;

    return html`
      <figure class="center mb-tiny profile-picture">
        <profile-picture
          name="${this.profile.firstname} ${this.profile.lastname}"
          img="${this.profile.profile_img_url}"
          uuid="${this.profile.id}"
          size="250px"
        >
        </profile-picture>
      </figure>

      <profile-headline>
        <h2 class="center name">
          ${this.profile.firstname} ${this.profile.lastname}
        </h2>
        ${this.profile.badges
          ? html`
              <profile-badges>
                ${repeat(
                  this.profile.badges,
                  (b) => html`
                    <badge-tag class="small blue filled">
                      <svg-icon icon="crown"></svg-icon> ${b.name}
                    </badge-tag>
                  `
                )}
              </profile-badges>
            `
          : nothing}
      </profile-headline>

      ${this.#renderFollowing()}

      <flex-container class="justify-content-center">
        ${(this.profile.has_connection || app.session.isAdmin) &&
        this.profile.id !== app.session.user?.id
          ? html`
              <flex-item>
                <button
                  class="red outline tiny"
                  type="button"
                  @click=${this.#messageUser}
                  title="Click to message"
                >
                  <svg-icon icon="message"></svg-icon>
                  ${msg("Message")}
                </button>
              </flex-item>
            `
          : nothing}
        ${this.profile.has_connection &&
        this.profile.id !== app.session.user?.id
          ? html`
              <flex-item>
                <button
                  class="blue outline tiny"
                  type="button"
                  @click=${this.#unfollowUser}
                  title="Click to unfollow"
                >
                  <svg-icon icon="account-add"></svg-icon>
                  ${msg("Connected")}
                </button>
              </flex-item>
            `
          : nothing}
        ${!this.profile.has_connection &&
        this.profile.following &&
        this.profile.id !== app.session.user?.id
          ? html`
              <flex-item>
                <button class="beige tiny" type="button" disabled>
                  <svg-icon icon="minus"></svg-icon>
                  ${msg("Pending")}
                </button>
              </flex-item>
              <flex-item>
                <button
                  class="blue outline tiny"
                  type="button"
                  @click=${this.#unfollowUser}
                  title=${msg("Click to unfollow")}
                >
                  ${msg("Following")}
                </button>
              </flex-item>
            `
          : nothing}
        ${!this.profile.has_connection &&
        !this.profile.following &&
        this.profile.id !== app.session.user?.id
          ? html`
              <flex-item>
                <button
                  class="outline tiny"
                  type="button"
                  @click=${this.#followUser}
                >
                  <svg-icon icon="plus"></svg-icon>
                  ${msg("Follow")}
                </button>
              </flex-item>
            `
          : nothing}
        ${this.profile.id === app.session.user?.id
          ? html`
              <flex-item>
                <a href="/account" class="button outline tiny">
                  <svg-icon icon="account"></svg-icon>
                  ${msg("Edit profile")}
                </a>
              </flex-item>
            `
          : nothing}
        <flex-item>
          <button
            class="outline tiny round"
            type="button"
            data-tooltip="${navigator.canShare ? msg("Share") : msg("Copy")}"
            data-tooltip-position="top"
            data-tooltip-theme="default"
            @click=${this.#onShare}
          >
            <svg-icon
              icon="${navigator.canShare ? "share" : "copy"}"
            ></svg-icon>
          </button>
        </flex-item>
      </flex-container>

      ${this.profile.bio_short
        ? html`
            <section class="card">
              <h3>${msg("Biography")}</h3>
              <p>${nl2br(this.profile.bio_short)}</p>
            </section>
          `
        : nothing}
      ${currentLevel
        ? html`
            <section class="card">
              <h3>${msg("Qogni Level")}</h3>
              <div class="level-line">
                <img src="/assets/img/points.svg" alt="Points" loading="lazy" />
                <span>${msg(str`User has ${currentLevel.name} level`, { desc: "Shows the user's current Qogni level." })}</span>
              </div>
            </section>
          `
        : nothing}
      ${this.profile.last_post
        ? html`
            <post-entry
              .postId=${this.profile.last_post.id}
              self-fetch
            ></post-entry>
          `
        : nothing}
    `;
  }

  #renderFollowing() {
    const { id, followers_count, following_count, slug } = this.profile;
    const isMe = id === app.session?.user?.id;
    if (!isMe) return nothing;
    return html`
      <profile-followers>
        <a href="/profile/${slug}/followers#following"
          ><strong>${following_count}</strong> ${msg("following")}</a
        >
        <a href="/profile/${slug}/followers#followers"
          ><strong>${followers_count}</strong> ${msg("followers")}</a
        >
      </profile-followers>
    `;
  }

  async #followUser(e) {
    if (!this.profile) return;
    return Task.run(
      async () => {
        await this.#domain.followUser(this.profile.id);
        await this.fetch();
      },
      {
        ghost: e.target,
      }
    );
  }

  async #unfollowUser(e) {
    if (!this.profile) return;
    return Task.run(
      async () => {
        await this.#domain.unfollowUser(this.profile.id);
        await this.fetch();
      },
      {
        ghost: e.target,
      }
    );
  }

  async #messageUser(e) {
    if (!this.profile) return;
    return Task.run(
      async () => {
        const result = await this.#messagesDomain.createNewConversation([
          this.profile.id,
        ]);
        // Redirect to conversation.
        window.location.replace(`/messages/${result.data.id}`);
      },
      {
        ghost: e.target,
      }
    );
  }

  async #onShare() {
    const task = async () => {
      const { absoluteUrl } = config;
      const { firstname, lastname } = this.profile;
      const name = `${firstname} ${lastname}`;
      const userId = this.profile?.slug || this.profile?.id;
      const link = `${absoluteUrl}/profile/${userId}`;

      await nativeWebShare({ title: name, text: link });
    };

    Task.run(task, {
      ghost: this,
      global: false,
    });
  }
}
