import { ApiRequest } from "../shared/APIRequest";
import { AppDomainHandler } from "../shared/app-domain-handler";

export class ProgramDomain extends AppDomainHandler {
  #api;

  constructor() {
    super();
    this.#api = ApiRequest.factory();
  }

  async createProgramOrder(programId, data) {
    if (! data.start_date) {
      // Start date, 8 days from now by default
      data.start_date = (new Date(Date.now() + 8 * 24 * 60 * 60 * 1000)).toISOString();
    }
    return await this.#api.postData(`/programs/${programId}/order`, data);
  }

  async getRunningProgram(options = {}) {
    return await this.#api.getData(`/users/me/program?${new URLSearchParams(options?.query ?? {})}`);
  }

  async getOrder(orderId, options) {
    return await this.#api.getData(`/orders/${orderId}?${new URLSearchParams(options?.query ?? {})}`);
  }
}
