import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html } from "lit";
import { GroupDomain } from "../../../domain/group-domain";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../../shared/pwa-page";

export class PageNewGroup extends OnboardedMixin(AuthenticatedMixin(PWAPage)) {
  #domain;

  constructor() {
    super();
    this.#domain = new GroupDomain();
  }

  async #createNewGroup(e) {
    if (e.detail?.name !== "--submit") return;

    let response;

    const task = async () => {
      const name = e.detail.value["group-name"];
      const type = e.detail.value["group-type"];
      const description = e.detail.value["group-description"] || "";

      response = await this.#domain.create({name, type, description});
    };

    await Task.run(task, {
      ghost: this,
      description: "Updating profile",
    });

    if (!response) return false;
    window.location.replace(`/network/groups/${response.slug ?? response.id}`);
  }

  render() {
    return html`
      <div class="center">
        <h1>New circle</h1>
      </div>
      <section class="card">
        <x-form @action=${this.#createNewGroup}>
          <form>
            <input
              name="group-name"
              data-label="Name"
              required
              placeholder="Group Name"
            />
            <label>
              <span data-label="">Search</span>
              <select name="group-type">
                <option value="open">Open</option>
                <option value="closed">Closed</option>
              </select>
              <svg-icon icon="chevron" size="12px"></svg-icon>
            </label>
            <label>
              Description
              <textarea
                name="group-description"
                rows="4"
                placeholder="Write a description here..."
                value=""
              ></textarea>
            </label>
            <button type="submit" class="small wide" name="publish">Save group</button>
          </form>
        </x-form>
      </section>
    `;
  }
}
