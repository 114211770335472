import { LitElement } from 'lit';

export class MasterDetail extends LitElement {
  #resizeObserver;

  createRenderRoot() {
    return this;
  }

  connectedCallback() {
    super.connectedCallback();

    this.#resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        this.#handleResize(entry.contentRect);
      }
    });
    this.#resizeObserver.observe(document.body);
  }

  #handleResize() {
    const rect = app.mainPage.getBoundingClientRect();

    this.style.left = rect.left + 'px';
    this.style.width = rect.width + 'px';
  }
}

customElements.define('master-detail', MasterDetail);
