export class SortableContainer {
  static attach(container, options = {}) {
    const dropzone = container;
    const dropzones = [dropzone];

    const draggables = container.querySelectorAll(`:scope > *`);

    function getDragAfterElement(cnt, y) {
      const draggableElements = [...cnt.querySelectorAll(":not(.is-dragging)")];

      return draggableElements.reduce(
        (closest, child) => {
          const box = child.getBoundingClientRect();
          const offset = y - box.top - box.height / 2;

          if (offset < 0 && offset > closest.offset) {
            return {
              offset,
              element: child,
            };
          } else {
            return closest;
          }
        },
        { offset: Number.NEGATIVE_INFINITY }
      ).element;
    }

    draggables.forEach((draggable) => {
      draggable.setAttribute("draggable", "true");

      draggable.addEventListener("dragstart", () => {
        draggable.classList.add("is-dragging");
      });

      draggable.addEventListener("dragend", () => {
        draggable.classList.remove("is-dragging");
      });
    });

    dropzones.forEach((zone) => {
      zone.addEventListener("dragover", (e) => {
        e.preventDefault();
        const afterElement = getDragAfterElement(zone, e.clientY);
        const draggable = document.querySelector(".is-dragging");
        if (afterElement === null) {
          zone.appendChild(draggable);
        } else {
          zone.insertBefore(draggable, afterElement);
        }
      });
    });
  }
}
