import { html } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { LightElement } from "../common";

customElements.define(
  "radio-group",
  class RadioGroup extends LightElement {
    #hasChanged = false;

    static formAssociated = true;

    constructor() {
      super();
      this.internals = this.attachInternals();
    }

    static get properties() {
      return {
        name: { type: String, attribute: true },
        items: { type: Array },
        value: { type: String },
      };
    }

    connectedCallback() {
      super.connectedCallback();
      this.setAttribute("tabindex", "0");

      this.addEventListener("focus", () => {
        const firstInput = this.querySelector("input:not(:focus)");
        firstInput.focus();
      });
    }

    render() {
      if (!this.items || !Array.isArray(this.items)) {
        this.innerContent = true;
        return;
      }
      
      return html`<fieldset
        class="radio-group horizontal flex"
        @input=${this.change}
        @change=${this.change}
      >
        ${repeat(
          this.items,
          (item) => item.id,
          (item, index) => {
            const value = item.value ?? item;
            const text = item.text ?? item;
            return html`<label tabindex="${index + 1}">
              <input
                name="${this.name}"
                value="${value}"
                type="radio"
                class="variant2"
                ?checked=${this.value == value}
              />
              <span>${text}</span>
            </label>`;
          }
        )}
      </fieldset>`;
    }

    firstUpdated() {
      this.addEventListener("input", this.change.bind(this));
      if(this.innerContent){
        const selectedItem = this.querySelector(`input[value="${this.value}"]`);
        if(selectedItem)
          selectedItem.checked = true;
      }
    }

    updated() {
      if (this.querySelectorAll("input:checked").length === 0) {
        this.internals.setValidity(
          { valueMissing: true },
          "This field is required."
        );
        if (this.#hasChanged) this.internals.reportValidity();
      } else {
        this.internals.setValidity({ valid: true });
      }
    }

    // capture all change/uinput events, and collect all values as array.
    change(e) {
      e.preventDefault();
      e.stopPropagation();

      this.#hasChanged = true;
      this.value = this.querySelector("input:checked").value;
      this.internals.setFormValue(this.value);
      this.updated();
    }
  }
);
