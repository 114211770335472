import { html, nothing } from "lit";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  WidgetEnabledPWAPage,
} from "../shared/pwa-page";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { createRef, ref } from "lit/directives/ref.js";
import { NotificationDomain } from "../domain/notification-domain";
import { AccountDomain } from "../domain/account-domain";
import {repeat} from "lit/directives/repeat.js";

export class PageNotifications extends OnboardedMixin(
  AuthenticatedMixin(WidgetEnabledPWAPage)
) {
  #domain;
  #accountDomain;
  #nextCursor;
  #infinityListElement = createRef();
  #readTimeout;

  #onVisibilityChangeBound;

  get properties() {
    return {
      ...super.properties,
      notifications: { type: Array },
      pagination: { type: Object },
    };
  }

  getWidgetSettings() {
    return {
      ...super.getWidgetSettings(),
      title: 'Latest notifications',
      priority: 810,
      full: true,
      cta: {
        href: '/notifications',
      }
    }
  }

  constructor() {
    super();
    this.#domain = new NotificationDomain();
    this.#accountDomain = new AccountDomain();
    this.notifications = undefined;
    this.pagination = undefined;
    this.userList = undefined;

    this.#onVisibilityChangeBound = this.#onVisibilityChange.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();

    app.notification.addEventListener(
      "statechange",
      this.#notificationChange.bind(this)
    );

    if (! this.widget) {
      app.notification.pollInterval = 2000;
      app.notification.addEventListener(
        "statechange",
        this.#notificationChange.bind(this)
      );
      this.#readTimeout = setTimeout(() => {
        this.#markRead();
      }, 2000);
    } else {
      this.#getNotifications();
    }

    document.addEventListener("visibilitychange", this.#onVisibilityChangeBound);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    if (! this.widget) {
      app.notification.pollInterval = 10000;

      if (this.#readTimeout) clearTimeout(this.#readTimeout);
    }

    app.notification.removeEventListener(
      "statechange",
      this.#notificationChange
    );

    document.removeEventListener("visibilitychange", this.#onVisibilityChangeBound);
  }

  async #notificationChange(e) {
    if (e.detail.oldValue === undefined) return;
    await this.#getNotifications();
    if (! this.widget && this.#infinityListElement.value)
      this.#infinityListElement.value.addItems(this.notifications, true);
  }

  async #getNotifications() {
    const task = async () => {
      try {
        const query = {};
        if (this.#nextCursor) query["cursor"] = this.#nextCursor;
        if (this.widget) query['per_page'] = 2;
        const response = await this.#domain.getNotifications({ query });
        this.notifications = response.data;
        this.pagination = response.pagination;
        this.#nextCursor = response.pagination.next_cursor;
      } catch (err) {
        app.addToastMessage(`Notifications: ${err}`, { type: "error" });
      } finally {
        this.requestUpdate();
      }
    };

    await Task.run(task);
  }

  async #markRead() {
    if (!app.notification.unreadStats["unread_notifications"]) return;
    if (
      !this.notifications ||
      !Array.isArray(this.notifications) ||
      this.notifications.length === 0
    )
      return;

    const lastNotification = this.notifications[0];

    if (lastNotification) {
      await this.#domain.markReadBulk(new Date(lastNotification.created_at));
      // reload page.
      this.#getNotifications();
    }
  }

  async #action(e) {
    if (e.target.href && e.target.href !== "#") {
      return;
    }

    e.preventDefault();
    const notificationId = e.target.closest("notification-card").dataset["id"];
    if (!notificationId || !this.notifications) return;
    let notification = this.notifications.filter(
      (notification) => notification.id === notificationId
    );
    if (!notification || notification.length !== 1) return;
    notification = notification[0];

    // Follow back.
    const task = async () => {
      if (
        notification.notification_type?.name === "Followers" &&
        notification.action_user
      ) {
        await this.#accountDomain.followUser(notification.action_user.id);
      }

      await app.session.refreshUser(true);
      this.requestUpdate();
    };

    Task.run(task);
  }

  #renderNotification(notification) {
    return html`
      <notification-card
        class="${notification.read ? "" : "new"}"
        data-id="${notification.id}"
        data-action-user-id=${notification.action_user?.id}
      >
        <section
          class="card notification ${notification.read ? "" : "light-blue"}"
        >
          ${notification.action_user 
            ? html`<profile-picture
              name="${notification.action_user?.firstname} ${notification.action_user?.lastname}" 
              img="${notification.action_user?.profile_img_url}"
              uuid=${notification.action_user?.id}
              link=${`/profile/${notification.action_user?.id}`}
              size="50px" >
            </profile-picture>`
          : nothing}

          <div class="info">${notification.content}</div>

          <div class="controls">
            <span class="time">
              ${this.lastNotificationDateString(notification, true)}
            </span>
            ${this.#renderNotificationControls(notification)}
          </div>
        </section>
      </notification-card>
    `;
  }

  #renderNotificationControls(notification) {
    if (
      notification.notification_type.name === "Followers" &&
      notification.action_user?.id &&
      app.session?.user?.following?.indexOf(notification.action_user.id) === -1
    ) {
      return html`
        <button
          type="button"
          class="tiny"
          @click=${this.#action.bind(this)}
        >
          Follow back
        </button>
      `;
    }

    if (notification.action_url && notification.action_name) {
      return html`
        <a
          href="${notification.action_url}"
          class="button beige tiny"
          @click=${this.#action.bind(this)}
        >
          ${notification.action_name}
        </a>
      `;
    }
    return nothing;
  }

  lastNotificationDateString(notification, withTime = false) {
    return this.#domain.formatDateTime(
      new Date(notification.created_at),
      withTime
    );
  }

  async #onCnvScrollEnd() {
    if (this.notifications?.length && !this.pagination?.next_cursor) return;
    await this.#getNotifications();
    if (this.#infinityListElement.value)
      this.#infinityListElement.value.addItems(this.notifications);
  }

  renderWidget() {
    if (this.notifications === undefined) return html`
      <section class="card">
        <app-shimmer style="height: 100px"></app-shimmer>
      </section>
    `;
    if (this.notifications === null || this.notifications?.length === 0) {
      return html`
        <section class="card">
          <i>You don't have any notifications.</i>
        </section>
      `;
    }

    return html`
      <section class="card">
        ${repeat(this.notifications, this.#renderNotification.bind(this))}
      </section>
    `;
  }

  renderPage() {
    return html`
      <section class="center hide-full-width hero">
        <h1>Your notifications</h1>
      </section>

      ${this.notifications === null || this.notifications?.length === 0
        ? html` You don't have any notifications at this moment. `
        : nothing}

      <infinite-list
        ${ref(this.#infinityListElement)}
        .renderItem=${this.#renderNotification.bind(this)}
        @scroll-end=${this.#onCnvScrollEnd}
      ></infinite-list>
    `;
  }

  async #refreshNotifications() {
    this.#nextCursor = null;
    await this.#getNotifications();
    if (this.#infinityListElement.value)
      this.#infinityListElement.value.addItems(this.notifications, true);
  }

  async #onVisibilityChange() {
    if (!document.hidden) {
      await this.#refreshNotifications();
    }
  }
}
